import { useNavigate, useParams } from "react-router-dom";
import { ProductActions } from "../components/ProductActions";
import { BsQuestionCircle } from "react-icons/bs";
import { dateFormatLocal } from "../../SharedModule/utils/formatters";
import { ModalPortal } from "../../SharedModule/components/ModalPortal";
import { ModalPublish } from "../components/ModalPublish";
import { useEffect, useRef, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useSpotlights } from "../hooks/useSpotlights";
import { Spinner } from "../../SharedModule/components/Spinner";
import { SpotlightService } from "../services/spotlight";
import {
  loadLastSpotlightData,
  setActualProductName,
  setIsLoadingData,
  setIsSpotlightOn,
} from "../redux/reducers/spotlight.reducer";
import { useDispatch, useSelector } from "react-redux";
import { addToast } from "../../SharedModule/redux/reducers/ui.reducer";
import { getStatusStyle } from "../utils/spotlightUtils";
import "quill/dist/quill.snow.css";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { IterableQuill } from "../components/IterableQuill";
import { setIsLoadingData as setIsLoadingDataKeyContact } from "../redux/reducers/keyContact.reducer";
import { setIsLoadingData as setIsLoadingDataSuccessCriteria } from "../redux/reducers/successCriteria.reducer";
import eventHanlder from "../../SharedModule/utils/eventHanlder";
import { setActiveTab } from "../redux/reducers/ui.reducer";
import {
  ROUTE_DELETE_PRODUCT_PAGE,
  ROUTE_FORBIDDEN_PAGE,
} from "../../SharedModule/utils/constants";
import image from "./../../styles/legacy/spotlight-off.png";
import { NoContent } from "../../BillingModule/components/NoContent";
import HeaderModule from "../components/HeaderModule";
import Footer from "../../SharedModule/components/Footer";
import { selectAuth } from "../../SharedModule/redux/reducers/auth.reducer";

export const SpotlightPage = () => {
  const navigate = useNavigate();

  let { productId } = useParams();

  const { trackPageView } = useMatomo();

  const auth = useSelector(selectAuth);
  const isLogged = auth.isAuthenticated;

  const { lastSpotlight, isLoadingData, isSpotlightOn, actualProductName } =
    useSpotlights(Number(productId));

  if (!isLoadingData && lastSpotlight.errors) {
    if (
      lastSpotlight.errors[1] === "Not enough permissions to execute action"
    ) {
      navigate(ROUTE_FORBIDDEN_PAGE);
    } else {
      navigate(ROUTE_DELETE_PRODUCT_PAGE);
    }
  }

  const getSpotlightStateFromStorage = () => {
    let state = localStorage.getItem("spotlightStateChange");
    return state ? JSON.parse(state) : isSpotlightOn;
  };
  const isSpotlightOnValue = getSpotlightStateFromStorage();

  const hasLastSpotlight = () => {
    if (lastSpotlight.lastSpotlight) {
      return true;
    } else {
      return false;
    }
  };

  const { accounts } = useMsal();

  const dispatch = useDispatch();

  const [localLoader, setLocalLoader] = useState(false);
  const [quillValue, setQuillValue] = useState("");

  const getNewLastSpotlight = async () => {
    setLocalLoader(true);
    // should by any, because now with own handler has a diff structure
    const lastSpotlight: any = await SpotlightService.getLastSpotlight(
      Number(productId)
    );
    const spotlightHistory = await SpotlightService.getSpotlightHistory(
      Number(productId)
    );
    let action: any = {
      lastSpotlight: lastSpotlight.data,
      spotlightHistory: spotlightHistory,
    };
    dispatch(loadLastSpotlightData(action));
    dispatch(setActualProductName(lastSpotlight.data.productName));
    dispatch(setIsSpotlightOn(lastSpotlight.data.isSpotlightOn));
    setLocalLoader(false);
  };

  // Knows issue: quill and modal quill shares same ref.
  // flag to avoid enter in useEffect when modal quill apperas
  const [pageQuillEnable, setPageQuillEnable] = useState(true);

  // Modal config
  const modalRef = useRef();
  const showModal = () => {
    setPageQuillEnable(false);
    (modalRef.current as any).show(true);
  };
  const closeModal = (loadNewSpotlight: boolean) => {
    (modalRef.current as any).show(false);
    setPageQuillEnable(true);
    if (loadNewSpotlight) {
      getNewLastSpotlight();
    }
  };

  const loadInfo = () => {
    setQuillValue(lastSpotlight.lastSpotlight?.comment);
  };

  useEffect(() => {
    dispatch(setActiveTab("spotlight"));
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });
    dispatch(setIsLoadingData(true));
    dispatch(setIsLoadingDataKeyContact(true));
    dispatch(setIsLoadingDataSuccessCriteria(true));
  }, []);

  useEffect(() => {
    // heres is where we set default value data
    if (hasLastSpotlight() && pageQuillEnable && isSpotlightOnValue) {
      loadInfo();
    }
  }, [lastSpotlight, isSpotlightOnValue]);

  const toggleSpotlight = async () => {
    setLocalLoader(true);
    let result = await SpotlightService.toggleSpotlight(Number(productId));
    if (result === "Spotlight was changed successfully.") {
      dispatch(setIsSpotlightOn(!isSpotlightOnValue));
      window.localStorage.setItem(
        "spotlightStateChange",
        JSON.stringify(!isSpotlightOnValue)
      );
      dispatch(addToast({ mode: "success", message: result }));
      eventHanlder.dispatch("toast action", { isClosed: false });
    }
    setLocalLoader(false);
  };

  return (
    <>
      <ModalPortal ref={modalRef} zIndex={170}>
        <ModalPublish
          title="Update Spotlight Overview"
          btnLabel="Publish"
          btnCancelLabel="Cancel"
          btnCancel={closeModal}
          actionPlaceholder="notCloseOnClickOutside"
          type="formModal"
          lastSpotlight={lastSpotlight?.lastSpotlight}
          owner={accounts[0].localAccountId}
        />
      </ModalPortal>
      <div className="content">
        <HeaderModule />
        {(isLoadingData || localLoader) && (
          <Spinner
            style={{
              margin: "15% 45%",
            }}
          />
        )}
        {!isLoadingData && !localLoader && (
          <>
            <div className="content-header">
              <div className="content-header-title">
                {actualProductName} - Spotlight Overview{" "}
                {!lastSpotlight.isProductActive && (
                  <button className="btn-status">Status: Inactive</button>
                )}
              </div>
              <div className="content-header-right">
                {isLogged &&
                  isSpotlightOnValue &&
                  lastSpotlight.isContentEditable &&
                  lastSpotlight.isProductActive && (
                    <button
                      className="btn btn-primary me-1"
                      onClick={() => showModal()}
                    >
                      Update
                    </button>
                  )}
                <ProductActions
                  toggleHandler={toggleSpotlight}
                  isContentEditable={lastSpotlight.isContentEditable}
                  isProductActive={lastSpotlight.isProductActive}
                  isLogged={isLogged}
                />
              </div>
            </div>
            {isSpotlightOnValue && !hasLastSpotlight() && (
              <NoContent title="No Status Yet" image={image} />
            )}
            {isSpotlightOnValue && hasLastSpotlight() && (
              <div className="status-gauge-description-container">
                <div
                  className={`status-gauge ${getStatusStyle(
                    lastSpotlight.lastSpotlight?.status.id
                  )}`}
                >
                  <h5>{lastSpotlight.lastSpotlight?.status.name}</h5>
                  <a
                    className="link-question-mark"
                    target="_blank"
                    rel="noreferrer"
                    href="https://itxnet.sharepoint.com/sites/Delivery/SitePages/Spotlight-Reporting.aspx"
                  >
                    <BsQuestionCircle className="icon" />
                  </a>
                </div>
                <div className="status-description">
                  <div className="status-author">
                    {lastSpotlight.lastSpotlight?.fullName}
                  </div>
                  <div className="publish-date">
                    {dateFormatLocal(lastSpotlight.lastSpotlight?.createdAt)}
                  </div>
                  <div className="status-comment">
                    <IterableQuill value={quillValue} />
                  </div>
                </div>
              </div>
            )}
            {!isSpotlightOnValue && (
              <NoContent
                title="Spotlight Reporting is turned off"
                text="Turn on to include this product into the weekly spotlight reports"
                action={() => toggleSpotlight()}
                image={image}
                conditionButton={lastSpotlight.isContentEditable}
                disabledButton={!lastSpotlight.isProductActive}
                textButton="Turn On Spotlight"
              />
            )}
          </>
        )}
        <Footer />
      </div>
    </>
  );
};
