import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ExternalService } from "../../services/external";
import {
  KeyValuePair,
  Project,
  SelectItem
} from "../../../types";
import { formatForDropdowns } from "../../../SharedModule/utils/formatters";

export const fetchMeta = async () => {
  const [productsProjects, ...rest] = await Promise.all([
    ExternalService.getProjects(),
    ExternalService.getClients(),
    ExternalService.getDepartments(),
  ]);

  const [clientsProducts, departmentsProducts] = rest.map((metaItem) => {
      return metaItem.map(formatForDropdowns<KeyValuePair | Project>("name"))
    }
  );

  return {
    projectsProducts:
      (productsProjects &&
        productsProjects.map((proj) => ({
          value: proj.id,
          label: `${proj.id} - ${proj.name}`,
          client: proj.accountId
        }))) ||
      [],
    clientsProducts,
    departmentsProducts
  };
};

export type MetaState = {
  clientsProducts: Array<SelectItem> | null;
  projectsProducts: Array<any> | null;
  departmentsProducts: Array<SelectItem> | null;
  loaded: boolean;
};

type SetItem = Partial<MetaState>;

const initialState: MetaState = {
  clientsProducts: null,
  projectsProducts: null,
  departmentsProducts: null,
  loaded: false
};

export const metaSlice = createSlice({
  name: "meta",
  initialState,
  reducers: {
    // Remember Redux Toolkit allows us to write "mutating" logic in reducers.
    setMeta: (state: MetaState, action: PayloadAction<SetItem>) => {
      return { ...state, ...action.payload, loaded: true};
    },
  },
});

export const { setMeta } = metaSlice.actions;

// Selectors
export const selectMetaLoaded = ({ meta }: { meta: MetaState }) => meta.loaded;

export const selectClients = ({ meta }: { meta: MetaState }) => meta.clientsProducts;

export const selectDepartments = ({ meta }: { meta: MetaState }) => meta.departmentsProducts;

export const selectProjects = ({ meta }: { meta: MetaState }) => meta.projectsProducts;

export const selectRecurringOptions = (state: { meta: MetaState }) => {
  return {
    loaded: selectMetaLoaded(state),
    clientsProducts: selectClients(state),
    departmentsProducts: selectDepartments(state),
    projectsProducts: selectProjects(state)
  };
};

export default metaSlice.reducer;