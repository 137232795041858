import {
  ROUTE_QUERY_TIMESHEETS,
  ROUTE_TIMESHEETS,
} from "../../SharedModule/utils/constants";
import { LeftNavBar } from "../../SharedModule/components/LeftNavBar";
import { LeftNavBarSection } from "../../SharedModule/components/LeftNavBarSection";

export const LeftNavBarTimesheets = (props: any) => {
  return (
    <LeftNavBar>
      <LeftNavBarSection
        mainTitle="Time Entry"
        biIconName="BiAlarm"
        loadingStatus={props.isLoading}
        listOfLinks={[{ text: "Enter time", route: ROUTE_TIMESHEETS }]}
      />
      <LeftNavBarSection
        mainTitle="Reports"
        biIconName="BiBarChartAlt2"
        loadingStatus={props.isLoading}
        listOfLinks={[
          {
            text: "Query",
            route: ROUTE_QUERY_TIMESHEETS,
          },
        ]}
      />
      <LeftNavBarSection
        mainTitle="Administration"
        biIconName="BiShieldQuarter"
        loadingStatus={props.isLoading}
        listOfLinks={[
          {
            text: "Administrative Functions",
            route:
              process.env.REACT_APP_ASUITE_BASE_URI +
              "/Timesheets/timesheetsAdmin.asp",
          },
        ]}
      />
      <LeftNavBarSection
        mainTitle="Reimbursements"
        biIconName="BiDollar"
        loadingStatus={props.isLoading}
        listOfLinks={[
          {
            text: "Reimbursements",
            route:
              process.env.REACT_APP_ASUITE_BASE_URI +
              "/Timesheets/reimbreport.asp",
          },
          {
            text: "Expense Reports",
            route:
              process.env.REACT_APP_ASUITE_BASE_URI +
              "/Timesheets/Expense_Report.asp",
          },
        ]}
      />
    </LeftNavBar>
  );
};
