import { DateTime } from "luxon";
import { CustomWeekDay } from "../../TimesModule/types";

export const getShortDayName = (index: number) => {
  switch (index) {
    case 0:
      return "SUN";
    case 1:
      return "MON";
    case 2:
      return "TUE";
    case 3:
      return "WED";
    case 4:
      return "THU";
    case 5:
      return "FRI";
    default:
      return "SAT";
  }
};

export const isToday = (date: Date) => {
  const today: Date = new Date();
  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getUTCDate() === today.getUTCDate()
  );
};

export const isSameDay = (dateString: string, d2: Date) => {
  let d1 = DateTime.fromISO(dateString, { zone: "utc" }).setLocale("en-US");
  return (
    d1.year === d2.getFullYear() &&
    d1.month - 1 === d2.getMonth() &&
    d1.day === d2.getUTCDate()
  );
};

export const isWeekday = (date: Date) => date.getDay() % 6 !== 0;

export const isHoliday = (day: Date, holidays: string[]) => {
  let response = false;
  if (holidays.length > 0) {
    holidays.forEach((element) => {
      if (isSameDay(element, day)) {
        response = true;
      }
    });
  }
  return response;
};

export const getPreviousWorkableDay = (value: number, holidays: string[]) => {
  let yesterday: any = new Date(
    new Date().setDate(new Date().getUTCDate() - value)
  );
  if (isWeekday(yesterday) && !isHoliday(yesterday, holidays)) {
    return yesterday;
  }
  return getPreviousWorkableDay(value + 1, holidays);
};

export const getWeekFullDate = (
  actualWeek: CustomWeekDay[],
  dayNumber: number
) => {
  let result;
  actualWeek.forEach((element) => {
    if (element.numDay === dayNumber) {
      result = element.fullDate;
    }
  });
  return result;
};
