import { EntryHoursBox } from "../components/EntryHoursBox";
import {
  empty,
  emptyProjectWorkOrder,
  getProjectWorOrder,
  getValueFromSelect,
} from "../utils/timesUtils";
import { selectMetaTimesData } from "../redux/reducers/meta.reducer";
import { useSelector } from "react-redux";
import { newUuid } from "../../SharedModule/utils/uuid";
import { TimeEntryForm } from "../types";

export const ModalTimeEntry = (props: any) => {
  const MetaTimesInfo = useSelector(selectMetaTimesData);

  let elem: TimeEntryForm = {
    entryCanEdit: props.editMode ? props.entry.canEdit : true,
    entryCanDelete: props.editMode ? props.entry.canDelete : true,
    entryId: props.editMode ? props.entry.timeEntryId : newUuid(),
    entryDate: props.editMode
      ? props.entryInitalInfo
        ? props.entryInitalInfo.modalDate
        : props.entry.entryDate
      : props.entryInitalInfo
      ? props.entryInitalInfo.modalDate
      : new Date().toISOString(),
    entryDateValid: true,
    entryProjectWorkOrder: props.editMode
      ? getProjectWorOrder(props.projectList, props.entry.entityId)
      : props.entryInitalInfo
      ? getProjectWorOrder(props.projectList, props.entryInitalInfo.entityId)
      : emptyProjectWorkOrder,
    entryProjectWorkOrderValid:
      props.editMode || (props.entryInitalInfo && props.entryInitalInfo.entityId),
    entryHours: props.editMode ? props.entry.hours : "",
    entryHoursValid: props.editMode,
    entryTaskType: props.editMode
      ? getValueFromSelect(MetaTimesInfo.taskTypes, props.entry.taskTypeId)
      : props.entryInitalInfo
      ? props.entryInitalInfo.taskType
        ? props.entryInitalInfo.taskType
        : props.entryInitalInfo.entryTaskType
      : empty,
    entryTaskTypeValid: props.editMode || (props.entryInitalInfo && props.entryInitalInfo.taskType),
    entryDescription: props.editMode ? props.entry.description : "",
    entryDescriptionValid: props.editMode || (props.entryInitalInfo && props.entryInitalInfo.description),
    entryIsBillable: props.editMode ? props.entry.timeEntryTypeId === 1 : true,
    entryNonBillableReason:
      props.editMode && props.entry.nonBillableReasonId
        ? getValueFromSelect(
            MetaTimesInfo.nonBillableReasons,
            props.entry.nonBillableReasonId
          )
        : empty,
    entryNonBillableReasonValid:
      props.editMode && props.entry.nonBillableReasonId ? true : false,
    isEntryValid: false,
    isEntryTouched: false,
  };

  return (
    <EntryHoursBox
      title={props.editMode ? "Edit time entry" : "Add time"}
      state={elem}
      btnCancel={props.btnCancel}
      projectsWorkOrders={props.projectList}
      isLoadingProjects={false}
      isModal={true}
      editMode={props.editMode}
      userIdParam={props.userIdParam}
      reloadPage={props.reloadPage}
      holidays={props.holidays}
      multipleTimeEntries={
        props.actualTimeEntries && props.actualTimeEntries.length > 1
          ? props.actualTimeEntries
          : null
      }
      deleteEntry={props.deleteEntry}
    />
  );
};
