import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EntryList,
  Holidays,
  IsListView,
  IsLoading,
  ProjectList,
  Summary,
  TimeEntriesList,
  TimeEntriesSheetView,
  loadTimesInfo,
  setLoading,
} from "../redux/reducers/times.reducer";
import { TimesheetsService } from "../services/timesheets";
import { quantityFormat } from "../../SharedModule/utils/formatters";
import { actualUIState } from "../../ProductsModule/redux/reducers/ui.reducer";
import { hasTimeAdminAccess } from "../utils/timesUtils";

// showedWeek param is pass only for call get endpoint once a work week change
export const useTimes = (
  sunday: Date,
  saturday: Date,
  showedWeek: string,
  activeUserId: any,
  pageDate: Date
) => {
  const dispatch = useDispatch();
  const isListView = useSelector(IsListView);
  const isLoading = useSelector(IsLoading);
  const projectList = useSelector(ProjectList);
  const timeEntriesList = useSelector(TimeEntriesList);
  const timeEntriesSheetView = useSelector(TimeEntriesSheetView);
  const summary = useSelector(Summary);
  const holidays = useSelector(Holidays);
  const entryList = useSelector(EntryList);
  
  // times module admin
  const { userRolesTimesModule } = useSelector(actualUIState);
  const isTimeAdmin = hasTimeAdminAccess(userRolesTimesModule);

  const loadTimesPageInfo = useCallback(async (pageDate: Date) => {
    dispatch(setLoading(true));
    let [projectsWorkOrders, timeEntries] = await Promise.all([
      TimesheetsService.getUserProjects(activeUserId),
      TimesheetsService.getTimesEntries(
        activeUserId,
        sunday.toISOString(),
        saturday.toISOString()
      ),
    ]);

    const partial = projectsWorkOrders.data.map((elem) => ({
      value: elem.entityId,
      label: elem.showBudget
        ? elem.name +
          " (" +
          quantityFormat(elem.budgetRemaining) +
          " budget hours remaining)"
        : elem.name,
      categoryId: elem.categoryId,
      entityId: elem.entityId,
    }));
    const info = {
      projectList: partial,
      timeEntriesInfo: timeEntries,
      pageDate: pageDate
    };
    dispatch(loadTimesInfo(info));
  }, [dispatch, showedWeek, activeUserId]);

  useEffect(() => {
    loadTimesPageInfo(pageDate);
  }, [loadTimesPageInfo]);

  return {
    isLoading,
    isListView,
    projectList,
    timeEntriesList,
    timeEntriesSheetView,
    summary,
    holidays,
    entryList,
    isTimeAdmin,
    loadTimesPageInfo,
  };
};
