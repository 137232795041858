import { DateTime } from "luxon";
import { Role } from "../../types";
import { ExternalService } from "../../BillingModule/services/external";

import dayjs, { Dayjs } from "dayjs";
import dayjsBusinessTime from "dayjs-business-time";

dayjs.extend(dayjsBusinessTime);

// Shared
export const THE_HUB = "The Hub";
export const THIS_WEEK = "This Week";
export const LAST_WEEK = "Last Week";
export const THIS_MONTH = "This Month";
export const LAST_MONTH = "Last Month";
export const LOCKED = "Locked";
export const APPROVED_TEXT = "Approved";
export const PENDING_TEXT = "Pending";

// Enviroments
export const DEVELOPMENT = "development";
export const RELEASE = "preprod";
export const PRODUCTION = "production";

// Modules
export const NO_MODULE = "no-module";
export const BILLING_MODULE = "billing";
export const PRODUCTS_MODULE = "products";
export const ADMIN_MODULE = "admin";
export const TIMES_MODULE = "timesheets";

// Shared Routes
export const ROUTE_HOME = "/";
export const ROUTE_THEHUB_ADMIN = "/admin";
export const ROUTE_NOT_FOUND_PAGE = "/404-page-not-found";
export const ROUTE_FORBIDDEN_PAGE = "/forbidden-page";
export const ROUTE_LOG_OUT = "/log-out";
export const ROUTE_SAMPLE_REPORT = "/sample-report";
export const ROUTE_ACCESS_CONTROL_REPORT_BY_USER = "/access-control-report-by-user";
export const ROUTE_ACCESS_CONTROL_REPORT_BY_JIRA_PROJECT = "/access-control-report-by-jira-project";
export const ROUTE_ACCESS_CONTROL_REPORT_BY_GITLAB_PROJECT = "/access-control-report-by-gitlab-project";

// Billing Routes
export const ROUTE_INVOICES_LIST = "/billing";
export const ROUTE_INVOICES_DETAIL = "/billing/invoices/:id";
export const ROUTE_INVOICES_SEARCH = "/billing/search";
export const ROUTE_INVOICES_FILTERS = "/billing/filters";
export const ROUTE_RECURRING_QUERY = "/billing/recurring";
export const ROUTE_PROFFESSIONAL_SERVICES_QUERY =
  "/billing/professional-services";
export const ROUTE_BILLABLE_ITEMS_RESULTS = "/billing/results";

// Products Routes
export const ROUTE_PRODUCTS_LIST = "/products";
export const ROUTE_DELETE_PRODUCT_PAGE = "/product-not-found";
export const ROUTE_PRODUCT_SPOTLIGHT_SUMMARY_REPORT =
  "/products/reports/spotlightSummaryReport";
export const ROUTE_PRODUCT_ADVOCACY_SUMMARY_REPORT =
  "/products/reports/advocacySummaryReport";
export const ROUTE_PRODUCT_ADVOCACY_DETAIL_REPORT =
  "/products/reports/advocacyDetailReport";
export const ROUTE_PRODUCT_RELATIONSHIP_UPDATES_REPORT =
  "/products/reports/relationshipUpdatesReport";
export const ROUTE_PRODUCT_RELATIONSHIP_REVIEW_REPORT =
  "/products/reports/relationshipReviewReport";
export const ROUTE_PRODUCT_SPOTLIGHT = "/products/:productId/spotlight";
export const ROUTE_PRODUCT_SPOTLIGHT_HISTORY =
  "/products/:productId/spotlightHistory";
export const ROUTE_PRODUCT_KEYCONTACTS = "/products/:productId/keyContacts";
export const ROUTE_PRODUCT_NEW_KEYCONTACTS_DETAILS =
  "/products/:productId/keyContacts/addContact";
export const ROUTE_PRODUCT_KEYCONTACTS_DETAILS =
  "/products/:productId/keyContacts/:contactId";
export const ROUTE_PRODUCT_KEYCONTACTS_CONFIDENCE_HISTORY =
  "/products/:productId/keyContacts/:contactId/confidenceHistory";
export const ROUTE_PRODUCT_SUCCESSCRITERIA =
  "/products/:productId/successCriteria";

// Times Routes
export const ROUTE_TIMESHEETS = "/time";
export const ROUTE_QUERY_TIMESHEETS = "/time/query";
export const ROUTE_QUERY_RESULTS_TIMESHEETS = "/time/query/results";

// other router
export const ROUTE_PROJECTS = "/projects";
export const ROUTE_WORK_ORDERS = "/work-orders";
export const ROUTE_BUS_DEV = "/bus-dev";
export const ROUTE_INTELLIGENCE = "/intelligence";

export const PENDING = 1;
export const APPROVED = 2;
export const EXPORTED = 3;
export const RESET = 4;
export const VOID = 5;

// Roles - All modules
export const ROLE_SUPER_ADMIN = "Super Admin";
export const ROLE_PRICE_MANAGER = "Price Manager";

// Roles - Billing Module
export const ROLE_ACCOUNTING_ADMINISTRATOR = "Accounting Administrator";
export const ROLE_ACCOUNTING_COORDINATOR = "Accounting Coordinator";
export const ROLE_INVOICE_APPROVER_OWNER = "Invoice Approver - Owner";
export const ROLE_RECURRING_BILLING_OWNER = "Recurring Billing Owner";
export const ROLE_SUPERVISOR = "Supervisor";
export const ROLE_INVOICE_VIEWER = "Invoice Viewer";

// Roles - Products module
export const ROLE_ALL_PRODUCTS_VIEWER = "All Products Viewer";
export const ROLE_ALL_PRODUCTS_ADMIN = "All Products Admin";
export const ROLE_ENGAGEMENT_LEAD = "Engagement Lead";
export const ROLE_ENGAGEMENT_MANAGER = "Engagement Manager";
// Automatics Roles - these should not be used
export const ROLE_PRODUCT_VIEWER = "Product Viewer";
export const ROLE_PRODUCT_EDITOR = "Product Editor";
export const ROLE_PRODUCT_ADMIN = "Product Admin";

// Roles - Times Module
export const ROLE_TIMESHEETS_ADMIN = "Time Admin"

// Automatic Roles
export const ROLE_PROJECT_OWNER = "Project Owner";

// Billing policies
export const ROLE_NOT_OWNERS: Role["name"][] = [
  ROLE_SUPER_ADMIN,
  ROLE_ACCOUNTING_ADMINISTRATOR,
  ROLE_ACCOUNTING_COORDINATOR,
  ROLE_SUPERVISOR,
];

export const ROLE_BILLING_MODULE_PERFORM_SEARCH: Role["name"][] = [
  ROLE_SUPER_ADMIN,
  ROLE_ACCOUNTING_ADMINISTRATOR,
  ROLE_ACCOUNTING_COORDINATOR,
  ROLE_SUPERVISOR,
  ROLE_INVOICE_VIEWER,
];

export const ROLE_BILLING_MODULE: Role["name"][] = [
  ROLE_SUPER_ADMIN,
  ROLE_ACCOUNTING_ADMINISTRATOR,
  ROLE_ACCOUNTING_COORDINATOR,
  ROLE_INVOICE_APPROVER_OWNER,
  ROLE_RECURRING_BILLING_OWNER,
  ROLE_SUPERVISOR,
  ROLE_INVOICE_VIEWER,
];

export const ROLE_BILLING_MODULE_PERFORM_ACTION: Role["name"][] = [
  ROLE_SUPER_ADMIN,
  ROLE_ACCOUNTING_ADMINISTRATOR,
  ROLE_ACCOUNTING_COORDINATOR,
  ROLE_INVOICE_APPROVER_OWNER,
  ROLE_RECURRING_BILLING_OWNER,
  ROLE_SUPERVISOR,
];

export const ROLE_BILLING_MODULE_EXPORT_INVOICES: Role["name"][] = [
  ROLE_INVOICE_VIEWER,
];

// Products policies
export const PRODUCTS_CREATOR: Role["name"][] = [
  ROLE_SUPER_ADMIN,
  ROLE_ALL_PRODUCTS_ADMIN,
  ROLE_ENGAGEMENT_MANAGER,
];

export const PRODUCTS_DELETE: Role["name"][] = [
  ROLE_SUPER_ADMIN,
  ROLE_ALL_PRODUCTS_ADMIN,
];

// Admin access
export const ADMIN_ACCESS: Role["name"][] = [
  ROLE_SUPER_ADMIN,
  ROLE_PRICE_MANAGER,
];

// Helper to know if have a billing role
export const hasBillingRole = (userRoles: Role[] | null | undefined) => {
  let result =
    userRoles?.some((role) =>
      Array.isArray(ROLE_BILLING_MODULE)
        ? ROLE_BILLING_MODULE.find(
            (allowedBillingRoles) => allowedBillingRoles === role.name
          )
        : ROLE_BILLING_MODULE === role.name
    ) || false;
  return result;
};

// Dates
export const NOW = DateTime.now();
export const THIS_MONTHS_START = NOW.startOf("month");
export const YESTERDAY = NOW.get("day") === 1 ? NOW : NOW.minus({ day: 1 });
export const THIS_MONTHS_END = NOW.endOf("month");
export const LAST_MONTHS_START = NOW.minus({ month: 1 }).startOf("month");
export const LAST_MONTHS_END = NOW.minus({ month: 1 }).endOf("month");

// Business days
const holidays: string[] = [];
const loadData = async () => {
  const data = await ExternalService.getYearHolidays("US", NOW.year);
  data.forEach((element) => holidays.push(element.date.split("T")[0]));
  // Add holidays to dayjs
  dayjs.setHolidays(holidays);
  let day: Dayjs = dayjs(NOW.startOf("month").toISODate());
  // Should be the first workable day
  while (!day.isBusinessDay() || day.isHoliday()) {
    day = day.addBusinessDays(1);
  }
  const nextBillingDate: Dayjs = day.addBusinessDays(12);
  THIS_MONTHS_BILLING = DateTime.fromJSDate(nextBillingDate.toDate());
};
loadData();
// Should load this data inside 'loadData' function
export let THIS_MONTHS_BILLING: DateTime;
