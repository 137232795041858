import { useState } from "react";
import "../../../styles/main.scss";
import {
  addToExcluded,
  deselectAll,
  removeFromExcluded,
  selectAll,
  selectResourcesOrderedBy,
} from "./../../redux/reducers/billableItems.reducer";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import { currencyFormat } from "../../../SharedModule/utils/formatters";
import { Spinner } from "../../../SharedModule/components/Spinner";
import image from "./../../../styles/legacy/404.png";
import { NoContent } from "../NoContent";

const projectsBaseUrl = process.env.REACT_APP_ASUITE_PROJECT_RESOURCE_URI;
const workOrderBaseUrl = process.env.REACT_APP_ASUITE_WO_RESOURCE_URI;

export const Resources = ({ loading }: { loading: boolean }) => {
  const [sort, setSort] = useState<any>({
    orderBy: "accountName",
    criteria: "asc",
    dataType: "string",
  });

  const sortBy = (orderBy: any, dataType: "string" | "date" | "number") => {
    let criteria: "asc" | "desc" = "asc";
    if (sort.orderBy === orderBy) {
      criteria = sort.criteria === "asc" ? "desc" : "asc";
    }
    setSort({
      orderBy,
      criteria,
      dataType,
    });
  };

  const { resources, excluded } = useSelector(
    selectResourcesOrderedBy(sort.orderBy, sort.criteria, sort.dataType),
    shallowEqual
  );

  const dispatch = useDispatch();

  if (loading)
    return (
      <div className="mt-5">
        <Spinner
          style={{
            marginLeft: "50%",
            marginTop: "5%",
          }}
        />
      </div>
    );

  const isSelected = (id: number) => {
    return !excluded.some((item: any) => item.id === id);
  };

  const onClickCheckbox = (item: any) => {
    if (isSelected(item.id)) {
      dispatch(
        addToExcluded({
          type: "resources",
          id: item.id,
        })
      );
    } else {
      dispatch(
        removeFromExcluded({
          type: "resources",
          id: item.id,
        })
      );
    }
  };

  if (!loading && !resources.length)
    return (
      <NoContent
        title="No Resources"
        text="No resources were found matching your query request."
        image={image}
        style={{ margin: "5% auto" }}
      />
    );

  return (
    <>
      <table className="table white-table-header-background mt-5">
        <thead className="align-middle">
          <tr className="sticky sticky-with-tabs">
            <th className="small fw-normal text-center border-dark border-top column-10">
              Select
              <div className="d-flex justify-content-center">
                <div
                  className="link-text mx-2"
                  onClick={() => dispatch(selectAll({ type: "resources" }))}
                >
                  All
                </div>
                /
                <div
                  className="link-text mx-2"
                  onClick={() => dispatch(deselectAll({ type: "resources" }))}
                >
                  None
                </div>
              </div>
            </th>
            <th
              className={`border-dark border-top fw-normal py-0 pointer column-20 ${
                sort.orderBy === "accountName" ? "sorted-" + sort.criteria : ""
              }`}
              onClick={() => sortBy("accountName", "string")}
            >
              Client & Project/WO
            </th>
            <th
              className={`border-dark border-top fw-normal py-0 pointer ${
                sort.orderBy === "date" ? "sorted-" + sort.criteria : ""
              }`}
              style={{ width: "11%" }}
              onClick={() => sortBy("date", "date")}
            >
              Date
            </th>
            <th
              className={`border-dark border-top fw-normal py-0 pointer ${
                sort.orderBy === "natureOfExpense"
                  ? "sorted-" + sort.criteria
                  : ""
              }`}
              style={{ width: "17%" }}
              onClick={() => sortBy("natureOfExpense", "string")}
            >
              Nature of expense
            </th>
            <th
              className={`border-dark border-top fw-normal py-0 pointer ${
                sort.orderBy === "description" ? "sorted-" + sort.criteria : ""
              }`}
              style={{ width: "12%" }}
              onClick={() => sortBy("description", "string")}
            >
              Description
            </th>
            <th
              className={`border-dark border-top fw-normal text-end py-0 pointer ${
                sort.orderBy === "quantity"
                  ? "sorted-" + sort.criteria
                  : "sorted-column"
              }`}
              style={{ width: "8%" }}
              onClick={() => sortBy("quantity", "number")}
            >
              Qty
            </th>
            <th
              className={`border-dark border-top fw-normal text-end py-0 pointer ${
                sort.orderBy === "price"
                  ? "sorted-" + sort.criteria
                  : "sorted-column"
              }`}
              style={{ width: "11%" }}
              onClick={() => sortBy("price", "number")}
            >
              Price
            </th>
            <th
              className={`border-dark border-top fw-normal text-end py-0 pointer ${
                sort.orderBy === "totalAmount"
                  ? "sorted-" + sort.criteria
                  : "sorted-column"
              }`}
              style={{ width: "11%" }}
              onClick={() => sortBy("totalAmount", "number")}
            >
              Total Price
            </th>
          </tr>
        </thead>
        <tbody>
          {resources.map((resource: any) => (
            <tr key={resource.id}>
              <td
                className="text-center pb-0"
                style={{ verticalAlign: "middle" }}
              >
                <div className="form-check d-inline-block">
                  <input
                    className="form-check-input"
                    id="hours"
                    type="checkbox"
                    checked={isSelected(resource.id)}
                    onChange={() => onClickCheckbox(resource)}
                  />
                </div>
              </td>
              <td>
                <div className="fw-bold">{resource.accountName}</div>
                <a
                  className="text-decoration-none mt-1"
                  href={
                    resource.projectId
                      ? projectsBaseUrl + resource.projectId
                      : workOrderBaseUrl + resource.workOrderId
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {resource.projectName || resource.workOrderDescription}
                </a>
              </td>
              <td>
                {DateTime.fromISO(resource.date, { zone: "utc" })
                  .setLocale("en-US")
                  .toFormat("DD")}
              </td>
              <td>{resource.natureOfExpense}</td>
              <td>{resource.description || resource.purpose || ""}</td>
              <td className="text-end">{resource.quantity}</td>
              <td className="fw-bold text-end">
                {currencyFormat(resource.price)}
              </td>
              <td className="fw-bold text-end">
                {currencyFormat(resource.totalAmount)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
