import { useNavigate, useParams } from "react-router-dom";
import { BsQuestionCircle } from "react-icons/bs";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import React, { useEffect, useRef, useState } from "react";
import { dateFormat } from "../../SharedModule/utils/formatters";
import { ModalPortal } from "../../SharedModule/components/ModalPortal";
import { ModalCriteria } from "../components/ModalCriteria";

import { ModalDelete } from "../components/ModalDelete";
import { useSuccessCriteria } from "../hooks/useSuccessCriteria";
import { getStatus } from "../utils/successCriteriaUtils";
import { Spinner } from "../../SharedModule/components/Spinner";
import { SuccessCriteriaService } from "../services/successCriteria";
import { addToast } from "../../SharedModule/redux/reducers/ui.reducer";
import { useDispatch, useSelector } from "react-redux";
import { SuccessCriteria } from "../types/types";
import { IterableQuill } from "../components/IterableQuill";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { setIsLoadingData as setIsLoadingDataSpotlight } from "../redux/reducers/spotlight.reducer";
import { setIsLoadingData as setIsLoadingDataKeyContact } from "../redux/reducers/keyContact.reducer";
import { setIsLoadingData } from "../redux/reducers/successCriteria.reducer";
import eventHanlder from "../../SharedModule/utils/eventHanlder";
import { setActiveTab } from "../redux/reducers/ui.reducer";
import {
  ROUTE_DELETE_PRODUCT_PAGE,
  ROUTE_FORBIDDEN_PAGE,
} from "../../SharedModule/utils/constants";
import image from "./../../styles/legacy/404.png";
import { NoContent } from "../../BillingModule/components/NoContent";
import HeaderModule from "../components/HeaderModule";
import Footer from "../../SharedModule/components/Footer";
import { selectAuth } from "../../SharedModule/redux/reducers/auth.reducer";

const mountedStyle = { animation: "inAnimation 250ms ease-in" };
const unmountedStyle = {
  animation: "outAnimation 250ms ease-out",
  animationFillMode: "forwards",
};

export const SuccessCriteriaPage = () => {
  let { productId } = useParams();

  const { trackPageView } = useMatomo();

  const auth = useSelector(selectAuth);
  const isLogged = auth.isAuthenticated;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    actualProductName,
    isContentEditable,
    isProductActive,
    successCriteriaList,
    isLoadingData,
    loadSuccessCriteriaInfo,
  } = useSuccessCriteria(Number(productId));

  // ASUITE-11:2119: endpoint error when product not exist
  if (!isLoadingData && successCriteriaList.errors) {
    if (
      successCriteriaList.errors[1] ===
      "Not enough permissions to execute action"
    ) {
      navigate(ROUTE_FORBIDDEN_PAGE);
    } else {
      navigate(ROUTE_DELETE_PRODUCT_PAGE);
    }
  }

  const [activeCriteria, setActiveCriteria] = useState<SuccessCriteria>(Object);
  const [editMode, setEditMode] = useState(false);

  let opened: number = 0;
  let achieved: number = 0;

  useEffect(() => {
    dispatch(setActiveTab("successCriteria"));
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });
    dispatch(setIsLoadingData(true));
    dispatch(setIsLoadingDataSpotlight(true));
    dispatch(setIsLoadingDataKeyContact(true));
  }, []);

  if (!successCriteriaList.errors) {
    successCriteriaList.forEach((element: SuccessCriteria) => {
      if (element.isAcheived) {
        achieved = achieved + 1;
      } else {
        opened = opened + 1;
      }
    });
  }

  // Modal config
  const modalRef = useRef();
  const showModal = () => {
    (modalRef.current as any).show(true);
  };
  const closeModal = () => {
    (modalRef.current as any).show(false);
  };
  const modalRefDelete = useRef();
  const showModalDelete = () => {
    (modalRefDelete.current as any).show(true);
  };
  const closeModalDelete = () => {
    (modalRefDelete.current as any).show(false);
  };

  const editCriteria = () => {
    setEditMode(true);
    showModal();
  };
  const deleteCriteria = async () => {
    let result;
    result = await SuccessCriteriaService.removeSuccessCriteria(
      Number(productId),
      activeCriteria.successCriteriaId
    );
    if (result === "Success Criteria Deleted Successfully.") {
      dispatch(addToast({ mode: "success", message: result }));
      loadSuccessCriteriaInfo();
    } else {
      dispatch(addToast({ mode: "error", message: "Something went wrong" }));
    }
    eventHanlder.dispatch("toast action", { isClosed: false });
    closeModalDelete();
  };

  const onCloseModal = () => {
    setEditMode(false);
    closeModal();
  };

  const handleSelectCriteria = (criteria: SuccessCriteria) => {
    setActiveCriteria(criteria);
  };

  const checkActiveCriteria = (criteria: SuccessCriteria) => {
    return activeCriteria === criteria;
  };

  const handleActionButton = async (event: any, criteria: SuccessCriteria) => {
    event.preventDefault();
    let result = await SuccessCriteriaService.achieveSuccessCriteria(
      Number(productId),
      criteria.successCriteriaId,
      !criteria.isAcheived
    );
    if (result === "Success Criteria Updated Successfully.") {
      dispatch(
        addToast({
          mode: "success",
          message: `${
            criteria.isAcheived ? "Unachieved" : "Achieved"
          }: ${result}`,
        })
      );
      loadSuccessCriteriaInfo();
    } else {
      dispatch(addToast({ mode: "error", message: "Something went wrong" }));
    }
    eventHanlder.dispatch("toast action", { isClosed: false });
  };

  return (
    <>
      <ModalPortal ref={modalRef} zIndex={170}>
        <ModalCriteria
          title={editMode ? "Edit Criteria" : "Add Criteria"}
          btnLabel="Save Criteria"
          btnCancelLabel="Cancel"
          btnCancel={onCloseModal}
          actionPlaceholder="notCloseOnClickOutside"
          type="formModal"
          dataType="criteria"
          editMode={editMode}
          activeItem={activeCriteria}
          onSuccessEvent={loadSuccessCriteriaInfo}
        />
      </ModalPortal>
      <ModalPortal ref={modalRefDelete} zIndex={170}>
        <ModalDelete
          title="Confirm delete?"
          btnLabel="Delete"
          btnCancelLabel="Cancel"
          btnAction={deleteCriteria}
          btnCancel={closeModalDelete}
          actionPlaceholder="notCloseOnClickOutside"
          dataType="criteria"
        />
      </ModalPortal>
      <div className="content">
        <HeaderModule />
        {isLoadingData && (
          <Spinner
            style={{
              margin: "15% 45%",
            }}
          />
        )}
        {!isLoadingData && (
          <>
            <div className="content-header">
              <div className="content-header-title">
                {actualProductName} - Success Criteria{" "}
                <Tooltip
                  title={
                    <React.Fragment>
                      <b>{"Project Success Criteria"}</b>
                      <br />
                      {
                        "Those things identified and agreed by stakeholders which need to be achieved in order that the project is considered a success. The success criteria will be measurable and will be regularly reviewed to ensure that it is still achievable. The project manager/scrum master will be responsible for managing the time, cost & quality element of the success criteria during the project but it will ultimately be the stakeholders who use the criteria at the end of the project to establish whether the project was a success."
                      }
                      <br />
                      <br />
                      <b>{"Product Success Criteria"}</b>
                      <br />
                      {
                        'Similar to project, but these criteria apply to the software product we are building and how the client will view their software product as a success. Product Success Criteria may also be likened to the pKPI\'s defined by the Innovation Lead with the client. They will most likely extend beyond the life of a "project", as the product needs to be live in production in order to gauge whether those criteria are being met.'
                      }
                    </React.Fragment>
                  }
                  placement="bottom"
                  TransitionComponent={Zoom}
                  arrow
                >
                  <span>
                    <BsQuestionCircle className="iconTooltip" />
                  </span>
                </Tooltip>
                <span className="count-amount">
                  ( {opened} Open, {achieved} Achieved )
                </span>
                {!isProductActive && (
                  <button className="btn-status">Status: Inactive</button>
                )}
              </div>
              {isLogged && isContentEditable && isProductActive && (
                <button className="btn btn-primary" onClick={() => showModal()}>
                  Add criteria
                </button>
              )}
            </div>

            {/* empty criteria list */}
            {successCriteriaList.length === 0 && (
              <NoContent
                title="No Success Criteria"
                image={image}
                style={{ marginTop: "3%" }}
              />
            )}

            {/* criteria list */}
            {successCriteriaList.length > 0 &&
              successCriteriaList.map((criteria: SuccessCriteria) => {
                return (
                  <div
                    key={criteria.successCriteriaId}
                    className={`criteria-list-block ${
                      checkActiveCriteria(criteria) ? "selected" : ""
                    } ${!criteria.isAcheived ? "unaddressed" : ""}`}
                    onClick={() => handleSelectCriteria(criteria)}
                  >
                    <div className="d-flex justify-content-between">
                      {criteria.title && (
                        <div className="criteria-title">{criteria.title}</div>
                      )}
                      {isLogged &&
                        isContentEditable &&
                        isProductActive &&
                        checkActiveCriteria(criteria) && (
                          <button
                            className="btn button-transparent button-transparent-red"
                            onClick={() => showModalDelete()}
                          >
                            Delete
                          </button>
                        )}
                    </div>
                    <div className="mt-2 mb-3 criteria-details">
                      <span className="criteria-bold">{`${criteria.lastUpdatedBy}`}</span>
                      {!criteria.isAcheived && (
                        <span className="criteria-date">{` updated on ${dateFormat(
                          criteria.lastVerified
                        )}`}</span>
                      )}
                      {criteria.isAcheived && (
                        <span className="criteria-date">{` achieved as of ${dateFormat(
                          criteria.lastVerified
                        )}`}</span>
                      )}
                    </div>
                    <div className="mt-2">
                      <div className={`${getStatus(criteria.status)} me-2`}>
                        {getStatus(criteria.status)
                          .replaceAll("-", " ")
                          .toLocaleUpperCase()}
                      </div>
                      <span className="bold addressed-status me-2">
                        {!criteria.isAcheived ? "Unachieved" : "Achieved"}
                      </span>
                    </div>

                    {checkActiveCriteria(criteria) && (
                      <div
                        className="criteria-detail-text d-flex flex-column"
                        style={
                          checkActiveCriteria(criteria)
                            ? mountedStyle
                            : unmountedStyle
                        }
                      >
                        <IterableQuill
                          key={criteria.successCriteriaId}
                          value={criteria.description}
                        />
                        {isLogged && isContentEditable && isProductActive && (
                          <div className="d-flex justify-content-end">
                            {!criteria.isAcheived && (
                              <button
                                className="btn button-transparent button-transparent-purple me-3"
                                disabled={
                                  !isProductActive || !isContentEditable
                                }
                                onClick={() => editCriteria()}
                              >
                                Edit
                              </button>
                            )}
                            <button
                              className="btn button-transparent button-transparent-purple"
                              disabled={!isProductActive || !isContentEditable}
                              onClick={(event) =>
                                handleActionButton(event, criteria)
                              }
                            >
                              {` Mark as ${
                                criteria.isAcheived ? "Unacheived" : "Acheived"
                              }`}
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
          </>
        )}
        <Footer />
      </div>
    </>
  );
};
