import { Calendar } from "../../SharedModule/components/Calendar";
import { InputNumberHours } from "../../SharedModule/components/InputNumberHours";
import { Select } from "../../SharedModule/components/Select";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectMetaTimesData } from "../redux/reducers/meta.reducer";
import {
  TimeEntryEditForm,
  TimeEntryForm,
  TimeEntryPostForm,
  TimeEntrySheetView,
} from "../types";
import {
  empty,
  emptyProjectWorkOrder,
  getCategoryId,
  getNextWeekDay,
  getProjectWorOrder,
  getValueFromSelect,
  hasTimeAdminAccess,
} from "../utils/timesUtils";
import {
  IsSubmitted,
  setLoading,
  updateEntry,
} from "../redux/reducers/times.reducer";
import { TimesheetsService } from "../services/timesheets";
import { addToast } from "../../SharedModule/redux/reducers/ui.reducer";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { getPreviousWorkableDay } from "../../SharedModule/utils/dateUtils";
import { BiEditAlt, BiTrash, BiError } from "react-icons/bi";
import { dateFormatEntryEdit } from "../../SharedModule/utils/formatters";
import { actualUIState } from "../../ProductsModule/redux/reducers/ui.reducer";

export const EntryHoursBox = (props: any) => {
  const MetaTimesInfo = useSelector(selectMetaTimesData);

  // times module admin
  const { userRolesTimesModule } = useSelector(actualUIState);
  const isSubmitted = useSelector(IsSubmitted);

  const [isTimeAdmin] = useState(hasTimeAdminAccess(userRolesTimesModule));

  const dispatch = useDispatch();

  const initialState: TimeEntryForm = {
    entryCanEdit: props.state.canEdit,
    entryCanDelete: props.state.canDelete,
    entryId: props.state.entryId,
    entryDate: props.state.entryDate,
    entryDateValid: true,
    entryProjectWorkOrder: emptyProjectWorkOrder,
    entryProjectWorkOrderValid: false,
    entryHours: "",
    entryHoursValid: false,
    entryTaskType: empty,
    entryTaskTypeValid: false,
    entryDescription: "",
    entryDescriptionValid: false,
    entryIsBillable: true,
    entryNonBillableReason: empty,
    entryNonBillableReasonValid: false,
    isEntryValid: false,
    isEntryTouched: false,
  };

  const [entryState, setEntryState] = useState(props.state);

  useEffect(() => {
    checkEntryValid();
  }, [entryState]);

  const checkEntryValid = () => {
    // edit entry on page times, not modal => update redux state
    if (!props.isModal) {
      dispatch(updateEntry({ entryState }));
    }

    if (entryState.isEntryValid && !props.isModal) {
      props.isTouched();
    }
  };

  const checkStatus = (property: string, isFieldValid: boolean) => {
    switch (property) {
      case "entryProjectWorkOrder":
        return (
          entryState.entryDateValid &&
          isFieldValid &&
          entryState.entryHoursValid &&
          entryState.entryTaskTypeValid &&
          entryState.entryDescriptionValid &&
          (entryState.entryIsBillable ||
            (!entryState.entryIsBillable &&
              entryState.entryNonBillableReasonValid))
        );
      case "entryDate":
        return (
          isFieldValid &&
          entryState.entryProjectWorkOrderValid &&
          entryState.entryHoursValid &&
          entryState.entryTaskTypeValid &&
          entryState.entryDescriptionValid &&
          (entryState.entryIsBillable ||
            (!entryState.entryIsBillable &&
              entryState.entryNonBillableReasonValid))
        );
      case "entryHours":
        return (
          entryState.entryDateValid &&
          entryState.entryProjectWorkOrderValid &&
          isFieldValid &&
          entryState.entryTaskTypeValid &&
          entryState.entryDescriptionValid &&
          (entryState.entryIsBillable ||
            (!entryState.entryIsBillable &&
              entryState.entryNonBillableReasonValid))
        );
      case "entryTaskType":
        return (
          entryState.entryDateValid &&
          entryState.entryProjectWorkOrderValid &&
          entryState.entryHoursValid &&
          isFieldValid &&
          entryState.entryDescriptionValid &&
          (entryState.entryIsBillable ||
            (!entryState.entryIsBillable &&
              entryState.entryNonBillableReasonValid))
        );
      case "entryDescription":
        return (
          entryState.entryDateValid &&
          entryState.entryProjectWorkOrderValid &&
          entryState.entryHoursValid &&
          entryState.entryTaskTypeValid &&
          isFieldValid &&
          (entryState.entryIsBillable ||
            (!entryState.entryIsBillable &&
              entryState.entryNonBillableReasonValid))
        );
      case "entryNonBillableReason":
        return (
          entryState.entryDateValid &&
          entryState.entryProjectWorkOrderValid &&
          entryState.entryHoursValid &&
          entryState.entryTaskTypeValid &&
          entryState.entryDescriptionValid &&
          (entryState.entryIsBillable || isFieldValid)
        );
      case "entryIsBillable":
        return (
          entryState.entryDateValid &&
          entryState.entryProjectWorkOrderValid &&
          entryState.entryHoursValid &&
          entryState.entryTaskTypeValid &&
          entryState.entryDescriptionValid &&
          (isFieldValid || entryState.entryNonBillableReasonValid)
        );
      default:
        break;
    }
  };

  const handleChange = (value, property) => {
    let isFieldValid;
    let actualEntryValid;

    switch (property) {
      case "entryProjectWorkOrder":
        isFieldValid =
          value.label !== emptyProjectWorkOrder.label &&
          value.value !== emptyProjectWorkOrder.value;
        actualEntryValid = checkStatus(property, isFieldValid);
        setEntryState({
          ...entryState,
          entryProjectWorkOrderValid: isFieldValid,
          isEntryTouched: true,
          isEntryValid: actualEntryValid,
          [property]: { value: value.value, label: value.label },
        });
        break;
      case "entryDate":
        isFieldValid = isTimeAdmin
          ? true
          : previousWorkableDay <= new Date(value) &&
            new Date(value) <= lastWorkableDay;
        actualEntryValid = checkStatus(property, isFieldValid);
        setEntryState({
          ...entryState,
          [property]: value,
          entryDateValid: isFieldValid,
          isEntryTouched: true,
          isEntryValid: actualEntryValid,
        });
        break;
      case "entryHours":
        isFieldValid =
          Number(value) > 0 && Number(value) < 24 && Number(value) % 0.25 === 0;
        actualEntryValid = checkStatus(property, isFieldValid);
        setEntryState({
          ...entryState,
          [property]: value,
          entryHoursValid: isFieldValid,
          isEntryTouched: true,
          isEntryValid: actualEntryValid,
        });
        break;
      case "entryTaskType":
        isFieldValid =
          value.label !== empty.label && value.value !== empty.value;
        actualEntryValid = checkStatus(property, isFieldValid);
        setEntryState({
          ...entryState,
          [property]: value,
          entryTaskTypeValid: isFieldValid,
          isEntryTouched: true,
          isEntryValid: actualEntryValid,
        });
        break;
      case "entryDescription":
        isFieldValid = value.length > 0;
        actualEntryValid = checkStatus(property, isFieldValid);
        setEntryState({
          ...entryState,
          [property]: value,
          entryDescriptionValid: isFieldValid,
          isEntryTouched: true,
          isEntryValid: actualEntryValid,
        });
        break;
      case "entryNonBillableReason":
        isFieldValid =
          entryState.entryIsBillable ||
          (value.label !== empty.label && value.value !== empty.value);
        actualEntryValid = checkStatus(property, isFieldValid);
        setEntryState({
          ...entryState,
          [property]: value,
          entryNonBillableReasonValid: isFieldValid,
          isEntryTouched: true,
          isEntryValid: actualEntryValid,
        });
        break;
      case "entryIsBillable":
        actualEntryValid = checkStatus(property, value);
        setEntryState({
          ...entryState,
          [property]: value,
          isEntryTouched: true,
          isEntryValid: actualEntryValid,
        });
        break;
      default:
        break;
    }
  };

  let previousWorkableDay = getPreviousWorkableDay(1, props.holidays);

  let lastWorkableDay: any = getNextWeekDay(6, new Date());

  const saveAction = async () => {
    let request: TimeEntryPostForm[] = [];
    let bodyEdit: TimeEntryEditForm;
    if (props.editMode) {
      // services to edit
      bodyEdit = {
        entryDate: entryState.entryDate,
        hours: entryState.entryHours,
        taskTypeId: entryState.entryTaskType.value,
        description: entryState.entryDescription,
        entityId: entryState.entryProjectWorkOrder.value,
        timeEntryCategoryId: getCategoryId(
          props.projectsWorkOrders,
          entryState.entryProjectWorkOrder.value
        ),
        timeEntryTypeId: entryState.entryIsBillable ? 1 : 2,
        // could be non billable from BE, so sometimes entryNonBillableReason still empty on entry nonBillable
        nonBillableReasonId: entryState.entryIsBillable
          ? null
          : entryState.entryNonBillableReason.value
          ? entryState.entryNonBillableReason.value
          : null,
      };
      dispatch(setLoading(true));
      props.btnCancel();
      await TimesheetsService.editTimesEntry(bodyEdit, entryState.entryId)
        .then((response) => {
          dispatch(
            addToast({ mode: "success", message: response.data.message })
          );
          props.reloadPage();
        })
        .catch((error) => {
          dispatch(addToast({ mode: "error", message: error }));
          props.reloadPage();
        });
    } else {
      // replicates services to add in time pages
      request.push({
        userId: props.userIdParam,
        entryDate: entryState.entryDate,
        hours: entryState.entryHours,
        taskTypeId: entryState.entryTaskType.value,
        description: entryState.entryDescription,
        entityId: entryState.entryProjectWorkOrder.value,
        timeEntryCategoryId: getCategoryId(
          props.projectsWorkOrders,
          entryState.entryProjectWorkOrder.value
        ),
        timeEntryTypeId: entryState.entryIsBillable ? 1 : 2,
        timeEntrySourceId: 1,
        nonBillableReasonId: entryState.entryIsBillable
          ? null
          : entryState.entryNonBillableReason.value,
      });
      dispatch(setLoading(true));
      props.btnCancel();
      await TimesheetsService.addTimesEntries(request)
        .then((response) => {
          // Entry was not save correctly
          if (response.data.data && response.data.data.length > 0) {
            dispatch(
              addToast({
                mode: "error",
                message: "Time Entry was not saved correctly",
              })
            );
          } else {
            dispatch(
              addToast({ mode: "success", message: response.data.message })
            );
          }
          props.reloadPage();
        })
        .catch((error) => {
          dispatch(addToast({ mode: "error", message: error }));
          props.reloadPage();
        });
    }
  };

  const updateInfoModal = (entry: TimeEntrySheetView) => {
    let newEditForm: TimeEntryForm = {
      entryCanEdit: entry.canEdit,
      entryCanDelete: entry.canDelete,
      entryId: entry.timeEntryId.toString(),
      entryDate: entry.entryDate,
      entryDateValid: true,
      entryProjectWorkOrder: getProjectWorOrder(
        props.projectsWorkOrders,
        entry.entityId
      ),
      entryProjectWorkOrderValid: true,
      entryHours: entry.hours,
      entryHoursValid: true,
      entryTaskType: getValueFromSelect(
        MetaTimesInfo.taskTypes,
        entry.taskTypeId
      ),
      entryTaskTypeValid: true,
      entryDescription: entry.description,
      entryDescriptionValid: true,
      entryIsBillable: entry.timeEntryTypeId === 1,
      entryNonBillableReason:
        entry.timeEntryTypeId === 1
          ? empty
          : getValueFromSelect(
              MetaTimesInfo.nonBillableReasons,
              entry.nonBillableReasonId
            ),
      entryNonBillableReasonValid: entry.nonBillableReasonId ? true : false,
      isEntryValid: false,
      isEntryTouched: false,
    };

    setEntryState(newEditForm);
  };

  // Sheet view save - modal (add/edit)
  const clickHandler = () => {
    // if entry is valid => save, if not modal still present
    if (entryState.isEntryValid) {
      saveAction();
    }
  };

  return (
    <form
      className={`card entry-hour-box-background px-4 py-3 ${
        !props.isModal ? "my-3" : ""
      }`}
    >
      {props.title && (
        <div className="row">
          <h4>{props.title}</h4>
        </div>
      )}
      {props.multipleTimeEntries && (
        <>
          <div className="row">
            <div className="d-flex">
              <div className="border-dark fw-500 ps-2 column-10">Date</div>
              <div className="border-dark fw-500 ps-2 column-10">Time</div>
              <div className="border-dark fw-500 ps-2 column-15">Type</div>
              <div className="border-dark fw-500 ps-2 column-55">
                Description
              </div>
              <div className="border-dark fw-500 text-center py-0 ps-2 column-10"></div>
            </div>
          </div>
          {props.multipleTimeEntries.map((entry: TimeEntrySheetView) => {
            return (
              <div className="row mt-1" key={entry.timeEntryId}>
                <div className="d-flex table-row-multiple-entries">
                  <div className="column-10 fw-500 py-1 ps-2">
                    {dateFormatEntryEdit(entry.entryDate)}
                  </div>
                  <div className="column-10 fw-500 py-1 ps-2">
                    {`${entry.hours.toFixed(2)} hours`}
                  </div>
                  <div className="column-15 fw-500 py-1 ps-2">
                    {
                      getValueFromSelect(
                        MetaTimesInfo.taskTypes,
                        entry.taskTypeId
                      ).label
                    }
                  </div>
                  <div className="column-55 fw-500 py-1 ps-2">
                    <div className="entry-description">{entry.description}</div>
                  </div>
                  <div className="d-flex column-10 justify-content-center py-1 ps-2">
                    <BiEditAlt
                      className={`icon-entry-action me-2 ${
                        entry.canEdit ? "" : "disabled"
                      }`}
                      onClick={() =>
                        entry.canEdit ? updateInfoModal(entry) : undefined
                      }
                    />
                    <BiTrash
                      className={`icon-entry-action ${
                        entry.canDelete ? "" : "disabled"
                      }`}
                      onClick={() =>
                        entry.canDelete ? props.deleteEntry(entry) : undefined
                      }
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}

      <div className="row mt-3">
        <div
          className="d-flex flex-column col-sm-3"
          style={{ maxWidth: "220px" }}
        >
          <label className="col-form-label">Date</label>
          <Calendar
            className={`me-3 ${
              entryState.isEntryTouched &&
              isSubmitted &&
              !entryState.entryDateValid
                ? "border-error"
                : ""
            }`}
            date={entryState.entryDate}
            onChange={(value) => handleChange(value, "entryDate")}
            minDate={isTimeAdmin ? undefined : previousWorkableDay}
            maxDate={isTimeAdmin ? undefined : lastWorkableDay}
            isDisabled={false}
          />
          {entryState.isEntryTouched &&
            isSubmitted &&
            !entryState.entryDateValid && (
              <div className="error-message">
                <BiError className="error-icon" />
                <span className="ms-1">{`${
                  new Date(entryState.entryDate) < previousWorkableDay
                    ? "You cannot enter time past the last working day"
                    : "You cannot enter time further than this week"
                }`}</span>
              </div>
            )}
        </div>
        <div className="d-flex flex-column col-sm-7" style={{ flexGrow: "1" }}>
          <label className="col-form-label">Project / Work order</label>
          <Select
            className={`me-3 ${
              entryState.isEntryTouched &&
              isSubmitted &&
              !entryState.entryProjectWorkOrderValid
                ? "border-error"
                : ""
            }`}
            placeholder="Select a project, task or work order you are assigned to"
            options={props.projectsWorkOrders}
            value={entryState.entryProjectWorkOrder}
            isLoading={props.isLoadingProjects}
            onChange={(project) =>
              handleChange(project, "entryProjectWorkOrder")
            }
            isDisabled={false}
          />
          {entryState.isEntryTouched &&
            isSubmitted &&
            !entryState.entryProjectWorkOrderValid && (
              <div className="error-message">
                <BiError className="error-icon" />
                <span className="ms-1">Select project or work order</span>
              </div>
            )}
        </div>
        <div
          className="d-flex flex-column col-sm-2"
          style={{ maxWidth: "220px" }}
        >
          <label className="col-form-label">Hours</label>
          <InputNumberHours
            className={`me-3 ${
              entryState.isEntryTouched &&
              isSubmitted &&
              !entryState.entryHoursValid
                ? "border-error"
                : ""
            }`}
            hours={entryState.entryHours}
            setHours={(value) => handleChange(value, "entryHours")}
          />
          {entryState.isEntryTouched &&
            isSubmitted &&
            !entryState.entryHoursValid && (
              <div className="error-message">
                <BiError className="error-icon" />
                <span className="ms-1">{`${
                  entryState.entryHours === 0 || entryState.entryHours === 24
                    ? "Must be between 0.25 and 23.75 hours"
                    : "Needs to be multiple of 0.25"
                }`}</span>
              </div>
            )}
        </div>
      </div>
      <div className="row mt-3">
        <div
          className="d-flex flex-column col-sm-3"
          style={{ maxWidth: "220px" }}
        >
          <label className="col-form-label">Task type</label>
          <Select
            className={`me-3 ${
              entryState.isEntryTouched &&
              isSubmitted &&
              !entryState.entryTaskTypeValid
                ? "border-error"
                : ""
            }`}
            options={MetaTimesInfo.taskTypes}
            value={entryState.entryTaskType}
            isLoading={!MetaTimesInfo.loaded}
            onChange={(value) => handleChange(value, "entryTaskType")}
            isDisabled={false}
          />
          {entryState.isEntryTouched &&
            isSubmitted &&
            !entryState.entryTaskTypeValid && (
              <div className="error-message">
                <BiError className="error-icon" />
                <span className="ms-1">Select task type</span>
              </div>
            )}
          {isTimeAdmin && (
            <>
              <div className="d-flex mt-4">
                <input
                  className="form-check-input"
                  id="billable"
                  type="checkbox"
                  checked={entryState.entryIsBillable}
                  onChange={(e) =>
                    handleChange(e.target.checked, "entryIsBillable")
                  }
                />
                <label className="form-check-label ms-3">Billable</label>
              </div>
              {!entryState.entryIsBillable && (
                <div className="row mt-3">
                  <div
                    className="d-flex flex-column"
                    style={{ maxWidth: "220px" }}
                  >
                    <label className="col-form-label">
                      Non-billable reason
                    </label>
                    <Select
                      className={`me-3 ${
                        entryState.isEntryTouched &&
                        isSubmitted &&
                        !entryState.entryNonBillableReasonValid
                          ? "border-error"
                          : ""
                      }`}
                      options={MetaTimesInfo.nonBillableReasons}
                      value={entryState.entryNonBillableReason}
                      isLoading={!MetaTimesInfo.loaded}
                      onChange={(value) =>
                        handleChange(value, "entryNonBillableReason")
                      }
                      isDisabled={false}
                    />
                  </div>
                  {entryState.isEntryTouched &&
                    isSubmitted &&
                    !entryState.entryNonBillableReasonValid && (
                      <div className="error-message">
                        <BiError className="error-icon" />
                        <span className="ms-1">
                          You must enter a description
                        </span>
                      </div>
                    )}
                </div>
              )}
            </>
          )}
        </div>
        <div className="d-flex flex-column col-sm-9" style={{ flexGrow: "1" }}>
          <label className="col-form-label">Description</label>
          <TextareaAutosize
            minRows={3}
            maxRows={6}
            maxLength={4000}
            className={`textarea-entry-box ${
              entryState.isEntryTouched &&
              isSubmitted &&
              !entryState.entryDescriptionValid
                ? "border-error"
                : ""
            }`}
            placeholder="—"
            value={entryState.entryDescription}
            onChange={(e) => handleChange(e.target.value, "entryDescription")}
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
          />
          {entryState.isEntryTouched &&
            isSubmitted &&
            !entryState.entryDescriptionValid && (
              <div className="error-message">
                <BiError className="error-icon" />
                <span className="ms-1">You must enter a description</span>
              </div>
            )}
        </div>
      </div>
      <div className="row mt-3">
        {!props.isModal && (
          <div
            className="clear-button pe-4"
            onClick={() => setEntryState(initialState)}
          >
            Clear
          </div>
        )}
        {props.isModal && (
          <div
            className="d-flex justify-content-end offset-sm-9 col-sm-3 mt-auto"
            style={{ height: "40px" }}
          >
            <button
              className="btn button-secondary w-25"
              onClick={props.btnCancel}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary w-25 ms-3"
              disabled={
                !entryState.isEntryValid ||
                !entryState.isEntryTouched ||
                (props.editMode && !entryState.entryCanEdit)
              }
              onClick={() => clickHandler()}
            >
              Save
            </button>
          </div>
        )}
      </div>
    </form>
  );
};
