import { FaArrowUp, FaArrowDown } from "react-icons/fa";

// when advocacy data is a string (in key contacts page)
export const getAdvocacyString = (type: string) => {
  switch (type) {
    case "Advocacy":
      return "advocate";
    case "Trust":
      return "trust";
    case "Below Trust":
      return "below";
    case "Loyalty":
      return "loyal";
    default:
      return "";
  }
};

// when advocacy data is a number (in key contacts details page)
export const getAdvocacyNumber = (type: number) => {
  switch (type) {
    case 4:
      return "advocate";
    case 2:
      return "trust";
    case 1:
      return "below";
    case 3:
      return "loyal";
    default:
      return "";
  }
};

export const getRelationshipTrend = (trend: string | number) => {
  if (typeof trend === 'number'){
    trend = getTrendingName(trend);
  }
  switch (trend) {
    case "Up":
      return <FaArrowUp />;
    case "Down":
      return <FaArrowDown />;
    case "Same":
    default:
      return "";
  }
}


export const transformNameWithoutComma = (name: string) => {
  let names = name.split(",");
  return `${names[1].trim()} ${names[0]}`;
}

// Helper function to load label in selects
export const getUserNameToCompleteSelect = (structure: any, id: string | null) => {
  if (id) {
    let userFinded = structure.find((elem: any) => elem.value === id);
    return userFinded ? userFinded.label : "";
  }
};

export const getTrendingName = (trend: number) => {
  switch (trend) {
    case 1:
      return "Up";
    case 2:
      return "Down";
    case 3:
    default:
      return "Same";
  }
};

export const getInitials = (name: string) => {
  let names = name.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

