import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useEffect, useState } from "react";
import Footer from "../../SharedModule/components/Footer";
import { Select } from "../../SharedModule/components/Select";
import { Calendar } from "../../SharedModule/components/Calendar";
import { useDispatch, useSelector } from "react-redux";
import { BiInfoCircle } from "react-icons/bi";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { Checkbox } from "../../SharedModule/components/Checkbox";
import {
  getLastWeekDay,
  getNextWeekDay,
  getPrevSunday,
  hasTimeAdminAccess,
} from "../utils/timesUtils";
import {
  LAST_MONTH,
  LAST_WEEK,
  ROUTE_QUERY_RESULTS_TIMESHEETS,
  THIS_MONTH,
  THIS_WEEK,
} from "../../SharedModule/utils/constants";
import { selectMetaTimesData } from "../redux/reducers/meta.reducer";
import { useNavigate } from "react-router-dom";
import {
  SelectFilters,
  setFilters,
  SelectInitialStateFilters,
  keepFilters,
  setAdditionalFilters,
  IsShowAdditionalFilters,
} from "../redux/reducers/timesQuery.reducer";
import { actualUIState } from "../../ProductsModule/redux/reducers/ui.reducer";

export const TimesQueryPage = () => {
  const { trackPageView } = useMatomo();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const MetaTimesInfo = useSelector(selectMetaTimesData);

  const activeUsers = MetaTimesInfo.timeUsers?.filter((user) => user.isActive);

  const filters = useSelector(SelectFilters);
  const isShowAdditionalFilters = useSelector(IsShowAdditionalFilters);

  const initialStatefilters = useSelector(SelectInitialStateFilters);

  useEffect(() => {
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });

    return () => {
      dispatch(keepFilters(false));
      dispatch(setAdditionalFilters(false));
    };
  }, []);

  const today = new Date();
  // should use a huge object
  const [pageFilters, setPageFilters] = useState({
    // Filters
    startDate: filters.startDate,
    endDate: filters.endDate,
    costCenters: filters.costCenters,
    clients: filters.clients,
    projects: filters.projects,
    projectManagers: filters.projectManagers,
    users: filters.users,
    // aditional filters
    includeInactiveUsers: filters.includeInactiveUsers,
    taskTypes: filters.taskTypes,
    countries: filters.countries,
    hourType: {
      billableHours: filters.hourType.billableHours,
      nonBillableHours: filters.hourType.nonBillableHours,
      internalHours: filters.hourType.internalHours,
    },
    nonBillableReasons: filters.nonBillableReasons,
    timeOff: {
      paidTimeOff: filters.timeOff.paidTimeOff,
      unpaidTimeOff: filters.timeOff.unpaidTimeOff,
    },
    approvalStatus: {
      pendingHours: filters.approvalStatus.pendingHours,
      approvedHours: filters.approvalStatus.approvedHours,
      lockedHours: filters.approvalStatus.lockedHours,
    },
  });

  // times module admin
  const { userRolesTimesModule } = useSelector(actualUIState);
  const [isTimeAdmin] = useState(hasTimeAdminAccess(userRolesTimesModule));

  const [showAdditionalFilters, setShowAdditionalFilters] = useState(
    isShowAdditionalFilters
  );

  const updateDates = (option: string) => {
    switch (option) {
      case THIS_WEEK:
        setPageFilters({
          ...pageFilters,
          startDate: getPrevSunday(today).toISOString(),
          endDate: getNextWeekDay(6, today).toISOString(),
        });
        break;
      case LAST_WEEK:
        setPageFilters({
          ...pageFilters,
          startDate: getLastWeekDay(7, today).toISOString(),
          endDate: getLastWeekDay(1, today).toISOString(),
        });
        break;
      case THIS_MONTH:
        setPageFilters({
          ...pageFilters,
          startDate: new Date(today.getFullYear(), today.getMonth(), 1).toISOString(),
          endDate: new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString(),
        });
        break;
      case LAST_MONTH:
        setPageFilters({
          ...pageFilters,
          startDate: new Date(today.getFullYear(), today.getMonth() - 1, 1).toISOString(),
          endDate: new Date(today.getFullYear(), today.getMonth(), 0).toISOString(),
        });
        break;
      default:
        break;
    }
  };

  const toggleShowAdditionalsFilters = () => {
    setShowAdditionalFilters(!showAdditionalFilters);
  };

  const resetFiltersPage = () => {
    setPageFilters(initialStatefilters);
  };

  const runQuery = () => {
    if (!showAdditionalFilters) {
      dispatch(
        setFilters({
          ...pageFilters,
          includeInactiveUsers: false,
          taskTypes: null,
          countries: null,
          hourType: {
            billableHours: true,
            nonBillableHours: true,
            internalHours: true,
          },
          nonBillableReasons: null,
          timeOff: {
            paidTimeOff: true,
            unpaidTimeOff: false,
          },
          approvalStatus: {
            pendingHours: true,
            approvedHours: true,
            lockedHours: true,
          },
        })
      );
    } else {
      dispatch(setFilters(pageFilters));
    }
    navigate(ROUTE_QUERY_RESULTS_TIMESHEETS);
  };

  return (
    <div className="content">
      <div className="d-flex flex-wrap">
        <h2 className="flex-fill">Query</h2>
      </div>
      <form className="card entry-hour-box-background p-4 mt-3">
        <div className="row justify-content-center">
          <label className="col-sm-3 col-form-label">Time Frame</label>
          <div className="col-sm-5">
            <div className="d-flex">
              <div className="position-relative">
                <label className="form-label">From</label>
                <Calendar
                  date={pageFilters.startDate}
                  onChange={(date: string) =>
                    setPageFilters({ ...pageFilters, startDate: date })
                  }
                />
              </div>
              <div className="position-relative ms-5">
                <label className="form-label">To</label>
                <Calendar
                  date={pageFilters.endDate}
                  onChange={(date: string) =>
                    setPageFilters({ ...pageFilters, endDate: date })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="mt-2 offset-sm-5 col-sm-7">
            <div
              className="btn button-secondary"
              onClick={() => updateDates(THIS_WEEK)}
            >
              {THIS_WEEK}
            </div>
            <div
              className="btn button-secondary ms-2"
              onClick={() => updateDates(LAST_WEEK)}
            >
              {LAST_WEEK}
            </div>
            <div
              className="btn button-secondary ms-2"
              onClick={() => updateDates(THIS_MONTH)}
            >
              {THIS_MONTH}
            </div>
            <div
              className="btn button-secondary ms-2"
              onClick={() => updateDates(LAST_MONTH)}
            >
              {LAST_MONTH}
            </div>
          </div>
        </div>

        <div className="row mt-4 justify-content-center">
          <label className="col-sm-3 col-form-label">
            Cost Center
            <Tooltip
              title={<b>Users assigned to this cost center</b>}
              placement="right"
              TransitionComponent={Zoom}
              arrow
            >
              <span>
                <BiInfoCircle className="disc-icon ms-1" />
              </span>
            </Tooltip>
          </label>
          <div className="col-sm-5">
            <Select
              isMulti
              placeholder="All"
              options={MetaTimesInfo.costCenters}
              value={pageFilters.costCenters}
              onChange={(costCenter) =>
                setPageFilters({ ...pageFilters, costCenters: costCenter })
              }
              isDisabled={false}
            />
          </div>
        </div>

        <div className="row mt-3 justify-content-center">
          <label className="col-sm-3 col-form-label">Clients</label>
          <div className="col-sm-5">
            <Select
              isMulti
              placeholder="All"
              options={MetaTimesInfo.clients}
              value={pageFilters.clients}
              onChange={(client) =>
                setPageFilters({ ...pageFilters, clients: client })
              }
              isDisabled={false}
            />
          </div>
        </div>

        <div className="row mt-3 justify-content-center">
          <label className="col-sm-3 col-form-label">Projects</label>
          <div className="col-sm-5">
            <Select
              isMulti
              placeholder="All"
              options={MetaTimesInfo.timeProjects}
              value={pageFilters.projects}
              onChange={(project) =>
                setPageFilters({ ...pageFilters, projects: project })
              }
              isDisabled={false}
            />
          </div>
        </div>

        <div className="row mt-3 justify-content-center">
          <label className="col-sm-3 col-form-label">
            Project Manager
            <Tooltip
              title={
                <b>
                  Returns time entered by something by these project managers
                </b>
              }
              placement="right"
              TransitionComponent={Zoom}
              arrow
            >
              <span>
                <BiInfoCircle className="disc-icon ms-1" />
              </span>
            </Tooltip>
          </label>
          <div className="col-sm-5">
            <Select
              isMulti
              placeholder="All"
              options={MetaTimesInfo.projectManagers}
              value={pageFilters.projectManagers}
              onChange={(manager) =>
                setPageFilters({ ...pageFilters, projectManagers: manager })
              }
              isDisabled={false}
            />
          </div>
        </div>

        <div className="row mt-3 justify-content-center">
          <label className="col-sm-3 col-form-label">Users</label>
          <div className="col-sm-5">
            <Select
              isMulti
              placeholder="All"
              options={
                pageFilters.includeInactiveUsers
                  ? MetaTimesInfo.timeUsers
                  : activeUsers
              }
              value={pageFilters.users}
              onChange={(user) =>
                setPageFilters({ ...pageFilters, users: user })
              }
              isDisabled={false}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-sm-5 offset-sm-5">
            <div
              className="link-text px-0"
              onClick={() => toggleShowAdditionalsFilters()}
            >
              {showAdditionalFilters ? "Hide" : "Show"} additional filter
              options
              {showAdditionalFilters ? " (reset filters and close)" : ""}
            </div>
          </div>
        </div>

        {showAdditionalFilters && (
          <>
            {isTimeAdmin && (
              <div className="row justify-content-center mt-4">
                <label className="col-sm-3 col-form-label"></label>
                <div className="col-sm-5">
                  <Checkbox
                    label="Include inactive users"
                    value={pageFilters.includeInactiveUsers}
                    onClickHandler={() =>
                      setPageFilters({
                        ...pageFilters,
                        includeInactiveUsers: !pageFilters.includeInactiveUsers,
                      })
                    }
                  />
                </div>
              </div>
            )}

            <div className="row mt-4 justify-content-center">
              <label className="col-sm-3 col-form-label">Task Type</label>
              <div className="col-sm-5">
                <Select
                  isMulti
                  placeholder="All"
                  options={MetaTimesInfo.taskTypes}
                  value={pageFilters.taskTypes}
                  onChange={(taskType) =>
                    setPageFilters({ ...pageFilters, taskTypes: taskType })
                  }
                  isDisabled={false}
                />
              </div>
            </div>

            <div className="row mt-3 justify-content-center">
              <label className="col-sm-3 col-form-label">
                Country
                <Tooltip
                  title={<b>Time for users that are located in this Country</b>}
                  placement="right"
                  TransitionComponent={Zoom}
                  arrow
                >
                  <span>
                    <BiInfoCircle className="disc-icon ms-1" />
                  </span>
                </Tooltip>
              </label>
              <div className="col-sm-5">
                <Select
                  isMulti
                  placeholder="All"
                  options={MetaTimesInfo.countries}
                  value={pageFilters.countries}
                  onChange={(country) =>
                    setPageFilters({ ...pageFilters, countries: country })
                  }
                  isDisabled={false}
                />
              </div>
            </div>

            <div className="row justify-content-center mt-4">
              <label className="col-sm-3 col-form-label">Hour Type</label>
              <div className="col-sm-5">
                <Checkbox
                  label="Billable hours"
                  value={pageFilters.hourType.billableHours}
                  onClickHandler={() =>
                    setPageFilters({
                      ...pageFilters,
                      hourType: {
                        billableHours: !pageFilters.hourType.billableHours,
                        nonBillableHours: pageFilters.hourType.nonBillableHours,
                        internalHours: pageFilters.hourType.internalHours,
                      },
                    })
                  }
                />
                <Checkbox
                  className="mt-2"
                  label="Non-Billable hours"
                  value={pageFilters.hourType.nonBillableHours}
                  onClickHandler={() =>
                    setPageFilters({
                      ...pageFilters,
                      hourType: {
                        billableHours: pageFilters.hourType.billableHours,
                        nonBillableHours:
                          !pageFilters.hourType.nonBillableHours,
                        internalHours: pageFilters.hourType.internalHours,
                      },
                    })
                  }
                />
                <Checkbox
                  className="mt-2"
                  label="Internal hours"
                  value={pageFilters.hourType.internalHours}
                  onClickHandler={() =>
                    setPageFilters({
                      ...pageFilters,
                      hourType: {
                        billableHours: pageFilters.hourType.billableHours,
                        nonBillableHours: pageFilters.hourType.nonBillableHours,
                        internalHours: !pageFilters.hourType.internalHours,
                      },
                    })
                  }
                />
              </div>
            </div>

            <div className="row mt-4 justify-content-center">
              <label className="col-sm-3 col-form-label">
                Non-Billable Reason
              </label>
              <div className="col-sm-5">
                <Select
                  isMulti
                  placeholder="All"
                  options={MetaTimesInfo.nonBillableReasons}
                  value={pageFilters.nonBillableReasons}
                  onChange={(nonBillableReason) =>
                    setPageFilters({
                      ...pageFilters,
                      nonBillableReasons: nonBillableReason,
                    })
                  }
                  isDisabled={false}
                />
              </div>
            </div>

            <div className="row justify-content-center mt-4">
              <label className="col-sm-3 col-form-label">Time Off</label>
              <div className="col-sm-5">
                <Checkbox
                  label="Paid time off"
                  value={pageFilters.timeOff.paidTimeOff}
                  onClickHandler={() =>
                    setPageFilters({
                      ...pageFilters,
                      timeOff: {
                        paidTimeOff: !pageFilters.timeOff.paidTimeOff,
                        unpaidTimeOff: pageFilters.timeOff.unpaidTimeOff,
                      },
                    })
                  }
                />
                <Checkbox
                  className="mt-2"
                  label="Unpaid time off"
                  value={pageFilters.timeOff.unpaidTimeOff}
                  onClickHandler={() =>
                    setPageFilters({
                      ...pageFilters,
                      timeOff: {
                        paidTimeOff: pageFilters.timeOff.paidTimeOff,
                        unpaidTimeOff: !pageFilters.timeOff.unpaidTimeOff,
                      },
                    })
                  }
                />
              </div>
            </div>

            <div className="row justify-content-center mt-4">
              <label className="col-sm-3 col-form-label">Approval Status</label>
              <div className="col-sm-5">
                <Checkbox
                  label="Pending hours"
                  value={pageFilters.approvalStatus.pendingHours}
                  onClickHandler={() =>
                    setPageFilters({
                      ...pageFilters,
                      approvalStatus: {
                        pendingHours: !pageFilters.approvalStatus.pendingHours,
                        approvedHours: pageFilters.approvalStatus.approvedHours,
                        lockedHours: pageFilters.approvalStatus.lockedHours,
                      },
                    })
                  }
                />
                <Checkbox
                  className="mt-2"
                  label="Approved hours"
                  value={pageFilters.approvalStatus.approvedHours}
                  onClickHandler={() =>
                    setPageFilters({
                      ...pageFilters,
                      approvalStatus: {
                        pendingHours: pageFilters.approvalStatus.pendingHours,
                        approvedHours:
                          !pageFilters.approvalStatus.approvedHours,
                        lockedHours: pageFilters.approvalStatus.lockedHours,
                      },
                    })
                  }
                />
                <Checkbox
                  className="mt-2"
                  label="Locked hours"
                  value={pageFilters.approvalStatus.lockedHours}
                  onClickHandler={() =>
                    setPageFilters({
                      ...pageFilters,
                      approvalStatus: {
                        pendingHours: pageFilters.approvalStatus.pendingHours,
                        approvedHours: pageFilters.approvalStatus.approvedHours,
                        lockedHours: !pageFilters.approvalStatus.lockedHours,
                      },
                    })
                  }
                />
              </div>
            </div>

            <div className="row justify-content-center mt-4">
              <label className="col-sm-3 col-form-label">
                Optional Summaries
              </label>
              <div className="col-sm-5">
                <Checkbox
                  label="Summary by User"
                  value={false}
                  onClickHandler={() => console.log("change checkbox")}
                />
                <Checkbox
                  className="mt-2"
                  label="Summary by Task"
                  value={false}
                  onClickHandler={() => console.log("change checkbox")}
                />
                <Checkbox
                  className="mt-2"
                  label="Summary by Task Type"
                  value={false}
                  onClickHandler={() => console.log("change checkbox")}
                />
                <Checkbox
                  className="mt-2"
                  label="Hide individual entries"
                  value={false}
                  onClickHandler={() => console.log("change checkbox")}
                  isDisabled={true}
                />
              </div>
            </div>
          </>
        )}

        <hr className="separator" />
        <div className="mt-4 footer-filter">
          <div
            className="link-text"
            onClick={() => {
              resetFiltersPage();
            }}
          >
            Reset Filters
          </div>
          <button className="btn btn-primary" onClick={() => runQuery()}>
            Run Query
          </button>
        </div>
      </form>
      <Footer />
    </div>
  );
};
