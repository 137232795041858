import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Tab } from "../components/Tab";
import "../../styles/main.scss";
import { Calendar } from "../../SharedModule/components/Calendar";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  billableItemType,
  selectAdvancedFilters,
  selectFilters,
  selectFiltersLabels,
} from "../redux/reducers/filters.reducer";
import {
  selectAllDetailsLoaded,
  selectAreBillableHours,
  selectCountAndAmount,
  selectedBillableHoursIds,
} from "../redux/reducers/billableHours.reducer";
import { Tabs } from "../components/Tabs";
import {
  selectAreMilestones,
  selectAreRecurring,
  selectAreResources,
  selectCountsAndAmounts,
  selectSelectedBillableItems,
} from "../redux/reducers/billableItems.reducer";
import { setFilters } from "../redux/reducers/invoices.reducer";
import { BillingService } from "../services/billing";
import { Spinner } from "../../SharedModule/components/Spinner";
import { ModalPortal } from "../../SharedModule/components/ModalPortal";
import { ModalLayout } from "../../SharedModule/components/ModalLayout";
import { BillableItemsSummary } from "../components/BillableItemsResultsPage/BillableItemsSummary";
import { BillableItemsTotals } from "../components/BillableItemsResultsPage/BillableItemsTotals";
import { TabLabelContent } from "../components/TabLabelContent";
import { useBillableItemsTabs } from "../hooks/useBillableItemsTabs";
import {
  LAST_MONTHS_END,
  ROUTE_BILLABLE_ITEMS_RESULTS,
  ROUTE_INVOICES_LIST,
  ROUTE_PROFFESSIONAL_SERVICES_QUERY,
} from "../../SharedModule/utils/constants";
import { DateTime } from "luxon";
import { ExternalService } from "../services/external";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import image from "./../../styles/legacy/404.png";
import { NoContent } from "../components/NoContent";
import Footer from "../../SharedModule/components/Footer";
import { addToast } from "../../SharedModule/redux/reducers/ui.reducer";

export const BillableItemsResults = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { trackPageView } = useMatomo();

  // Internal state
  const [reminders, setReminders] = useState(true);
  const [generatingInvoices, setGeneratingInvoices] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState<string>(
    LAST_MONTHS_END.toISO()
  );

  // Selectors
  const filters = useSelector(selectFilters, shallowEqual);
  const advancedFilters = useSelector(selectAdvancedFilters, shallowEqual);
  const filtersLabels = useSelector(selectFiltersLabels);
  const billableHoursIds = useSelector(selectedBillableHoursIds, shallowEqual);
  const allDetailsLoaded = useSelector(selectAllDetailsLoaded, shallowEqual);
  const { recurringIds, resourcesIds, milestonesIds } = useSelector(
    selectSelectedBillableItems,
    shallowEqual
  );
  const areMilestones = useSelector(selectAreMilestones);
  const areResources = useSelector(selectAreResources);
  const areRecurring = useSelector(selectAreRecurring);
  const areBillableHours = useSelector(selectAreBillableHours);

  useEffect(() => {
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });
  }, []);

  useEffect(() => {
    (location.state as any)?.from === ROUTE_PROFFESSIONAL_SERVICES_QUERY
      ? setInvoiceDate(filters.dateTo)
      : setInvoiceDate(filters.postingDate);
  }, [filters, location.state, location.pathname]);

  // Get counts / amounts from store
  const billableHoursCountAndAmount = useSelector(selectCountAndAmount);

  const {
    counts: countsFromSelector,
    deselected: deselectedFromSelector,
    amounts: amountsFromSelector,
  } = useSelector(selectCountsAndAmounts);

  // Prepare counts / amounts for component
  const counts = {
    ...countsFromSelector,
    billableHours: billableHoursCountAndAmount.totalCount,
  };
  const deselected = {
    ...deselectedFromSelector,
    billableHours:
      billableHoursCountAndAmount.totalCount -
      billableHoursCountAndAmount.count,
  };
  const amounts = {
    ...amountsFromSelector,
    billableHours: billableHoursCountAndAmount.amount,
  };

  const grandTotal =
    amounts.billableHours +
    amounts.resources +
    amounts.milestones +
    amounts.recurring;

  const getTotalCount = (name: keyof typeof counts) => {
    return counts[name];
  };

  const getSelectedCount = (name: keyof typeof counts) => {
    return counts[name] - deselected[name];
  };

  // Modal config
  const modalRef = useRef();
  const showModal = () => {
    (modalRef.current as any).show(true);
  };
  const closeModal = () => {
    (modalRef.current as any).show(false);
  };

  // Important tabs setup on the hook
  const { proServicesParams, recurringParams, tabs, loadFunction, loading } =
    useBillableItemsTabs(filters, advancedFilters);

  const activeTabs: billableItemType[] = useMemo(
    () => [...filters.selectedCategories].sort(),
    [filters.selectedCategories]
  );

  const isRecurring = activeTabs.find(
    (tab) => tab === billableItemType.recurring
  );

  const checkIsLoading = () => {
    let checker: boolean;
    if (
      activeTabs.includes(0) &&
      activeTabs.includes(1) &&
      activeTabs.includes(2)
    ) {
      checker =
        loading.billableHours || loading.resources || loading.milestones;
      return checker;
    }
    if (activeTabs.includes(0) && activeTabs.includes(1)) {
      checker = loading.billableHours || loading.resources;
      return checker;
    }
    if (activeTabs.includes(0) && activeTabs.includes(2)) {
      checker = loading.billableHours || loading.milestones;
      return checker;
    }
    if (activeTabs.includes(1) && activeTabs.includes(2)) {
      checker = loading.resources || loading.milestones;
      return checker;
    }
    switch (activeTabs[0]) {
      case 0:
        return (checker = loading.billableHours
          ? loading.billableHours
          : false);
      case 1:
        return (checker = loading.resources);
      case 2:
        return (checker = loading.milestones);
    }
  };

  const loadData = () => {
    activeTabs.forEach((tab) => {
      const params = isRecurring ? recurringParams : proServicesParams;
      loadFunction(
        tabs[tab].name,
        tabs[tab].service,
        params,
        tabs[tab].callback
      );
    });
  };

  useEffect(() => {
    loadData();
    return () => {
      // clear details queue on component unmount
      ExternalService.clearQueue();
    };
  }, []);

  const notAllItemsLoaded = activeTabs
    .map((tab) => {
      return tabs[tab].name === "billableHours"
        ? allDetailsLoaded
        : !loading[tabs[tab].name];
    })
    .some((item) => item === false);

  // ASUITE11-2170: Generate invoices button enabled when there are resources with price zero
  const setPSButtonDisabled = () => {
    if (grandTotal === 0) {
      return getSelectedCount("resources") <= 0;
    }
    return false;
  };

  const isPSDisabled = notAllItemsLoaded || setPSButtonDisabled();

  const isRecurringDisabled =
    notAllItemsLoaded || counts.recurring - deselected.recurring === 0;

  // Generate invoices method
  const generateInvoices = async() => {
    closeModal();
    setGeneratingInvoices(true);

    // generate invoices should return generated invoices
    const nowISO = DateTime.now().toUTC().toISO();
    const invoiceDateISOString = invoiceDate;
    const nowTimeString = nowISO.split("T")[1];
    const invoiceDateString = invoiceDateISOString.split("T")[0];

    const invoiceString = `${invoiceDateString}T${nowTimeString}`;

    dispatch(
      setFilters({
        dateFrom: invoiceString,
        dateTo: invoiceString,
        invoiceOwners: null,
        accountIds: null,
        departmentIds: null,
        statusIds: null,
        invoiceNumbers: null,
        internalNumbers: null,
        idsInputValue: null,
      })
    );
    await BillingService.generateInvoices({
      invoiceDate: invoiceString,
      sendReminderToOwners: reminders,
      totalToBill: grandTotal,
      billableHourIds: billableHoursIds,
      resourceIds: resourcesIds,
      milestoneIds: milestonesIds,
      recurringIds: recurringIds,
    })
      .then(() => {
        navigate(ROUTE_INVOICES_LIST, {
          state: { from: ROUTE_BILLABLE_ITEMS_RESULTS },
        });
      })
      .catch((error) => {
        dispatch(addToast({ mode: "error", message: error.data[1] ?? error.data[0] }));
        setGeneratingInvoices(false);
        return error.response || error.message;
      });
  };

  const getEditQueryLink = () => {
    return (
      (isRecurring ? "/billing/recurring" : "/billing/professional-services") +
      "#keep"
    );
  };

  return (
    <>
      <ModalPortal ref={modalRef}>
        <ModalLayout
          title="Generate Invoices?"
          text="Are you sure you want to generate invoices from the selected items?"
          btnLabel="Yes, generate invoices"
          btnAction={generateInvoices}
          btnCancel={closeModal}
        />
      </ModalPortal>

      <div className="content">
        <BillableItemsSummary
          isLoading={isRecurring ? loading.recurring : checkIsLoading()}
          isRecurring={isRecurring}
          filtersLabels={filtersLabels}
          editQueryLink={getEditQueryLink()}
        />

        {!notAllItemsLoaded &&
        (!isRecurring
          ? areMilestones && areResources && areBillableHours
          : areRecurring) ? (
          <div className="container-fluid border-top">
            <NoContent
              title="No Results Found"
              text="Try adjusting your query to find what you’re looking for."
              action={() => navigate(getEditQueryLink())}
              image={image}
              textButton="Edit Query"
              style={{ margin: "3% auto" }}
            />
          </div>
        ) : (
          <>
            <div className="container-fluid bg-light border-top">
              <BillableItemsTotals
                isLoading={isRecurring ? loading.recurring : checkIsLoading()}
                tabs={tabs}
                activeTabs={activeTabs}
                amounts={amounts}
                counts={counts}
                deselected={deselected}
                grandTotal={grandTotal}
              />

              <div className="d-flex justify-content-between">
                <div className="mt-4 d-flex">
                  <label className="form-label d-inline-block me-2 mt-1">
                    Invoice Date
                  </label>
                  <Calendar
                    date={invoiceDate}
                    onChange={(date: string) => {
                      setInvoiceDate(date);
                    }}
                    className="d-inline-block"
                  />

                  <div className="form-check ms-4 mt-1">
                    <input
                      className="form-check-input"
                      id="hours"
                      type="checkbox"
                      checked={reminders}
                      onChange={() => setReminders((state) => !state)}
                    />
                    <label className="form-check-label" htmlFor="hours">
                      Send invoice reminders to Owners
                    </label>
                  </div>
                </div>

                <div className="mt-3 pb-4 d-flex">
                  <button
                    className={
                      "btn btn-primary me-2" +
                      ((isRecurring ? isRecurringDisabled : isPSDisabled)
                        ? " disabled"
                        : "")
                    }
                    onClick={showModal}
                  >
                    Generate Invoices
                  </button>
                  {generatingInvoices && <Spinner />}
                </div>
              </div>
            </div>

            <Tabs>
              {activeTabs.map((el: billableItemType) => {
                const Component = tabs[el].component;
                return (
                  <Tab
                    key={el}
                    label={
                      <TabLabelContent
                        title={tabs[el].title}
                        totalCount={getTotalCount(tabs[el].name)}
                        selectedCount={getSelectedCount(tabs[el].name)}
                      />
                    }
                  >
                    <Component loading={loading[tabs[el].name] as boolean} />
                  </Tab>
                );
              })}
            </Tabs>
          </>
        )}
        <Footer />
      </div>
    </>
  );
};
