import DynamicBiIcon from "./DynamicBiIcon";
import { CheckSkeleton } from "../utils/checkSkeleton";
import { useLocation, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  actualUIState,
  setActiveTab,
} from "../../ProductsModule/redux/reducers/ui.reducer";
import { AccessControl } from "./AccessControl";
import { LeftNavBarLink } from "../../TimesModule/types";

export const LeftNavBarSection = ({
  mainTitle,
  biIconName,
  loadingStatus,
  listOfLinks,
  role,
}: any) => {
  let { productId }: any = useParams();

  const url = process.env.REACT_APP_ASUITE_BASE_URI;

  const location: any = useLocation();

  const { activeTab } = useSelector(actualUIState);
  const dispatch = useDispatch();

  if (!activeTab) {
    dispatch(
      setActiveTab(
        "/" +
          location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
      )
    );
  }

  const leftNavBarSectionBody = () => {
    return (
      <>
        <li className="link-title" key={biIconName}>
          <CheckSkeleton
            isLoading={loadingStatus}
            style={{ width: "25px", marginRight: "10px", height: "20px" }}
          >
            <DynamicBiIcon icon={biIconName} className="link-title-icon my-2" />
          </CheckSkeleton>
          <CheckSkeleton
            isLoading={loadingStatus}
            style={{ width: "80px", height: "20px" }}
          >
            <span className="link-title-text my-2">{mainTitle}</span>
          </CheckSkeleton>
        </li>
        {listOfLinks.map((link: LeftNavBarLink) => (
          <>
            {link.role && (
              <AccessControl allowedRoles={link.role}>
                <CheckSkeleton
                  isLoading={loadingStatus}
                  key={link.text}
                  style={{
                    width: "175px",
                    margin: "5px 10px 5px 26px",
                    height: "25px",
                  }}
                >
                  <li
                    key={link.text}
                    className={`hoverable ${
                      activeTab === link.route ? "active" : ""
                    }`}
                    onClick={() => dispatch(setActiveTab(link.route))}
                  >
                    {!link.asuiteLink && (
                      <Link
                        to={link.route.replace(":productId", productId)}
                        className={`${
                          link.text.length > 21 ? "multiline" : ""
                        }`}
                      >
                        <span>{link.text}</span>
                      </Link>
                    )}
                    {link.asuiteLink && (
                      <a
                        href={`${url}${link.route}`}
                        target="_blank"
                        rel="noreferrer"
                        className={`text-decoration-none ${
                          link.text.length > 17 ? "multiline" : ""
                        }`}
                      >
                        <span>{link.text}</span>
                      </a>
                    )}
                  </li>
                </CheckSkeleton>
              </AccessControl>
            )}
            {!link.role && (
              <CheckSkeleton
                isLoading={loadingStatus}
                key={link.text}
                style={{
                  width: "175px",
                  margin: "5px 10px 5px 26px",
                  height: "25px",
                }}
              >
                <li
                  key={link.text}
                  className={`hoverable ${
                    activeTab === link.route ? "active" : ""
                  }`}
                  onClick={() => dispatch(setActiveTab(link.route))}
                >
                  {!link.asuiteLink && (
                    <Link
                      to={link.route.replace(":productId", productId)}
                      className={`${link.text.length > 21 ? "multiline" : ""}`}
                    >
                      <span>{link.text}</span>
                    </Link>
                  )}
                  {link.asuiteLink && (
                    <a
                      href={`${url}${link.route}`}
                      target="_blank"
                      rel="noreferrer"
                      className={`text-decoration-none ${
                        link.text.length > 17 ? "multiline" : ""
                      }`}
                    >
                      <span>{link.text}</span>
                    </a>
                  )}
                </li>
              </CheckSkeleton>
            )}
          </>
        ))}
      </>
    );
  };

  return (
    <>
      {role && (
        <AccessControl allowedRoles={role}>
          {leftNavBarSectionBody()}
        </AccessControl>
      )}
      {!role && leftNavBarSectionBody()}
    </>
  );
};
