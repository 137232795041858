import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "@reduxjs/toolkit";

// Reducers from each module
// Shared module
import auth from "./../redux/reducers/auth.reducer";
import ui from "./../redux/reducers/ui.reducer";
// Billing Module
import filters from "./../../BillingModule/redux/reducers/filters.reducer";
import meta from "./../../BillingModule/redux/reducers/meta.reducer";
import billableHours from "./../../BillingModule/redux/reducers/billableHours.reducer";
import billableItems from "./../../BillingModule/redux/reducers/billableItems.reducer";
import invoices from "./../../BillingModule/redux/reducers/invoices.reducer";
// Products Module
import uiProducts from "./../../ProductsModule/redux/reducers/ui.reducer";
import spotlightReportProducts from "./../../ProductsModule/redux/reducers/spotlightReport.reducer";
import metaProducts from "../../ProductsModule/redux/reducers/meta.reducer";
import products from "../../ProductsModule/redux/reducers/products.reducer";
import spotlight from "../../ProductsModule/redux/reducers/spotlight.reducer";
import keyContacts from "../../ProductsModule/redux/reducers/keyContact.reducer";
import successCriteria from "../../ProductsModule/redux/reducers/successCriteria.reducer";
// Times Module
import metaTimes from "../../TimesModule/redux/reducers/meta.reducer";
import times from "../../TimesModule/redux/reducers/times.reducer";
import timesQuery from "../../TimesModule/redux/reducers/timesQuery.reducer";
import queryResults from "../../TimesModule/redux/reducers/queryResult.reducer";

// Avoid persistence when there's a new version.
const APP_VERSION = process.env.REACT_APP_CURRENT_GIT_SHA;
const newVersion = localStorage.APP_VERSION !== APP_VERSION;

if (newVersion) {
  window.location.reload();
}

const persistConfigFilters = {
  key: "filters",
  storage,
};

const persistedFilters = persistReducer(persistConfigFilters, filters);

const persistConfigBillableHours = {
  key: "billableHours",
  storage,
};

const persistedBillableHours = persistReducer(
  persistConfigBillableHours,
  billableHours
);

export default combineReducers({
  //shared
  auth: auth,
  ui: ui,
  //billing
  meta: meta,
  filters: persistedFilters,
  billableHours: persistedBillableHours,
  billableItems: billableItems,
  invoices: invoices,
  //products
  uiProducts: uiProducts,
  spotlightReportProducts: spotlightReportProducts,
  metaProducts: metaProducts,
  products: products,
  spotlight: spotlight,
  keyContacts: keyContacts,
  successCriteria: successCriteria,
  // times
  metaTimes: metaTimes,
  times: times,
  timesQuery: timesQuery,
  queryResults: queryResults,
});
