import { useEffect, useState } from "react";
import { FaAsterisk } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { Select } from "../../SharedModule/components/Select";
import { VscQuestion } from "react-icons/vsc";
import { useSpotlights } from "../hooks/useSpotlights";
import { SpotlightService } from "../services/spotlight";
import { useDispatch } from "react-redux";
import { addToast } from "../../SharedModule/redux/reducers/ui.reducer";
import { useParams } from "react-router-dom";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import { setIsLoadingData } from "../redux/reducers/spotlight.reducer";
import eventHanlder from "../../SharedModule/utils/eventHanlder";

export const ModalPublish = ({
  title,
  btnLabel,
  btnCancelLabel,
  btnCancel,
  lastSpotlight,
  owner,
}: any) => {
  let { productId } = useParams();

  const { spotlightStatuses } = useSpotlights(Number(productId));

  // new react-quilljs properties
  const placeholder =
    "If there are no updates, please give a small explanation as to what happened this week.";
  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: [
        ["bold", "italic", "underline", "strike"],
        [{ align: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["clean"],
      ],
    },
    placeholder,
    clipboard: {
      matchVisual: false,
    },
  });

  const dispatch = useDispatch();

  let spotlightStatusesOptions = spotlightStatuses.map((elem: any) => {
    return { value: elem.id.toString(), label: elem.name };
  });

  const [isPublishDisabled, setIsPublishDisabled] = useState(true);

  useEffect(() => {
    // heres is where we set default value data
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(state.comments);
    }
  }, [quill]);

  // set modal with values of last spotlight
  const [state, setState] = useState({
    status: lastSpotlight
      ? { value: lastSpotlight.status.id, label: lastSpotlight.status.name }
      : spotlightStatusesOptions[0],
    comments: lastSpotlight ? lastSpotlight.comment : "",
  });

  const handleChange = (value: any) => {
    setState({ ...state, comments: value });
  };

  const handleSubmit = async () => {
    let result = await SpotlightService.addSpotlight(
      owner,
      Number(productId),
      Number(state.status.value),
      state.comments
    );
    if (result === "Spotlight added successfully.") {
      dispatch(addToast({ mode: "success", message: result }));
      dispatch(setIsLoadingData(true));
    } else {
      dispatch(addToast({ mode: "error", message: "Something went wrong" }));
    }
    eventHanlder.dispatch("toast action", { isClosed: false });
    btnCancel(true);
  };

  const [maxChars, setMaxChars] = useState(
    10000 - state.comments.replace(/<(.|\n)*?>/g, "").length
  );

  const checkCharacterCount = (event: any) => {
    handleChange(quill.root.innerHTML);
    const unprivilegedEditor = quill.root.innerHTML;
    let totalChars = unprivilegedEditor.replace(/<(.|\n)*?>/g, "").length;
    if (totalChars > 0) {
      quillRef.current.__quill.root.dataset.placeholder = "";
    } else {
      quillRef.current.__quill.root.dataset.placeholder = placeholder;
    }
    setMaxChars(10000 - totalChars);
    if (totalChars === 0 || totalChars > 10000) {
      setIsPublishDisabled(true);
    } else {
      setIsPublishDisabled(false);
    }
    if (unprivilegedEditor.length > 10000 && event.key !== "Backspace") {
      setIsPublishDisabled(true);
      event.preventDefault();
    }
  };

  return (
    <div className="block" style={{ width: "100%" }}>
      <div className="block-header">
        <h6 style={{ fontWeight: "bold" }}>{title}</h6>
        <div className="d-flex justify-content-between">
          <div
            style={{ width: "85%", fontSize: "11px" }}
            className="grayMedium mt-2"
          >
            Set the current status of your product, and then enter a comment
            about how things have been going the past week.
            <br />
            If there are no changes, enter a small note as to why.
          </div>
          <IoMdClose
            size={"20px"}
            className="pointer grayMedium"
            onClick={() => btnCancel(false)}
            style={{ marginTop: "-25px" }}
          />
        </div>
      </div>
      <div className="block-body">
        <div className="d-flex mt-2 flex-column mb-2">
          <label className="form-label gray-label mb-1">
            STATUS{" "}
            <span className="required">
              <FaAsterisk size={"6px"} />
            </span>
          </label>
          <div className="position-relative d-flex">
            <div style={{ width: "40%" }}>
              <Select
                placeholder="Select Name"
                className="mb-2"
                options={spotlightStatusesOptions}
                isLoading={!spotlightStatusesOptions}
                value={{
                  value: state.status.value,
                  label: state.status.label,
                }}
                onChange={(name: any) => {
                  setState({ ...state, status: name });
                }}
                isDisabled={false}
              />
            </div>
            <a
              className="ms-3 mt-1 gray-label"
              target="_blank"
              rel="noreferrer"
              href="https://itxnet.sharepoint.com/sites/Delivery/SitePages/Spotlight-Reporting.aspx"
            >
              <VscQuestion size={"25px"} />
            </a>
          </div>
        </div>
        <div className="d-flex mt-2 flex-column mb-2">
          <label className="form-label gray-label mb-1">
            COMMENTS{" "}
            <span className="required">
              <FaAsterisk size={"6px"} />
            </span>
          </label>
          <div
            ref={quillRef}
            className="ql-enabled"
            onKeyUp={checkCharacterCount}
          />
          <div className="gray-label mt-2 align-self-end">
            {`Characters left: ${maxChars}`}
          </div>
        </div>
      </div>
      <div className="block-footer">
        <div className="d-flex mt-2">
          <button
            className="btn button-secondary w-100"
            onClick={() => btnCancel(false)}
          >
            {btnCancelLabel}
          </button>
          <button
            className="btn btn-primary w-100 ms-2"
            disabled={isPublishDisabled}
            onClick={() => handleSubmit()}
          >
            {btnLabel}
          </button>
        </div>
      </div>
    </div>
  );
};
