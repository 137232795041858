import { AccountInfo } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { login, setUserRoles } from "../redux/reducers/auth.reducer";
import { SecurityService } from "../../SharedModule/services/security";
import { Spinner } from "./Spinner";
import { TimeoutLogic } from "./../components/TimeoutLogic";
import { ForbiddenPage } from "../pages/ForbiddenPage";
import eventHanlder from "../utils/eventHanlder";

export const Auth = ({ children }: any) => {
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  const [status, setStatus] = useState({ loaded: false, auth: false });

  const [isLogoutLoading, setIsLogoutLoading] = useState(false);

  const getRoles = useCallback(async () => {
    const userRoles = await SecurityService.getUserRole();
    Array.isArray(userRoles) && dispatch(setUserRoles(userRoles));
    setStatus({
      loaded: true,
      auth: true,
      // ASUITE11-2099: this logic was old, when billing was the only module. Now products allows users without roles to enter to The Hub
      // auth: Array.isArray(userRoles) && userRoles.length !== 0,
    });
  }, [dispatch]);

  const loginAccess = () => {
    instance
      .acquireTokenSilent({
        scopes: ["api://894e8b20-1b64-479c-b4a6-6e4fd7e1757f/Billing.Access"],
        account: accounts[0] as AccountInfo,
      })
      .then((response) => {
        dispatch(
          login({
            accessToken: response.accessToken,
            activeUserId: accounts[0].localAccountId,
            activeUserName: accounts[0].name, //(accounts[0] as any).name as string,
            activeUserEmail: accounts[0].username,
          })
        );
        getRoles();
      });
  };

  useEffect(() => {
    eventHanlder.on("logout", () => {
      setIsLogoutLoading(true);
    });
    eventHanlder.on("login", () => {
      setIsLogoutLoading(false);
    });
    eventHanlder.on("authLogin", () => {
      loginAccess();
    });

    loginAccess();

    return () => {
      eventHanlder.remove("logout", null);
      eventHanlder.remove("login", null);
      eventHanlder.remove("authLogin", null);
    };
  }, [dispatch, accounts, instance, getRoles]);

  return (
    <>
      {status.auth && <TimeoutLogic />}
      {status.loaded && !isLogoutLoading ? (
        status.auth ? (
          children
        ) : (
          <ForbiddenPage />
        )
      ) : (
        <div className="position-absolute vh-100 vw-100 top-0 start-0">
          <Spinner
            style={{
              left: "50%",
              top: "50%",
              position: "absolute",
              transform: "translate(-15px, -15px)",
            }}
          />
        </div>
      )}
    </>
  );
};
