import { createSlice } from "@reduxjs/toolkit";
import { ProjectsWorkOrders } from "../../../types";
import { EntryListView, TimeEntryForm } from "../../types";
import {
  getEmptyTimeEntry,
  getProjectWorOrder,
  getValueFromSelect,
} from "../../utils/timesUtils";
import { newUuid } from "../../../SharedModule/utils/uuid";
import { isSameDay } from "../../../SharedModule/utils/dateUtils";

type TimesState = {
  projectList: ProjectsWorkOrders[];
  isAdmin: boolean;
  isListView: boolean;
  date: Date;
  user: string;
  timeEntriesList: EntryListView[];
  timeEntriesSheetView: any[];
  summary: {
    total: string;
    billable: string;
    nonBillable: string;
    internal: string;
    timeOff: string;
  };
  entryList: TimeEntryForm[];
  entryListInvalid: TimeEntryForm[];
  isLoading: boolean;
  isSubmitted: boolean;
  holidays: string[];
};

const initialState: TimesState = {
  projectList: [],
  isAdmin: false,
  isListView: true,
  date: new Date(),
  user: "",
  timeEntriesList: [],
  timeEntriesSheetView: [],
  summary: {
    total: "—",
    billable: "—",
    nonBillable: "—",
    internal: "—",
    timeOff: "—",
  },
  entryList: [
    getEmptyTimeEntry(),
    getEmptyTimeEntry(),
    getEmptyTimeEntry(),
    getEmptyTimeEntry(),
    getEmptyTimeEntry(),
  ],
  entryListInvalid: [],
  isLoading: true,
  isSubmitted: false,
  holidays: [],
};

export const timesSlice = createSlice({
  name: "times",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSubmitted: (state, action) => {
      state.isSubmitted = action.payload;
    },
    loadTimesInfo: (state, action) => {
      state.projectList = action.payload.projectList;
      // if has content in same day or has invalid entries, clean entry list
      state.timeEntriesList = action.payload.timeEntriesInfo.data.listView;
      let hasPageContent: boolean =
        state.timeEntriesList.length > 0 &&
        state.timeEntriesList.some((entry) =>
          isSameDay(entry.entryDate, action.payload.pageDate)
        );
      // if page has content, do not show time entries
      if (hasPageContent) {
        state.entryList = [];
      }

      if (state.entryListInvalid.length > 0) {
        // add invalid entries and then clen array
        state.entryList = state.entryListInvalid;
        state.entryListInvalid = [];
        state.isSubmitted = true;
      }
      // complete to 5 entry list
      if (!hasPageContent) {
        for (let total = state.entryList.length; total < 5; total++) {
          state.entryList.push(getEmptyTimeEntry());
        }
      }

      state.timeEntriesSheetView =
        action.payload.timeEntriesInfo.data.sheetView;

      // add this logic, when opens modal to add/edit entry in sheet view get corrects date
      if (state.timeEntriesSheetView && state.timeEntriesSheetView.length > 0) {
        for (var key in state.timeEntriesSheetView[0].dailyDetail) {
          if (state.timeEntriesSheetView[0].dailyDetail[key].length > 0) {
            state.timeEntriesSheetView[0].dailyDetail[key].forEach(
              (element) => {
                element.timeEntries.forEach((timeEntry) => {
                  timeEntry.entryDate =
                    timeEntry.entryDate.split("T")[0] + "T03:00:00Z";
                });
              }
            );
          }
        }
      }

      state.summary = action.payload.timeEntriesInfo.data.summary;
      state.holidays = action.payload.timeEntriesInfo.data.user.holidays;
      state.isLoading = false;
    },
    changeListView: (state, action) => {
      state.isListView = action.payload;
    },
    updateEntry: (state, action) => {
      let foundIndex = state.entryList.findIndex(
        (x) => x.entryId === action.payload.entryState.entryId
      );
      state.entryList[foundIndex] = action.payload.entryState;
    },
    addInvalidEntriesFromEndpoint: (state, action) => {
      let aux: TimeEntryForm;

      action.payload.list.forEach((element) => {
        aux = {
          entryId: newUuid(),
          entryCanEdit: true,
          entryCanDelete: true,
          entryDate: element.timeEntry.entryDate,
          entryDateValid: true,
          entryProjectWorkOrder: getProjectWorOrder(
            state.projectList,
            element.timeEntry.entityId
          ),
          entryProjectWorkOrderValid: true,
          entryHours: element.timeEntry.hours,
          entryHoursValid: true,
          entryTaskType: getValueFromSelect(
            action.payload.meta.taskTypes,
            element.timeEntry.taskTypeId
          ),
          entryTaskTypeValid: true,
          entryDescription: element.timeEntry.description,
          entryDescriptionValid: true,
          entryIsBillable: element.timeEntry.timeEntryTypeId === 1,
          entryNonBillableReason:
            element.timeEntry.timeEntryTypeId === 1
              ? null
              : getValueFromSelect(
                  action.payload.meta.nonBillableReasons,
                  element.timeEntry.nonBillableReasonId
                ),
          entryNonBillableReasonValid: true,
          isEntryValid: false,
          isEntryTouched: false,
        };
        state.entryListInvalid.push(aux);
      });
    },
    addEntry: (state) => {
      state.entryList.unshift(getEmptyTimeEntry());
    },
    addInvalidEntry: (state, action) => {
      state.entryListInvalid.unshift(action.payload.entry);
    },
    removeValidEntry: (state, action) => {
      state.entryList = state.entryList.filter(
        (entry) => entry.entryId !== action.payload.entry.entryId
      );
    },
    resetEntries: (state, action) => {
      if (action.payload) {
        state.entryList = [];
      } else {
        state.entryList = [
          getEmptyTimeEntry(),
          getEmptyTimeEntry(),
          getEmptyTimeEntry(),
          getEmptyTimeEntry(),
          getEmptyTimeEntry(),
        ];
      }
    },
    resetInvalidEntries: (state) => {
      state.entryListInvalid = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setSubmitted,
  loadTimesInfo,
  changeListView,
  updateEntry,
  addInvalidEntriesFromEndpoint,
  addEntry,
  addInvalidEntry,
  removeValidEntry,
  resetEntries,
  resetInvalidEntries,
} = timesSlice.actions;

// Selectors
export const IsLoading = (state: any) => {
  return state.times.isLoading;
};

export const IsSubmitted = (state: any) => {
  return state.times.isSubmitted;
};

export const IsListView = (state: any) => {
  return state.times.isListView;
};

export const ProjectList = (state: any) => {
  return state.times.projectList;
};

export const Summary = (state: any) => {
  return state.times.summary;
};

export const TimeEntriesList = (state: any) => {
  return state.times.timeEntriesList;
};

export const TimeEntriesSheetView = (state: any) => {
  return state.times.timeEntriesSheetView;
};

export const Holidays = (state: any) => {
  return state.times.holidays;
};

export const EntryList = (state: any) => {
  return state.times.entryList;
};

export default timesSlice.reducer;
