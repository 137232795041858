import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TimesheetsService } from "../services/timesheets";
import {
  IsLoading,
  QueryResultList,
  Summary,
  loadQueryResultsInfo,
  setLoading,
} from "../redux/reducers/queryResult.reducer";
import { preparedFilters } from "../utils/timesUtils";
import { Holidays, ProjectList } from "../redux/reducers/times.reducer";

export const useQueryResults = (filters: any) => {
  const dispatch = useDispatch();

  const projectList = useSelector(ProjectList);
  const holidays = useSelector(Holidays);

  const filtersForRequest = preparedFilters(filters);

  const summary = useSelector(Summary);
  const queryResultList = useSelector(QueryResultList);
  
  const isLoading = useSelector(IsLoading);

  const loadQueryResultsPageInfo = useCallback(async () => {
    dispatch(setLoading(true));
    const queryResults = await TimesheetsService.getQueryResults(
      filtersForRequest
    );
    dispatch(loadQueryResultsInfo(queryResults.data.data));
  }, [dispatch, filters]);

  useEffect(() => {
    loadQueryResultsPageInfo();
  }, [loadQueryResultsPageInfo]);

  return {
    isLoading,
    summary,
    queryResultList,
    projectList,
    holidays,
    loadQueryResultsPageInfo
  };
};
