import { Link } from "react-router-dom";
import { Select } from "../../../SharedModule/components/Select";
import { Badge } from "../Badge";
import {
  currencyFormat,
  dateFormat,
} from "../../../SharedModule/utils/formatters";
import { MoreActions } from "./MoreActions";
import {
  PENDING,
  ROLE_BILLING_MODULE_PERFORM_ACTION,
  ROLE_NOT_OWNERS,
  ROUTE_INVOICES_DETAIL,
} from "../../../SharedModule/utils/constants";
import { Invoice, SelectItem } from "../../../types";
import { ChangeOwnerPayload } from "../../hooks/useChangeOwner";
import {
  AccessControl,
  useIsAllowed,
} from "../../../SharedModule/components/AccessControl";
import { useDispatch, useSelector } from "react-redux";
import {
  changeOrder,
  selectOrderCriteria,
} from "./../../redux/reducers/invoices.reducer";
import { Spinner } from "../../../SharedModule/components/Spinner";

const RECURRING_BILLING_GROUP_ID = 3;

type InvoicesTableType = {
  invoices: Invoice[] | null;
  isLoading: boolean;
  allSelected: boolean;
  selectAll: Function;
  summary: {
    pendingTotal: number;
    pendingCount: number;
    invoicedTotal: number;
    invoicedCount: number;
    voidedTotal: number;
    voidedCount: number;
    total: number;
    count: number;
    resetCount: number;
  };
  getBillingGroupUrl: Function;
  toggleItemSelection: Function;
  approveInvoices: Function;
  resetInvoices: Function;
  voidInvoices: Function;
  sendReminders: Function;
  changeOwner: (invoice: ChangeOwnerPayload) => void;
  users: SelectItem[] | null;
};

export const InvoicesTable = ({
  invoices,
  isLoading,
  allSelected,
  selectAll,
  summary,
  getBillingGroupUrl,
  toggleItemSelection,
  approveInvoices,
  resetInvoices,
  voidInvoices,
  changeOwner,
  sendReminders,
  users,
}: InvoicesTableType) => {
  const { isAllowed } = useIsAllowed(ROLE_NOT_OWNERS);
  const dispatch = useDispatch();
  const sortBy = useSelector(selectOrderCriteria);

  return (
    <table className="table grey-table-header-background">
      {isLoading ? (
        <Spinner
          style={{
            marginLeft: "50%",
            marginTop: "5%",
          }}
        />
      ) : (
        <>
          <thead className="align-middle">
            <tr className="sticky sticky-header">
              <AccessControl allowedRoles={ROLE_NOT_OWNERS}>
                <th className="fw-normal text-center border-dark border-top py-0">
                  <div className="form-check d-inline-block mt-2 mb-0">
                    <input
                      className="form-check-input"
                      id="hours"
                      type="checkbox"
                      checked={allSelected}
                      onChange={() => selectAll()}
                    />
                  </div>
                </th>
              </AccessControl>
              <th
                className={`border-dark border-top fw-normal py-0 pointer ${
                  sortBy.orderBy === "id" ? "sorted-" + sortBy.criteria : ""
                }`}
                onClick={() => dispatch(changeOrder({ orderBy: "id" }))}
                style={{ width: "100px" }}
              >
                Invoice ID
              </th>
              <th
                className={`border-dark border-top fw-normal py-0 pointer ${
                  sortBy.orderBy === "date" ? "sorted-" + sortBy.criteria : ""
                }`}
                style={{ width: "115px" }}
                onClick={() =>
                  dispatch(changeOrder({ orderBy: "date", dataType: "date" }))
                }
              >
                Invoice Date
              </th>
              <th
                className={`border-dark border-top fw-normal py-0 pointer ${
                  sortBy.orderBy === "account.name"
                    ? "sorted-" + sortBy.criteria
                    : ""
                }`}
                onClick={() =>
                  dispatch(
                    changeOrder({ orderBy: "account.name", dataType: "string" })
                  )
                }
              >
                Client & Project/WO
              </th>
              <th
                className={`border-dark border-top fw-normal py-0 pointer ${
                  sortBy.orderBy === "owner.fullName"
                    ? "sorted-" + sortBy.criteria
                    : ""
                }`}
                onClick={() =>
                  dispatch(
                    changeOrder({
                      orderBy: "owner.fullName",
                      dataType: "string",
                    })
                  )
                }
              >
                Owner
              </th>
              <th
                className={`border-dark border-top fw-normal py-0 pointer ${
                  sortBy.orderBy === "amount" ? "sorted-" + sortBy.criteria : ""
                }`}
                onClick={() => dispatch(changeOrder({ orderBy: "amount" }))}
              >
                Amount
              </th>
              <th
                className={`border-dark border-top fw-normal py-0 pointer ${
                  sortBy.orderBy === "status.id"
                    ? "sorted-" + sortBy.criteria
                    : ""
                }`}
                onClick={() => dispatch(changeOrder({ orderBy: "status.id" }))}
              >
                Status
              </th>
              <th className="border-dark border-top fw-normal text-center py-0">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {invoices?.map((invoice: Invoice & { selected?: boolean }) => {
              return (
                <tr key={invoice.id}>
                  <AccessControl allowedRoles={ROLE_NOT_OWNERS}>
                    <td className="text-center pb-0">
                      <div className="form-check d-inline-block">
                        <input
                          className="form-check-input"
                          id="hours"
                          type="checkbox"
                          checked={invoice.selected || false}
                          onChange={() => toggleItemSelection(invoice.id)}
                          disabled={!invoice.isEditable}
                        />
                      </div>
                    </td>
                  </AccessControl>
                  <td>
                    <Link
                      className="link-text fw-500"
                      to={{
                        pathname: ROUTE_INVOICES_DETAIL.replace(
                          ":id",
                          invoice.id.toString()
                        ),
                      }}
                    >
                      {invoice.id}
                    </Link>
                    <div>
                      {invoice.quickBooksNumber && (
                        <div>({invoice.quickBooksNumber})</div>
                      )}
                    </div>
                  </td>
                  <td>{dateFormat(invoice.date)}</td>
                  <td>
                    <div className="fw-bold">{invoice.account.name}</div>
                    {invoice.billingGroup.type.id ===
                    RECURRING_BILLING_GROUP_ID ? (
                      <span>{`${invoice.billingGroup.type.name} #${invoice.billingGroup.externalId} - ${invoice.billingGroup.name}`}</span>
                    ) : (
                      <a
                        href={getBillingGroupUrl(invoice)}
                        target="_blank"
                        rel="noreferrer"
                        className="mt-1 link-text fw-500"
                      >
                        {`${invoice.billingGroup.type.name} #${invoice.billingGroup.externalId} - ${invoice.billingGroup.name}`}
                      </a>
                    )}
                  </td>
                  <td style={{ width: "170px" }}>
                    <AccessControl
                      allowedRoles={ROLE_BILLING_MODULE_PERFORM_ACTION}
                      fallback={
                        // Disabled select
                        <Select
                          options={users}
                          isDisabled={true}
                          value={
                            invoice.owner && {
                              value: invoice.owner.id,
                              label: invoice.owner.fullName,
                            }
                          }
                          isLoading={!users}
                          onChange={undefined}
                        />
                      }
                    >
                      <Select
                        placeholder="Select Owner"
                        options={users}
                        isDisabled={
                          invoice.status.id !== PENDING || !invoice.isEditable
                        }
                        value={
                          invoice.owner && {
                            value: invoice.owner.id,
                            label: invoice.owner.fullName,
                          }
                        }
                        isLoading={!users}
                        onChange={(owner: SelectItem) =>
                          changeOwner({
                            id: invoice.id,
                            owner: { id: owner.value, fullName: owner.label },
                          })
                        }
                      />
                    </AccessControl>
                  </td>
                  <td className="fw-bold text-end">
                    {currencyFormat(invoice.amount)}
                  </td>
                  <td>
                    <Badge status={invoice.status.name} isFullWidth={true} />
                  </td>
                  <td className="text-center">
                    <MoreActions
                      status={invoice.status}
                      invoiceId={invoice.id}
                      approveInvoices={() => approveInvoices(invoice.id)}
                      resetInvoices={() => resetInvoices(invoice.id)}
                      voidInvoices={() => voidInvoices(invoice.id)}
                      sendReminders={() => sendReminders(invoice.id)}
                      voidable={invoice.voidable}
                      editable={invoice.isEditable}
                    />
                  </td>
                </tr>
              );
            })}
            <tr>
              <td className="border-0" colSpan={isAllowed ? 4 : 3}></td>
              <td className="border-0 text-end h6 fw-bold pt-3">Total</td>
              <td className="border-0 text-end h6 fw-bold pt-3">
                {invoices && currencyFormat(summary.total)}
              </td>
              <td className="border-0" colSpan={2}></td>
            </tr>
          </tbody>
        </>
      )}
    </table>
  );
};
