import { useState } from "react";
import { Select } from "../../SharedModule/components/Select";
import { empty } from "../utils/timesUtils";

export const ModalActionNonBillable = ({
  btnAction,
  btnCancel,
  nonBillableReasons,
}: any) => {
  const [nonBillableReason, setNonBillableReason] = useState(empty);

  return (
    <>
      <h4 className="text-capitalize">Make Non-billable</h4>
      <label className="color-neutrals-800">
        Select the non-billable reason for this time
      </label>
      <div className="d-flex my-4 flex-column">
        <label className="mb-1">Non-billable Reason</label>
        <div className="mb-2">
          <Select
            placeholder="All"
            options={nonBillableReasons}
            value={nonBillableReason}
            onChange={(reason) => setNonBillableReason(reason)}
            isDisabled={false}
          />
        </div>
      </div>
      <div className="d-flex mt-2">
        <button className="btn button-secondary w-100" onClick={btnCancel}>
          Cancel
        </button>
        <button className="btn btn-primary w-100 ms-2" onClick={() => btnAction(2, nonBillableReason.value)}>
          Save
        </button>
      </div>
    </>
  );
};
