import {
  ROUTE_ACCESS_CONTROL_REPORT_BY_GITLAB_PROJECT,
  ROUTE_ACCESS_CONTROL_REPORT_BY_JIRA_PROJECT,
  ROUTE_ACCESS_CONTROL_REPORT_BY_USER,
  ROUTE_PRODUCTS_LIST,
  ROUTE_PRODUCT_ADVOCACY_DETAIL_REPORT,
  ROUTE_PRODUCT_ADVOCACY_SUMMARY_REPORT,
  ROUTE_PRODUCT_RELATIONSHIP_REVIEW_REPORT,
  ROUTE_PRODUCT_RELATIONSHIP_UPDATES_REPORT,
  ROUTE_PRODUCT_SPOTLIGHT_SUMMARY_REPORT,
} from "../../SharedModule/utils/constants";
import { LeftNavBar } from "../../SharedModule/components/LeftNavBar";
import { LeftNavBarSection } from "../../SharedModule/components/LeftNavBarSection";

export const LeftNavBarProducts = (props: any) => {
  return (
    <LeftNavBar>
      <LeftNavBarSection
        mainTitle="Products"
        biIconName="BiCube"
        loadingStatus={props.isLoading}
        listOfLinks={[{ text: "Product List", route: ROUTE_PRODUCTS_LIST }]}
      />
      <LeftNavBarSection
        mainTitle="Reports"
        biIconName="BiBarChartAlt2"
        loadingStatus={props.isLoading}
        listOfLinks={[
          {
            text: "Spotlight Summary",
            route: ROUTE_PRODUCT_SPOTLIGHT_SUMMARY_REPORT,
          },
          {
            text: "Advocacy Summary",
            route: ROUTE_PRODUCT_ADVOCACY_SUMMARY_REPORT,
          },
          {
            text: "Advocacy Detail",
            route: ROUTE_PRODUCT_ADVOCACY_DETAIL_REPORT,
          },
          {
            text: "Relationship Updates",
            route: ROUTE_PRODUCT_RELATIONSHIP_UPDATES_REPORT,
          },
          {
            text: "Relationship Review",
            route: ROUTE_PRODUCT_RELATIONSHIP_REVIEW_REPORT,
          },
        ]}
      />
      <LeftNavBarSection
        mainTitle="Access Control Report"
        biIconName="BiBarChartAlt2"
        loadingStatus={props.isLoading}
        listOfLinks={[
          {
            text: "By User",
            route: ROUTE_ACCESS_CONTROL_REPORT_BY_USER,
          },
          {
            text: "By Jira Project",
            route: ROUTE_ACCESS_CONTROL_REPORT_BY_JIRA_PROJECT,
          },
          {
            text: "By Gitlab Project",
            route: ROUTE_ACCESS_CONTROL_REPORT_BY_GITLAB_PROJECT,
          }
        ]}
      />
    </LeftNavBar>
  );
};
