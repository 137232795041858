import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useEffect, useRef, useState } from "react";
import Footer from "../../SharedModule/components/Footer";
import { Calendar } from "../../SharedModule/components/Calendar";
import {
  BiChevronLeft,
  BiChevronRight,
  BiListUl,
  BiGridAlt,
  BiCalendar,
  BiLockAlt,
  BiInfoCircle,
  BiEditAlt,
  BiTrash,
  BiError,
} from "react-icons/bi";
import {
  nameDayDateFormat,
  numDayShortMonthDateFormat,
  numDayShortMonthWithYearDateFormat,
  quantityFormat,
} from "../../SharedModule/utils/formatters";
import { Select } from "../../SharedModule/components/Select";
import { LOCKED, THE_HUB, THIS_WEEK } from "../../SharedModule/utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { EntryHoursBox } from "../components/EntryHoursBox";
import { useTimes } from "../hooks/useTimes";
import {
  CustomWeekDay,
  DayInfo,
  EntryListView,
  RowSheetView,
  Subtask,
  TimeEntryForm,
  TimeEntryPostForm,
  TimeEntrySheetView,
} from "../types";
import {
  addEntry,
  addInvalidEntriesFromEndpoint,
  changeListView,
  addInvalidEntry,
  resetEntries,
  setLoading,
  setSubmitted,
  resetInvalidEntries,
  removeValidEntry,
} from "../redux/reducers/times.reducer";
import { SummaryTotals } from "../components/SummaryTotals";
import {
  getShortDayName,
  getWeekFullDate,
  isHoliday,
  isSameDay,
  isToday,
} from "../../SharedModule/utils/dateUtils";
import { selectUserRoles } from "../../SharedModule/redux/reducers/auth.reducer";
import { DayTotals } from "../components/DayTotals";
import { ModalPortal } from "../../SharedModule/components/ModalPortal";
import { ModalTimeEntry } from "../components/ModalTimeEntry";
import { ModalDeleteTimeEntry } from "../components/ModalDeleteTimeEntry";
import { addToast } from "../../SharedModule/redux/reducers/ui.reducer";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import {
  getCategoryId,
  getNextWeekDay,
  getPrevSunday,
  getValueFromSelectWithLabel,
  navigateTo,
} from "../utils/timesUtils";
import { TimesheetsService } from "../services/timesheets";
import {
  selectMetaLoaded,
  selectMetaTimesData,
  selectUserAssignees,
} from "../redux/reducers/meta.reducer";
import { ModalMoreInfoTimeEntry } from "../components/ModalMoreInfoTimeEntry";
import { useTopNavigationInfo } from "../../SharedModule/hooks/useTopNavigationInfo";

export const TimesPage = () => {
  const { trackPageView } = useMatomo();
  const dispatch = useDispatch();

  let numberDayToday = new Date().getUTCDate();

  // sheet view totals
  let totalSheetHours = 0;
  let totalSheetDaysHours: number[] = [0, 0, 0, 0, 0, 0, 0];

  const usersAssignees = useSelector(selectUserAssignees);
  const metaLoaded = useSelector(selectMetaLoaded);

  const { activeUserId, activeUserName } = useSelector(selectUserRoles);

  const { loadInfo } =  useTopNavigationInfo();

  const MetaTimesInfo = useSelector(selectMetaTimesData);

  // when user is admin, should be seleted in users dropdown
  let firstLoadActualUser = usersAssignees?.filter(
    (user) => user.value === activeUserId
  );

  const [pageDate, setPageDate] = useState(new Date());
  const [showedWeek, setShowedWeek] = useState(THIS_WEEK);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [actualUser, setActualUser] = useState(
    firstLoadActualUser
      ? firstLoadActualUser[0]
      : { value: activeUserId, label: activeUserName }
  );
  const [activeEntry, setActiveEntry] = useState<
    EntryListView | TimeEntrySheetView
  >(Object);
  const [entryActions, setEntryActions] = useState(undefined);
  const [showCardError, setShowCardError] = useState(false);

  useEffect(() => {
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });
  }, [trackPageView]);

  const setToday = () => {
    setPageDate(new Date());
    if (showedWeek !== THIS_WEEK) {
      setShowedWeek(THIS_WEEK);
    }
    resetPage();
  };

  const updateDateByCalendar = (date: Date) => {
    pageDate.setFullYear(date.getFullYear());
    pageDate.setMonth(date.getMonth());
    pageDate.setDate(date.getUTCDate());
    setPageDate(date);
    calculateWeek();
    resetPage();
  };

  const addOneDay = () => {
    let newDate = pageDate.setDate(pageDate.getUTCDate() + 1);
    setPageDate(new Date(newDate));
    calculateWeek();
    resetPage();
  };

  const removeOneDay = () => {
    let newDate = pageDate.setDate(pageDate.getUTCDate() - 1);
    setPageDate(new Date(newDate));
    calculateWeek();
    resetPage();
  };

  const {
    projectList,
    timeEntriesList,
    timeEntriesSheetView,
    summary,
    holidays,
    entryList,
    isTimeAdmin,
    isListView,
    isLoading,
    loadTimesPageInfo,
  } = useTimes(
    getPrevSunday(pageDate),
    getNextWeekDay(6, pageDate),
    showedWeek,
    actualUser ? actualUser.value : activeUserId,
    pageDate
  );

  const [isListWiew, setIsListWiew] = useState(isListView);

  const hasContent = () => {
    return (
      timeEntriesList.filter((entry) => isSameDay(entry.entryDate, pageDate))
        .length > 0
    );
  };

  // THead for sheet view
  // To load tr and td for sheets view table
  let actualWeek: CustomWeekDay[] = [];
  for (let i = 0; i < 7; i++) {
    actualWeek.push({
      day: getShortDayName(i),
      numDay: getNextWeekDay(i, pageDate).getUTCDate(),
      fullDate: getNextWeekDay(i, pageDate),
      isToday: isToday(getNextWeekDay(i, pageDate)),
    });
  }

  const calculateWeek = () => {
    let sunday = getPrevSunday(pageDate);
    let saturday = getNextWeekDay(6, pageDate);
    let today = new Date();
    if (sunday < today && today < saturday) {
      if (showedWeek !== THIS_WEEK) setShowedWeek(THIS_WEEK);
    } else {
      let newActualWeek = `${
        sunday.getFullYear() === today.getFullYear()
          ? numDayShortMonthDateFormat(sunday.toISOString())
          : numDayShortMonthWithYearDateFormat(sunday.toISOString())
      } - ${
        saturday.getFullYear() === today.getFullYear()
          ? numDayShortMonthDateFormat(saturday.toISOString())
          : numDayShortMonthWithYearDateFormat(saturday.toISOString())
      }`;
      if (showedWeek !== newActualWeek) {
        setShowedWeek(newActualWeek);
      }
    }
  };

  // add new empty entry
  const addNewEntry = () => {
    dispatch(addEntry());
  };

  // check in entries childs if at least one is valid, set save all button enabled
  const checkEntriesStatus = () => {
    setSaveButtonDisabled(false);
  };

  // should update entryList with entries valid and call post endpoint
  const submitEntries = async () => {
    setShowCardError(false);
    dispatch(setSubmitted(true));
    dispatch(resetInvalidEntries());

    let request: TimeEntryPostForm[] = [];
    entryList.forEach((entry) => {
      if (entry.isEntryTouched) {
        if (entry.isEntryValid) {
          request.push({
            userId: actualUser ? actualUser.value : activeUserId,
            entryDate: entry.entryDate,
            hours: entry.entryHours,
            taskTypeId: entry.entryTaskType.value,
            description: entry.entryDescription,
            entityId: entry.entryProjectWorkOrder.value,
            timeEntryCategoryId: getCategoryId(
              projectList,
              entry.entryProjectWorkOrder.value
            ),
            timeEntryTypeId: entry.entryIsBillable ? 1 : 2,
            timeEntrySourceId: 1,
            nonBillableReasonId: entry.entryIsBillable
              ? null
              : entry.entryNonBillableReason.value,
          });
          dispatch(removeValidEntry({ entry: entry }));
        } else {
          dispatch(addInvalidEntry({ entry: entry }));
          setShowCardError(true);
        }
      }
    });

    if (request.length > 0) {
      dispatch(setLoading(true));
      // services
      await TimesheetsService.addTimesEntries(request)
        .then((response) => {
          dispatch(
            addToast({ mode: "success", message: response.data.message })
          );
          // Some entries were not save correctly in BE
          if (response.data.data && response.data.data.length > 0) {
            setShowCardError(true);
            dispatch(
              addInvalidEntriesFromEndpoint({
                list: response.data.data,
                meta: MetaTimesInfo,
              })
            );
          } else {
            setSaveButtonDisabled(true);
            dispatch(setSubmitted(false));
          }

          loadInfo();
          loadTimesPageInfo(pageDate);
          return response;
        })
        .catch((error) => {
          dispatch(addToast({ mode: "error", message: error }));
          return error;
        });
    }
  };

  const changeView = (islistView: boolean) => {
    dispatch(changeListView(islistView));
    setIsListWiew(islistView);
  };

  // Modal config
  const modalRef = useRef();
  const showModal = () => {
    (modalRef.current as any).show(true);
  };
  const closeModal = () => {
    (modalRef.current as any).show(false);
  };

  // Modal Delete config
  const modalDeleteRef = useRef();
  const showDeleteModal = () => {
    (modalDeleteRef.current as any).show(true);
  };
  const closeDeleteModal = () => {
    (modalDeleteRef.current as any).show(false);
  };

  // Modal More Info config
  const modalMoreInfoRef = useRef();
  const showMoreInfoModal = () => {
    (modalMoreInfoRef.current as any).show(true);
  };
  const closeMoreInfoModal = () => {
    (modalMoreInfoRef.current as any).show(false);
  };

  const iconDeleteEnabled = (entry: EntryListView | TimeEntrySheetView) => {
    return entry.canDelete && !isLoading;
  };

  const deleteEntry = (entry: EntryListView | TimeEntrySheetView) => {
    setActiveEntry(entry);
    showDeleteModal();
  };

  const deleteEntryModal = async () => {
    closeDeleteModal();
    if (!isListView) {
      closeModal();
    }
    dispatch(setLoading(true));
    await TimesheetsService.deleteTimesEntry(activeEntry.timeEntryId)
      .then((response) => {
        dispatch(addToast({ mode: "success", message: response.data.message }));
        loadTimesPageInfo(pageDate);
      })
      .catch((error) => {
        dispatch(addToast({ mode: "error", message: error }));
        loadTimesPageInfo(pageDate);
      });
  };

  const iconEditEnabled = (entry: EntryListView) => {
    return (
      entry.canEdit &&
      !isLoading &&
      // only edit time entries with status "pending"
      entry.timeEntryStatusId === 1
    );
  };

  const editEntry = (entry: EntryListView) => {
    setEntryInitalInfo(null);
    setActiveEntry(entry);
    showModal();
  };

  const [entryInitalInfo, setEntryInitalInfo] = useState<any>(null);
  const [actualTimeEntries, setActualTimeEntries] = useState<any>([]);

  const editEntrySheetView = (day: DayInfo, task: string, entityId: number) => {
    let taskType: string = getValueFromSelectWithLabel(
      MetaTimesInfo.taskTypes,
      task
    );
    let modalDate: string = getWeekFullDate(
      actualWeek,
      day.dayNumber
    ).toISOString();
    // entryInitalInfo => set info when user want to add time entry from sheet view with date and task type
    setEntryInitalInfo({
      taskType: taskType,
      entityId,
      modalDate,
    });
    if (!day.timeEntries) {
      setActualTimeEntries([]);
      showModal();
    } else {
      // modal to edit time entries with date and task
      setActualTimeEntries(day.timeEntries);
      setActiveEntry(day.timeEntries[0]);
      showModal();
    }
  };

  const addNewEntrySheetView = () => {
    setEntryInitalInfo(null);
    setActualTimeEntries([]);
    showModal();
  };

  const showMoreInfo = async (entry: EntryListView) => {
    await TimesheetsService.getTimeEntryInfo(entry.entityId)
      .then((response) => {
        if (response.data.data.length > 0) {
          setEntryActions(response.data.data);
          showMoreInfoModal();
        } else {
          dispatch(
            addToast({ mode: "error", message: "Time entry was not found" })
          );
        }
      })
      .catch((error) => {
        dispatch(addToast({ mode: "error", message: error }));
      });
  };

  const sumHoursWorked = (days: DayInfo[]) => {
    let sum = 0;
    days.forEach((day) => {
      sum += day.hoursWorked;
    });
    return sum;
  };

  const isTimeEntryClickable = (day: string, editable: boolean) => {
    // pending review if user is admin
    // pending validation of workable day before and future
    if (editable) {
      if (new Date(day).getUTCDate() === numberDayToday) {
        return "today-clickable";
      } else {
        return "time-entry-clickable";
      }
    } else {
      return "";
    }
  };

  const isSubtaskClickable = (day: DayInfo) => {
    // pending review if user is admin
    // pending validation of workable day before and future
    if (
      !day.timeEntries ||
      (day.timeEntries && day.timeEntries.some((e) => e.canEdit))
    ) {
      if (day.dayNumber === numberDayToday) {
        return "today-clickable";
      } else {
        return "time-entry-clickable";
      }
    } else {
      return "";
    }
  };

  const checkBackgroundColor = (day: string, index: number) => {
    return isHoliday(new Date(day), holidays)
      ? "table-holiday-td"
      : new Date(day).getUTCDate() === numberDayToday
      ? "table-today-td"
      : index === 0 || index === 6
      ? "table-td"
      : "table-td-white";
  };

  const checkBackgroundColorSubtask = (day: DayInfo, index: number) => {
    return day.isHoliday
      ? "table-holiday-td"
      : day.dayNumber === numberDayToday
      ? "table-today-td"
      : index === 0 || index === 6
      ? "table-td"
      : "table-td-white";
  };

  const updateUser = (user: any) => {
    resetPage();
    setActualUser(user);
  };

  const resetPage = () => {
    setShowCardError(false);
    setSaveButtonDisabled(true);
    dispatch(setSubmitted(false));
    dispatch(resetEntries(hasContent()));
  };

  return (
    <>
      <ModalPortal ref={modalMoreInfoRef}>
        <ModalMoreInfoTimeEntry
          btnCancel={closeMoreInfoModal}
          type="moreInfoModal"
          actions={entryActions}
        />
      </ModalPortal>
      <ModalPortal ref={modalDeleteRef}>
        <ModalDeleteTimeEntry
          btnCancel={closeDeleteModal}
          btnAction={deleteEntryModal}
        />
      </ModalPortal>
      <ModalPortal ref={modalRef}>
        <ModalTimeEntry
          title="Modal Entry"
          projectList={projectList}
          btnCancel={closeModal}
          reloadPage={() => loadTimesPageInfo(pageDate)}
          type="formModal"
          editMode={isListWiew || actualTimeEntries.length > 0}
          entry={
            isListWiew || actualTimeEntries.length > 0 ? activeEntry : null
          }
          isTimeAdmin={isTimeAdmin}
          userIdParam={actualUser ? actualUser.value : activeUserId}
          holidays={holidays}
          entryInitalInfo={entryInitalInfo}
          actualTimeEntries={actualTimeEntries}
          deleteEntry={deleteEntry}
        />
      </ModalPortal>
      <div className="content">
        <div className="content-header w-100" style={{ margin: "0 0 20px" }}>
          <h2>Enter time</h2>
        </div>
        <div className="d-flex mb-4">
          {isTimeAdmin && (
            <Select
              className="times-header-input me-3"
              options={usersAssignees}
              value={actualUser}
              onChange={(user) => updateUser(user)}
              isDisabled={isLoading || !metaLoaded}
            />
          )}
          <Calendar
            className="times-header-input pointer"
            date={pageDate}
            onChange={(date: Date) => updateDateByCalendar(new Date(date))}
            isDisabled={isLoading}
          />
          <BiChevronLeft
            className="arrow-icon pointer ms-3"
            onClick={() => (!isLoading ? removeOneDay() : null)}
          />
          <BiChevronRight
            className="arrow-icon pointer me-3"
            onClick={() => (!isLoading ? addOneDay() : null)}
          />
          <div
            className="link-text pointer"
            style={{ alignSelf: "center" }}
            onClick={() => (!isLoading ? setToday() : null)}
          >
            Today
          </div>
        </div>

        <hr className="header-separator" />

        <SummaryTotals
          showTitle={true}
          showedWeek={showedWeek}
          totals={summary.totalHours}
          billable={summary.billableHours}
          nonBillable={summary.nonBillableHours}
          internal={summary.internalHours}
          timeOff={summary.timeOffHours}
          isLoading={isLoading}
        />

        <div className="d-flex mt-4 justify-content-between">
          <div className="view-buttons">
            <button
              className={`btn view-button-secondary ${
                isListWiew ? "active" : ""
              }`}
              onClick={() => (!isLoading ? changeView(true) : null)}
            >
              <BiListUl className="view-icon me-1" />
              <span className="align-middle">List</span>
            </button>
            <button
              className={`btn view-button-secondary ${
                !isListWiew ? "active" : ""
              }`}
              style={{ marginLeft: "-5px" }}
              onClick={() => (!isLoading ? changeView(false) : null)}
            >
              <BiGridAlt className="view-icon me-1" />
              <span className="align-middle">Sheet</span>
            </button>
          </div>
          {isListWiew && (
            <div className="view-buttons">
              <button
                className="btn button-secondary"
                onClick={() => addNewEntry()}
                disabled={isLoading}
              >
                Add new entries
              </button>
              <button
                className="btn btn-primary ms-3"
                onClick={() => submitEntries()}
                disabled={saveButtonDisabled || isLoading}
              >
                Save all entries
              </button>
            </div>
          )}
          {!isListWiew && (
            <div className="view-buttons">
              <button
                className="btn button-secondary"
                onClick={() => addNewEntrySheetView()}
              >
                Add new entry
              </button>
            </div>
          )}
        </div>

        {!isLoading && isListView && showCardError && (
          <div className="card-error mt-3">
            <div className="error-message mt-3">
              <BiError className="error-icon big ms-3" />
              <span className="red-text ms-2">
                You have some things to correct.
              </span>
              <span className="black-text ms-2">
                Please correct required fields to add entry.
              </span>
            </div>
          </div>
        )}

        {isListWiew &&
          !isLoading &&
          entryList.map((elem: TimeEntryForm) => {
            return (
              <EntryHoursBox
                key={elem.entryId}
                state={elem}
                projectsWorkOrders={projectList}
                isLoadingProjects={isLoading}
                isTouched={checkEntriesStatus}
                isModal={false}
                holidays={holidays}
              />
            );
          })}

        {isListWiew &&
          !isLoading &&
          timeEntriesList.filter((entry) =>
            isSameDay(entry.entryDate, pageDate)
          ).length > 0 && (
            <>
              <div className="d-flex mt-4 justify-content-between">
                <div className="left-side d-flex align-items-center">
                  <BiCalendar style={{ width: "20px", height: "20px" }} />
                  <div className="table-date ms-2">
                    {nameDayDateFormat(pageDate.toISOString())}
                  </div>
                </div>
                <DayTotals timeEntries={timeEntriesList} actualDay={pageDate} />
              </div>
              <table className="table white-table-header-background mt-3 mb-5" style={{ width: "100%" }}>
                <thead className="align-middle sticky-header">
                  <tr>
                    <th className="border-dark border-top fw-500 py-0 ps-5 column-30">
                      Client/Task
                    </th>
                    <th className="border-dark border-top fw-500 py-0 ps-5 column-10">
                      Hours/Type
                    </th>
                    <th className="border-dark border-top fw-500 py-0 ps-5 column-30">
                      Description
                    </th>
                    <th className="border-dark border-top fw-500 py-0 ps-5 column-15">
                      Billable/Price
                    </th>
                    <th className="border-dark border-top fw-500 text-center py-0 column-5">
                      Status
                    </th>
                    <th className="border-dark border-top fw-500 text-center py-0 column-15">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {timeEntriesList
                    .filter((entry) => isSameDay(entry.entryDate, pageDate))
                    .map((entry: EntryListView) => {
                      return (
                        <tr
                          key={entry.timeEntryId}
                          style={{ verticalAlign: "middle" }}
                        >
                          <td className="table-td ps-5 py-3 fw-500">
                            <div className="td-small-text">
                              {entry.clientName}
                            </div>
                            <div
                              className="td-task-link"
                              onClick={() => navigateTo(entry)}
                            >
                              {entry.entityDescription}
                            </div>
                            {isTimeAdmin &&
                              activeUserId !== actualUser.value && (
                                <div className="td-small-text mt-1">
                                  {actualUser.label}
                                </div>
                              )}
                          </td>
                          <td className="table-td ps-5 py-3 fw-500">
                            <div className="td-bold">
                              {quantityFormat(entry.hours)}
                            </div>
                            <div className="td-text">{entry.taskType}</div>
                          </td>
                          <td className="table-td ps-5 py-3 fw-500">
                            <div className="td-text">{entry.description}</div>
                          </td>
                          <td className="table-td ps-5 py-3 fw-500">
                            <div className="td-bold">
                              {entry.timeEntryTypeId === 1 ? "Yes" : "No"}
                            </div>
                            <div className="td-small-text">
                              Internal Project
                            </div>
                            {isTimeAdmin && entry.timeEntryTypeId === 1 && (
                              <div className="td-text">
                                ${quantityFormat(entry.price)}
                              </div>
                            )}
                          </td>
                          <td className="table-td py-4 fw-500">
                            <div className={`td-status ${entry.status} d-flex`}>
                              {entry.status === LOCKED && (
                                <BiLockAlt className="me-1 mt-1" />
                              )}
                              {entry.status}
                            </div>
                          </td>
                          <td className="table-td action-icons-td py-4">
                            <div className="d-flex flex-column">
                              {entry.source !== THE_HUB && (
                                <div className="entry-text-imported">
                                  From {entry.source}
                                </div>
                              )}
                              <div>
                                <Tooltip
                                  title={<b>{"More Info"}</b>}
                                  placement="top"
                                  TransitionComponent={Zoom}
                                  arrow
                                >
                                  <span>
                                    <BiInfoCircle
                                      className={`icon-action ${
                                        !isLoading ? "" : "disabled"
                                      }`}
                                      onClick={() => showMoreInfo(entry)}
                                    />
                                  </span>
                                </Tooltip>
                                <Tooltip
                                  title={<b>{"Edit"}</b>}
                                  placement="top"
                                  TransitionComponent={Zoom}
                                  arrow
                                >
                                  <span>
                                    <BiEditAlt
                                      className={`icon-action ${
                                        iconEditEnabled(entry) ? "" : "disabled"
                                      }`}
                                      onClick={() =>
                                        iconEditEnabled(entry)
                                          ? editEntry(entry)
                                          : undefined
                                      }
                                    />
                                  </span>
                                </Tooltip>
                                <Tooltip
                                  title={<b>{"Delete"}</b>}
                                  placement="top"
                                  TransitionComponent={Zoom}
                                  arrow
                                >
                                  <span>
                                    <BiTrash
                                      className={`icon-action ${
                                        iconDeleteEnabled(entry)
                                          ? ""
                                          : "disabled"
                                      }`}
                                      onClick={() =>
                                        iconDeleteEnabled(entry)
                                          ? deleteEntry(entry)
                                          : undefined
                                      }
                                    />
                                  </span>
                                </Tooltip>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </>
          )}

        {!isLoading && !isListView && timeEntriesSheetView && (
          <table
            className="table-sheet-view table mt-3 mb-5"
            style={{ width: "100%" }}
          >
            <thead className="align-middle sticky-header">
              <tr>
                <th
                  className="table-td border-dark border-top fw-500 py-2 px-4 column-35"
                  id="Client/Project"
                >
                  Client/Project
                </th>
                <th
                  className="table-totals border-dark border-top fw-500 py-2 column-8 text-center"
                  id="Totals"
                >
                  Totals
                </th>
                {actualWeek &&
                  actualWeek.map((day: CustomWeekDay, index: number) => {
                    return (
                      <th
                        key={day.numDay}
                        className={`${
                          day.isToday ? "table-today-th" : "table-td"
                        } border-dark border-top fw-500 py-2 column-8`}
                      >
                        <div className="d-flex flex-column text-center">
                          <div>{day.day}</div>
                          <div>{day.numDay}</div>
                        </div>
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {timeEntriesSheetView &&
                timeEntriesSheetView.map((row: RowSheetView, index: number) => {
                  let subtasks: Subtask[] = [];
                  totalSheetHours = +row.totalHours;
                  if (row.subTasksTotals) {
                    Object.keys(row.subTasksTotals).forEach(
                      (subtask: string) => {
                        subtasks.push({ task: subtask, days: [] });
                      }
                    );
                  }
                  return (
                    <>
                      <tr key={row.entityId}>
                        <td
                          className="table-td-white py-3 ps-4 fw-500"
                          id="clientName"
                        >
                          <div className="d-flex flex-column">
                            <div className="td-small-text">
                              {row.clientName}
                            </div>
                            <div className="td-text">{row.entityName}</div>
                          </div>
                        </td>
                        <td
                          className="table-totals py-2 fw-500 text-center vert-middle border total-hours-day"
                          id="totalHours"
                        >
                          {row.totalHours}
                        </td>
                        {Object.keys(row.dailyDetail).map(
                          (day: string, index: number) => {
                            let taskSelected,
                              otherTask,
                              allTaskSelected: any[] = [];
                            if (row.dailyDetail[day][0]) {
                              // for to check more than one task type in a day
                              for (let elem of row.dailyDetail[day]) {
                                // Sum totals by day
                                totalSheetDaysHours[index] += elem.hoursWorked;
                                // Find task by name
                                taskSelected = subtasks.find(
                                  (x) => x.task === elem.taskName
                                );
                                allTaskSelected.push(taskSelected.task);
                                taskSelected.days.push({
                                  hoursWorked: elem.hoursWorked,
                                  dayNumber: new Date(day).getUTCDate(),
                                  timeEntries: elem.timeEntries,
                                  isHoliday: isHoliday(new Date(day), holidays),
                                });
                              }
                              // check if number os subtask with hours (row.dailyDetail[day].length) is less than number of existing subtasks
                              if (
                                row.dailyDetail[day].length < subtasks.length
                              ) {
                                for (
                                  let i = row.dailyDetail[day].length;
                                  i < subtasks.length;
                                  i++
                                ) {
                                  otherTask = subtasks.filter(
                                    (x) => !allTaskSelected.includes(x.task)
                                  );
                                  otherTask.forEach((element) => {
                                    element.days.push({
                                      hoursWorked: 0,
                                      dayNumber: new Date(day).getUTCDate(),
                                      timeEntries: null,
                                      isHoliday: isHoliday(
                                        new Date(day),
                                        holidays
                                      ),
                                    });
                                  });
                                }
                              }
                            } else {
                              subtasks.forEach((element) => {
                                element.days.push({
                                  hoursWorked: 0,
                                  dayNumber: new Date(day).getUTCDate(),
                                  timeEntries: null,
                                  isHoliday: isHoliday(new Date(day), holidays),
                                });
                              });
                            }
                            return (
                              <td
                                key={day}
                                id={day}
                                className={`${checkBackgroundColor(
                                  day,
                                  index
                                )} py-2 fw-500 text-center vert-middle ${
                                  index !==
                                  Object.keys(row.dailyDetail).length - 1
                                    ? "border"
                                    : ""
                                } ${
                                  row.subTasksTotals
                                    ? ""
                                    : isTimeEntryClickable(
                                        day,
                                        row.dailyDetail[day][0].canEdit
                                      )
                                }`}
                              >
                                {row.dailyDetail[day][0]
                                  ? row.dailyDetail[day][0].hoursWorked
                                  : "-"}
                              </td>
                            );
                          }
                        )}
                      </tr>
                      {subtasks.length > 0 &&
                        subtasks.map((elem: Subtask, index: number) => {
                          return (
                            <tr key={elem.task + elem.days[0].dayNumber}>
                              <td className="table-td-white py-2 ps-5 fw-500">
                                <span className="td-text task-row">
                                  {elem.task}
                                </span>
                              </td>
                              <td className="table-totals py-2 fw-500 text-center vert-middle border total-hours-subtask">
                                {sumHoursWorked(elem.days)}
                              </td>
                              {elem.days.map((day, index) => {
                                return (
                                  <td
                                    key={day.dayNumber}
                                    className={`${checkBackgroundColorSubtask(
                                      day,
                                      index
                                    )} py-2 fw-500 text-center vert-middle ${
                                      index !== elem.days.length - 1
                                        ? "border"
                                        : ""
                                    } ${isSubtaskClickable(day)}`}
                                    onClick={() =>
                                      isSubtaskClickable(day)
                                        ? editEntrySheetView(
                                            day,
                                            elem.task,
                                            row.entityId
                                          )
                                        : undefined
                                    }
                                  >
                                    {day.hoursWorked ? day.hoursWorked : "-"}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                    </>
                  );
                })}

              {/* totals row */}
              {timeEntriesSheetView && timeEntriesSheetView.length > 0 && (
                <tr>
                  <td className="table-td"></td>
                  <td className="table-totals py-2 fw-500 text-center vert-middle">
                    {totalSheetHours}
                  </td>
                  {actualWeek &&
                    actualWeek.map((day: CustomWeekDay, index: number) => {
                      return (
                        <th
                          key={day.day + day.numDay}
                          className={`${
                            day.isToday ? "table-today-th" : "table-td"
                          } fw-500 py-2 column-8 sum-totals-hours`}
                        >
                          <div className="d-flex flex-column text-center">
                            {totalSheetDaysHours[index] === 0
                              ? ""
                              : totalSheetDaysHours[index]}
                          </div>
                        </th>
                      );
                    })}
                </tr>
              )}
            </tbody>
          </table>
        )}

        <Footer />
      </div>
      )
    </>
  );
};
