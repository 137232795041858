import { useNavigate } from "react-router-dom";
import { CheckSkeleton } from "../../SharedModule/utils/checkSkeleton";
import {
  LOCKED,
  ROUTE_QUERY_TIMESHEETS,
  THE_HUB,
} from "../../SharedModule/utils/constants";
import {
  BiLeftArrowAlt,
  BiLockAlt,
  BiInfoCircle,
  BiEditAlt,
  BiTrash,
  BiCalendar,
} from "react-icons/bi";
import {
  FiltersTouched,
  GetFilters,
  keepFilters,
  resetFilters,
  setAdditionalFilters,
} from "../redux/reducers/timesQuery.reducer";
import { useDispatch, useSelector } from "react-redux";
import {
  dateFormatEntryEdit,
  nameDayDateFormat,
  quantityFormat,
} from "../../SharedModule/utils/formatters";
import {
  camelCaseToWords,
  firstLetterToLower,
  hasTimeAdminAccess,
  navigateTo,
  transformValue,
} from "../utils/timesUtils";
import { useRef, useState } from "react";
import { SummaryTotals } from "../components/SummaryTotals";
import { useQueryResults } from "../hooks/useQueryResults";
import { EntryListView } from "../types";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { ModalPortal } from "../../SharedModule/components/ModalPortal";
import { ModalMoreInfoTimeEntry } from "../components/ModalMoreInfoTimeEntry";
import { addToast } from "../../SharedModule/redux/reducers/ui.reducer";
import { TimesheetsService } from "../services/timesheets";
import { ModalTimeEntry } from "../components/ModalTimeEntry";
import { ModalDeleteTimeEntry } from "../components/ModalDeleteTimeEntry";
import { setLoading } from "../redux/reducers/queryResult.reducer";
import { DayTotals } from "../components/DayTotals";
import { ActionsButton } from "../components/ActionsButton";
import { ModalActionNonBillable } from "../components/ModalActionNonBillable";
import { selectMetaTimesData } from "../redux/reducers/meta.reducer";
import { ModalActionChangeDate } from "../components/ModalActionChangeDate";
import { ModalActionChangeProject } from "../components/ModalActionChangeProject";
import { actualUIState } from "../../ProductsModule/redux/reducers/ui.reducer";

export const QueryResultsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const MetaTimesInfo = useSelector(selectMetaTimesData);

  const [entryActions, setEntryActions] = useState(undefined);
  const [activeEntry, setActiveEntry] = useState<EntryListView>(Object);

  const [showDetails, setShowDetails] = useState(true);

  const [entriesSelected, setEntriesSelected] = useState<any>([]);

  const isSelected = (entry: any) => {
    let response = entriesSelected.indexOf(entry);
    return response > -1;
  };

  const updatedSelected = (entry: any) => {
    let response = entriesSelected.indexOf(entry);
    if (response < 0) {
      const dataUpdated = [entry, ...entriesSelected];
      setEntriesSelected(dataUpdated);
    } else {
      setEntriesSelected(
        entriesSelected.filter((item) => item.id !== entry.id)
      );
    }
  };

  const updateSelectedByDay = (date: string, value: boolean) => {
    let response = queryResultList[date];
    if (value) {
      const dataUpdated = [...response, ...entriesSelected];
      setEntriesSelected(dataUpdated);
    } else {
      setEntriesSelected(
        entriesSelected.filter((item) => item.entryDate !== date)
      );
    }
  };

  const filtersTouched = useSelector(FiltersTouched);
  const filters = useSelector(GetFilters);

  // times module admin
  const { userRolesTimesModule } = useSelector(actualUIState);
  const [isTimeAdmin] = useState(hasTimeAdminAccess(userRolesTimesModule));

  const {
    summary,
    queryResultList,
    isLoading,
    projectList,
    holidays,
    loadQueryResultsPageInfo,
  } = useQueryResults(filters);

  let listFiltersTouched: any[] = [];
  let listFiltersUntouched: any[] = [];

  for (const property in filtersTouched) {
    if (filtersTouched[property]) {
      listFiltersTouched.push({
        name: camelCaseToWords(property),
        value: transformValue(property, filters[property]),
      });
    } else {
      listFiltersUntouched.push({
        name: camelCaseToWords(property),
        value: transformValue(property, filters[property]),
      });
    }
  }

  let showAdditionalFilters: boolean =
    filtersTouched.taskTypes ||
    filtersTouched.countries ||
    filtersTouched.billableHours ||
    filtersTouched.nonBillableHours ||
    filtersTouched.internalHours ||
    filtersTouched.nonBillableReasons ||
    filtersTouched.paidTimeOff ||
    filtersTouched.unpaidTimeOff ||
    filtersTouched.pendingHours ||
    filtersTouched.approvedHours ||
    filtersTouched.lockedHours;

  const backToQuery = () => {
    dispatch(keepFilters(true));
    dispatch(setAdditionalFilters(showAdditionalFilters));
    navigate(ROUTE_QUERY_TIMESHEETS);
  };

  const newQuery = () => {
    dispatch(resetFilters());
    navigate(ROUTE_QUERY_TIMESHEETS);
  };

  // Modal More Info config
  const modalMoreInfoRef = useRef();
  const showMoreInfoModal = () => {
    (modalMoreInfoRef.current as any).show(true);
  };
  const closeMoreInfoModal = () => {
    (modalMoreInfoRef.current as any).show(false);
  };

  const showMoreInfo = async (entry: EntryListView) => {
    await TimesheetsService.getTimeEntryInfo(entry.entityId)
      .then((response) => {
        if (response.data.data.length > 0) {
          setEntryActions(response.data.data);
          showMoreInfoModal();
        } else {
          dispatch(
            addToast({ mode: "error", message: "Time entry was not found" })
          );
        }
      })
      .catch((error) => {
        dispatch(addToast({ mode: "error", message: error }));
      });
  };

  // Modal config
  const modalRef = useRef();
  const showModal = () => {
    (modalRef.current as any).show(true);
  };
  const closeModal = () => {
    (modalRef.current as any).show(false);
  };

  const editEntry = (entry: EntryListView) => {
    // only edit time entries with status "pending"
    if (entry.canEdit && entry.timeEntryStatusId === 1) {
      setActiveEntry(entry);
      showModal();
    }
  };

  // Modal Delete config
  const modalDeleteRef = useRef();
  const showDeleteModal = () => {
    (modalDeleteRef.current as any).show(true);
  };
  const closeDeleteModal = () => {
    (modalDeleteRef.current as any).show(false);
  };

  // Modal Action billable
  const ModalActionNonBillableRef = useRef();
  const showActionNonBillaleModal = () => {
    (ModalActionNonBillableRef.current as any).show(true);
  };
  const closeActionNonBillaleModal = () => {
    (ModalActionNonBillableRef.current as any).show(false);
  };

  // Modal Action change date
  const modalActionChangeDateRef = useRef();
  const showActionChangeDateModal = () => {
    (modalActionChangeDateRef.current as any).show(true);
  };
  const closeActionChangeDateModal = () => {
    (modalActionChangeDateRef.current as any).show(false);
  };

  // Modal Action change project
  const modalActionChangeProjectRef = useRef();
  const showActionChangeProjectModal = () => {
    (modalActionChangeProjectRef.current as any).show(true);
  };
  const closeActionChangeProjectModal = () => {
    (modalActionChangeProjectRef.current as any).show(false);
  };

  const deleteEntry = (entry: EntryListView) => {
    if (entry.canDelete) {
      setActiveEntry(entry);
      showDeleteModal();
    }
  };

  const deleteEntryModal = async () => {
    closeDeleteModal();
    dispatch(setLoading(true));
    await TimesheetsService.deleteTimesEntry(activeEntry.timeEntryId)
      .then((response) => {
        dispatch(addToast({ mode: "success", message: response.data.message }));
        loadQueryResultsPageInfo();
      })
      .catch((error) => {
        dispatch(addToast({ mode: "error", message: error }));
        loadQueryResultsPageInfo();
      });
  };

  const loadBodyToupdateTimeEntries = (action: number, value: any) => {
    // 1-billable 2-nonBillable 3-approved 4-unapprove 5=moveDate 6-moveProject
    let body = {
      timeEntriesIds: entriesSelected.map((elem) => elem.timeEntryId),
      action: {
        id: action,
        nonBillableReasonId: action === 2 ? value : null,
        entryDate: action === 5 ? value : null,
        // to review this 2 props
        entityId: action === 6 ? value.entityId : null,
        categoryId: action === 6 ? value.categoryId : null,
      },
    };
    return body;
  };

  const updateEntriesSelected = async (action: number, value: any) => {
    let respBody = loadBodyToupdateTimeEntries(action, value);
    // await TimesheetsService.updateTimeEntries(
    //   loadBodyToupdateTimeEntries(action, value)
    // )
    //   .then((response) => {
    //     dispatch(addToast({ mode: "success", message: response.data.message }));
    //     loadQueryResultsPageInfo();
    //   })
    //   .catch((error) => {
    //     dispatch(addToast({ mode: "error", message: error }));
    //   });
  };

  const getUserName = (userId: string) => {
    let response: any = MetaTimesInfo.timeUsers?.filter(
      (user) => user.value === userId
    );
    return response[0].label;
  };

  return (
    <>
      <ModalPortal ref={modalMoreInfoRef}>
        <ModalMoreInfoTimeEntry
          btnCancel={closeMoreInfoModal}
          type="moreInfoModal"
          actions={entryActions}
        />
      </ModalPortal>
      <ModalPortal ref={modalRef}>
        <ModalTimeEntry
          title="Modal Entry"
          projectList={projectList}
          btnCancel={closeModal}
          reloadPage={loadQueryResultsPageInfo}
          type="formModal"
          editMode={true}
          isTimeAdmin={isTimeAdmin}
          entry={activeEntry}
          userIdParam={activeEntry.userId}
          holidays={holidays}
          deleteEntry={deleteEntry}
        />
      </ModalPortal>
      <ModalPortal ref={modalDeleteRef}>
        <ModalDeleteTimeEntry
          btnCancel={closeDeleteModal}
          btnAction={deleteEntryModal}
        />
      </ModalPortal>
      <ModalPortal ref={ModalActionNonBillableRef}>
        <ModalActionNonBillable
          btnCancel={closeActionNonBillaleModal}
          btnAction={updateEntriesSelected}
          nonBillableReasons={MetaTimesInfo.nonBillableReasons}
        />
      </ModalPortal>
      <ModalPortal ref={modalActionChangeDateRef}>
        <ModalActionChangeDate
          btnCancel={closeActionChangeDateModal}
          btnAction={updateEntriesSelected}
        />
      </ModalPortal>
      <ModalPortal ref={modalActionChangeProjectRef}>
        <ModalActionChangeProject
          btnCancel={closeActionChangeProjectModal}
          btnAction={updateEntriesSelected}
          projects={MetaTimesInfo.timeProjects}
        />
      </ModalPortal>
      <div className="content">
        <div className="my-2 button-header">
          <div className="back-button-container" onClick={() => backToQuery()}>
            <BiLeftArrowAlt className="back-button-arrow" />
            <span className="back-button-text">Back to Query</span>
          </div>
          <button className="btn button-secondary" onClick={() => newQuery()}>
            New Query
          </button>
        </div>
        <div className="d-flex flex-wrap mb-3">
          <h2 className="flex-fill">Results</h2>
        </div>
        <div className="flex-container flex-wrap">
          <div className="col-lg-4 col-12 d-inline-flex mb-3">
            <div className="col-4">From:</div>
            <CheckSkeleton isLoading={isLoading} classes="col-7">
              <div className="col-7 fw-bold">{`${dateFormatEntryEdit(
                filters.startDate
              )} - ${dateFormatEntryEdit(filters.endDate)}`}</div>
            </CheckSkeleton>
          </div>
          {listFiltersTouched &&
            listFiltersTouched.length > 0 &&
            listFiltersTouched.map((filter: any) => {
              return (
                <div
                  className="col-lg-4 col-12 d-inline-flex mb-3"
                  key={filter.name}
                >
                  <div className="col-4">{filter.name}:</div>
                  <CheckSkeleton isLoading={isLoading} classes="col-7">
                    <div className="col-7 fw-bold">{filter.value}</div>
                  </CheckSkeleton>
                </div>
              );
            })}
          {!showDetails &&
            listFiltersUntouched &&
            listFiltersUntouched.length > 0 &&
            listFiltersUntouched.map((filter: any) => {
              return (
                <div
                  className="col-lg-4 col-12 d-inline-flex mb-3"
                  key={filter.name}
                >
                  <div className="col-4">{filter.name}:</div>
                  <CheckSkeleton isLoading={isLoading} classes="col-7">
                    <div className="col-7 fw-bold">{filter.value}</div>
                  </CheckSkeleton>
                </div>
              );
            })}
        </div>
        <div className="d-flex flex-wrap mb-3">
          <div
            className="link-text text-decoration-underline"
            onClick={() => setShowDetails(!showDetails)}
          >
            {showDetails ? "Show All Filters" : "Hide Details"}
          </div>
        </div>
        <SummaryTotals
          showTitle={false}
          totals={summary.totalHours}
          billable={summary.billableHours}
          nonBillable={summary.nonBillableHours}
          internal={summary.internalHours}
          timeOff={summary.timeOffHours}
          isLoading={isLoading}
        />
        <hr className="header-separator mt-5" />
        <div className="d-flex mt-3 justify-content-end">
          <div className="view-buttons">
            {isTimeAdmin && (
              <ActionsButton
                disabled={entriesSelected.length === 0}
                entriesSelected={entriesSelected}
                showActionNonBillaleModal={showActionNonBillaleModal}
                showActionChangeDateModal={showActionChangeDateModal}
                showActionChangeProjectModal={showActionChangeProjectModal}
                updateEntriesSelected={updateEntriesSelected}
              />
            )}
          </div>
        </div>

        {!isLoading &&
          queryResultList &&
          Object.keys(queryResultList).map((keyName) => {
            let actualSummary = {
              total: 0,
              billable: 0,
              nonBillable: 0,
              internal: 0,
              timeOff: 0,
            };
            queryResultList[keyName].forEach((element) => {
              actualSummary[firstLetterToLower(element.timeEntryType)] +=
                element.hours;
              actualSummary["total"] += element.hours;
            });
            return (
              <>
                <div
                  className="d-flex mt-4 justify-content-between"
                  key={keyName + "-summary"}
                >
                  <div className="left-side d-flex align-items-center">
                    <BiCalendar style={{ width: "20px", height: "20px" }} />
                    <div className="table-date ms-2">
                      {nameDayDateFormat(queryResultList[keyName][0].entryDate)}
                    </div>
                  </div>
                  <DayTotals daySummary={actualSummary} />
                </div>

                <table
                  className="table white-table-header-background mt-3 mb-5"
                  style={{ width: "100%" }}
                  key={"table-" + keyName}
                >
                  <thead className="align-middle sticky-header">
                    <tr>
                      {isTimeAdmin && (
                        <th className="text-center border-dark border-top fw-500 text-center py-1 column-5 small">
                          Select
                          <div className="d-flex justify-content-center">
                            <div
                              className="link-text mx-2"
                              onClick={() =>
                                updateSelectedByDay(
                                  queryResultList[keyName][0].entryDate,
                                  true
                                )
                              }
                            >
                              All
                            </div>
                            /
                            <div
                              className="link-text mx-2"
                              onClick={() =>
                                updateSelectedByDay(
                                  queryResultList[keyName][0].entryDate,
                                  false
                                )
                              }
                            >
                              None
                            </div>
                          </div>
                        </th>
                      )}
                      <th
                        className={`border-dark border-top fw-500 py-0 ps-5 column-${
                          isTimeAdmin ? "25" : "30"
                        }`}
                      >
                        Client/Task
                      </th>
                      <th className="border-dark border-top fw-500 py-0 ps-5 column-10">
                        Hours/Type
                      </th>
                      <th className="border-dark border-top fw-500 py-0 ps-5 column-30">
                        Note
                      </th>
                      <th className="border-dark border-top fw-500 py-0 ps-5 column-15">
                        Billable/Price
                      </th>
                      <th className="border-dark border-top fw-500 text-center py-0 column-5">
                        Status
                      </th>
                      <th className="border-dark border-top fw-500 text-center py-0 column-15">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {queryResultList[keyName].map((entry: any) => {
                      return (
                        <tr
                          key={"row-" + entry.timeEntryId}
                          style={{ verticalAlign: "middle" }}
                        >
                          {isTimeAdmin && (
                            <td className="table-td text-center py-0">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={isSelected(entry)}
                                disabled={entry.source !== THE_HUB}
                                onChange={() => updatedSelected(entry)}
                              />
                            </td>
                          )}
                          <td className="table-td ps-5 py-3 fw-500">
                            <div className="td-small-text">
                              {entry.clientName}
                            </div>
                            <div
                              className="td-task-link"
                              onClick={() => navigateTo(entry)}
                            >
                              {entry.entityDescription}
                            </div>
                            <div className="td-small-text mt-1">
                              {getUserName(entry.userId)}
                            </div>
                          </td>
                          <td className="table-td ps-5 py-3 fw-500">
                            <div className="td-bold">
                              {quantityFormat(entry.hours)}
                            </div>
                            <div className="td-text">{entry.taskType}</div>
                          </td>
                          <td className="table-td ps-5 py-3 fw-500">
                            <div className="td-text">{entry.description}</div>
                          </td>
                          <td className="table-td ps-5 py-3 fw-500">
                            <div className="td-bold">
                              {entry.timeEntryTypeId === 1 ? "Yes" : "No"}
                            </div>
                            <div className="td-small-text">
                              Internal Project
                            </div>
                            {entry.timeEntryTypeId === 1 && (
                              <div className="td-text">
                                ${quantityFormat(entry.price)}
                              </div>
                            )}
                          </td>
                          <td className="table-td py-4 fw-500">
                            <div className={`td-status ${entry.status} d-flex`}>
                              {entry.status === LOCKED && (
                                <BiLockAlt className="me-1 mt-1" />
                              )}
                              {entry.status}
                            </div>
                          </td>
                          <td className="table-td action-icons-td py-4">
                            <div className="d-flex flex-column">
                              {entry.source !== THE_HUB && (
                                <div className="entry-text-imported">
                                  From {entry.source}
                                </div>
                              )}
                              <div>
                                <Tooltip
                                  title={<b>More Info</b>}
                                  placement="top"
                                  TransitionComponent={Zoom}
                                  arrow
                                >
                                  <span>
                                    <BiInfoCircle
                                      className={`icon-action ${
                                        !isLoading ? "" : "disabled"
                                      }`}
                                      onClick={() => showMoreInfo(entry)}
                                    />
                                  </span>
                                </Tooltip>
                                <Tooltip
                                  title={<b>Edit</b>}
                                  placement="top"
                                  TransitionComponent={Zoom}
                                  arrow
                                >
                                  <span>
                                    <BiEditAlt
                                      className={`icon-action ${
                                        entry.canEdit &&
                                        !isLoading &&
                                        entry.timeEntryStatusId === 1
                                          ? ""
                                          : "disabled"
                                      }`}
                                      onClick={() => editEntry(entry)}
                                    />
                                  </span>
                                </Tooltip>
                                <Tooltip
                                  title={<b>Delete</b>}
                                  placement="top"
                                  TransitionComponent={Zoom}
                                  arrow
                                >
                                  <span>
                                    <BiTrash
                                      className={`icon-action ${
                                        entry.canDelete && !isLoading
                                          ? ""
                                          : "disabled"
                                      }`}
                                      onClick={() => deleteEntry(entry)}
                                    />
                                  </span>
                                </Tooltip>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            );
          })}
      </div>
    </>
  );
};
