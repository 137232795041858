import "../../../styles/main.scss";
import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  addToExcluded,
  removeFromExcluded,
  selectAll,
  selectRecurringOrderedBy,
  deselectAll,
} from "./../../redux/reducers/billableItems.reducer";

import {
  currencyFormat,
  dateFormat,
} from "../../../SharedModule/utils/formatters";
import { Spinner } from "../../../SharedModule/components/Spinner";
import image from "./../../../styles/legacy/404.png";
import { NoContent } from "../NoContent";

export const Recurring = ({ loading }: { loading: boolean }) => {
  const [sort, setSort] = useState<any>({
    orderBy: "accountName",
    criteria: "asc",
    dataType: "string",
  });

  const sortBy = (orderBy: any, dataType: "string" | "date" | "number") => {
    let criteria: "asc" | "desc" = "asc";
    if (sort.orderBy === orderBy) {
      criteria = sort.criteria === "asc" ? "desc" : "asc";
    }
    setSort({
      orderBy,
      criteria,
      dataType,
    });
  };

  const { recurring, excluded } = useSelector(
    selectRecurringOrderedBy(sort.orderBy, sort.criteria, sort.dataType),
    shallowEqual
  );

  const dispatch = useDispatch();

  if (loading)
    return (
      <div className="mt-5">
        <Spinner
          style={{
            marginLeft: "50%",
            marginTop: "5%",
          }}
        />
      </div>
    );

  const isSelected = (id: number) => {
    return !excluded.some((item: any) => item.id === id);
  };

  const onClickCheckbox = (item: any) => {
    if (isSelected(item.id)) {
      dispatch(
        addToExcluded({
          type: "recurring",
          id: item.id,
        })
      );
    } else {
      dispatch(
        removeFromExcluded({
          type: "recurring",
          id: item.id,
        })
      );
    }
  };

  if (!loading && !recurring.length)
    return (
      <NoContent
        title="No Entries"
        text="No entries were found matching your query request."
        image={image}
        style={{ margin: "3% auto" }}
      />
    );

  return (
    <>
      <table className="table white-table-header-background mt-5">
        <thead className="align-middle">
          <tr className="sticky sticky-with-tabs">
            <th className="small fw-normal text-center border-dark border-top column-10">
              Select
              <div className="d-flex justify-content-center">
                <div
                  className="link-text mx-2"
                  onClick={() => dispatch(selectAll({ type: "recurring" }))}
                >
                  All
                </div>
                /
                <div
                  className="link-text mx-2"
                  onClick={() => dispatch(deselectAll({ type: "recurring" }))}
                >
                  None
                </div>
              </div>
            </th>
            <th
              className={`border-dark border-top fw-normal py-0 pointer column-20 ${
                sort.orderBy === "accountName" ? "sorted-" + sort.criteria : ""
              }`}
              onClick={() => sortBy("accountName", "string")}
            >
              Client
            </th>
            <th
              className={`border-dark border-top fw-normal py-0 pointer column-20 ${
                sort.orderBy === "description" ? "sorted-" + sort.criteria : ""
              }`}
              onClick={() => sortBy("description", "string")}
            >
              Description
            </th>
            <th
              className={`border-dark border-top fw-normal py-0 pointer column-15 ${
                sort.orderBy === "billThruDate" ? "sorted-" + sort.criteria : ""
              }`}
              onClick={() => sortBy("billThruDate", "date")}
            >
              Billed through
            </th>
            <th
              className={`border-dark border-top fw-normal py-0 pointer column-10 ${
                sort.orderBy === "quantity" ? "sorted-" + sort.criteria : ""
              }`}
              onClick={() => sortBy("quantity", "number")}
            >
              Qty
            </th>
            <th
              className={`border-dark border-top fw-normal text-end py-0 column-10 pointer ${
                sort.orderBy === "unitPrice"
                  ? "sorted-" + sort.criteria
                  : "sorted-column"
              }`}
              onClick={() => sortBy("unitPrice", "number")}
            >
              Price
            </th>
            <th
              className={`border-dark border-top fw-normal text-end py-0 column-15 pointer ${
                sort.orderBy === "amount"
                  ? "sorted-" + sort.criteria
                  : "sorted-column"
              }`}
              onClick={() => sortBy("amount", "number")}
            >
              Total Price
            </th>
          </tr>
        </thead>
        <tbody>
          {recurring.map((el: any) => (
            <tr key={el.id}>
              <td
                className="text-center pb-0"
                style={{ verticalAlign: "middle" }}
              >
                <div className="form-check d-inline-block">
                  <input
                    className="form-check-input"
                    id="hours"
                    type="checkbox"
                    checked={isSelected(el.id)}
                    onChange={() => onClickCheckbox(el)}
                  />
                </div>
              </td>
              <td>{el.accountName}</td>
              <td>{el.description}</td>
              <td>{el.billThruDate ? dateFormat(el.billThruDate) : ""}</td>
              <td>{el.quantity}</td>
              <td className="text-end">{currencyFormat(el.unitPrice)}</td>
              <td className="fw-bold text-end">{currencyFormat(el.amount)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
