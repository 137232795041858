import { DEVELOPMENT, PRODUCTION, RELEASE } from "./constants";

export const hasModuleAccess = (key: string, list: any) => {
  if (list && list.hasOwnProperty(key)) {
    return list[key];
  } else {
    return false;
  }
};

export const checkEnvironment = () => {
  let environment = DEVELOPMENT;
  if (process.env.REACT_APP_MSAL_REDIRECT_URI === "https://thehub.itx.com/") {
    environment = PRODUCTION;
  } else if (
    process.env.REACT_APP_MSAL_REDIRECT_URI ===
    "https://thehub-release.itx.dev/"
  ) {
    environment = RELEASE;
  }
  return environment;
}
