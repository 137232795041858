import { EntryListView } from "./../types";
import { newUuid } from "../../SharedModule/utils/uuid";
import { FiltersBody, SelectItemNumber } from "../types";
import {
  ROLE_PRICE_MANAGER,
  ROLE_PROJECT_OWNER,
  ROLE_SUPERVISOR,
  ROLE_SUPER_ADMIN,
  ROLE_TIMESHEETS_ADMIN,
} from "../../SharedModule/utils/constants";

export const empty: SelectItemNumber = {
  value: 0,
  label: "—",
};

export const emptyProjectWorkOrder: SelectItemNumber = {
  value: 0,
  label: "Select a project, task or work order you are assigned to",
};

// Param is array of roles only for time module
export const hasTimeAdminAccess = (userRoles: string[]) => {
  let response: boolean = false;
  userRoles?.forEach((role: string) => {
    if (
      role === ROLE_SUPER_ADMIN ||
      role === ROLE_TIMESHEETS_ADMIN ||
      role === ROLE_SUPERVISOR ||
      role === ROLE_PROJECT_OWNER
    ) {
      response = true;
    }
  });
  return response;
};

export const isPriceManager = (userRoles: string[]) => {
  let response: boolean = false;
  userRoles?.forEach((role: string) => {
    if (role === ROLE_PRICE_MANAGER) {
      response = true;
    }
  });
  return response;
};

export const getEmptyTimeEntry = () => {
  return {
    entryCanEdit: true,
    entryCanDelete: true,
    entryId: newUuid(),
    entryDate: new Date().toISOString(),
    entryDateValid: true,
    entryProjectWorkOrder: emptyProjectWorkOrder,
    entryProjectWorkOrderValid: false,
    entryHours: "",
    entryHoursValid: false,
    entryTaskType: empty,
    entryTaskTypeValid: false,
    entryDescription: "",
    entryDescriptionValid: false,
    entryIsBillable: true,
    entryNonBillableReason: empty,
    entryNonBillableReasonValid: false,
    isEntryValid: false,
    isEntryTouched: false,
  };
};

export const getCategoryId = (list: any, value: number) => {
  let selected = list.find((elem) => elem.entityId === value);
  return selected.categoryId;
};

export const getProjectWorOrder = (list: any, value: number) => {
  let selected = list.find((elem) => elem.entityId === value);
  return selected;
};

export const getValueFromSelect = (list: any, value: number | null) => {
  let selected = list.find((elem) => elem.value === value);
  return selected ? selected : null;
};

export const getValueFromSelectWithLabel = (list: any, label: string) => {
  let selected = list.find((elem) => elem.label === label);
  return selected;
};

export const getPrevSunday = (pageDate: Date) => {
  let actualDate = new Date(
    pageDate.getFullYear(),
    pageDate.getMonth(),
    pageDate.getUTCDate(),
    3,
    0,
    0
  );
  return new Date(
    actualDate.setDate(actualDate.getUTCDate() - actualDate.getDay())
  );
};

// get next day from sunday
export const getNextWeekDay = (days: number, pageDate: Date) => {
  let sunday = getPrevSunday(pageDate);
  let actualDate = new Date(
    sunday.getFullYear(),
    sunday.getMonth(),
    sunday.getUTCDate(),
    3,
    0,
    0
  );
  return new Date(actualDate.setDate(actualDate.getUTCDate() + days));
};

// days will be number to discount
export const getLastWeekDay = (days: number, pageDate: Date) => {
  let sunday = getPrevSunday(pageDate);
  let actualDate = new Date(
    sunday.getFullYear(),
    sunday.getMonth(),
    sunday.getUTCDate(),
    3,
    0,
    0
  );
  return new Date(actualDate.setDate(actualDate.getUTCDate() - days));
};

export const camelCaseToWords = (s: string) => {
  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const firstLetterToLower = (word: string) => {
  return word.charAt(0).toLowerCase() + word.slice(1);
};

export const transformValue = (property: string, value: any) => {
  let response;

  switch (property) {
    case "costCenters":
    case "clients":
    case "projects":
    case "projectManagers":
    case "users":
    case "taskTypes":
    case "countries":
    case "nonBillableReasons":
      if (value === null) {
        response = "All " + camelCaseToWords(property);
      } else {
        value.forEach((element) => {
          if (!response) {
            response = element.label;
          } else {
            response = response + ", " + element.label;
          }
        });
      }
      break;
    case "hourType":
    case "timeOff":
    case "approvalStatus":
      for (const prop in value) {
        if (value[prop]) {
          if (!response) {
            response = camelCaseToWords(prop);
          } else {
            response = response + ", " + camelCaseToWords(prop);
          }
        }
      }
      break;
    default:
      break;
  }
  return response;
};

export const preparedFilters = (filters: any) => {
  // projectManagers
  let projectManagerInfo = [];
  if (filters.projectManagers) {
    filters.projectManagers.forEach((proj) => {
      projectManagerInfo = projectManagerInfo.concat(proj.projectIds);
    });
  }
  // hourType
  let hourTypeInfo: number[] = [];
  if (filters.hourType.billableHours) hourTypeInfo.push(1);
  if (filters.hourType.internalHours) hourTypeInfo.push(2);
  if (filters.hourType.nonBillableHours) hourTypeInfo.push(3);
  // timeOff
  let timeOffInfo: number[] = [];
  if (filters.timeOff.paidTimeOff) timeOffInfo.push(1);
  if (filters.timeOff.unpaidTimeOff) timeOffInfo.push(2);
  // approvalStatus
  let approvalStatusInfo: number[] = [];
  if (filters.approvalStatus.approvedHours) approvalStatusInfo.push(1);
  if (filters.approvalStatus.lockedHours) approvalStatusInfo.push(2);
  if (filters.approvalStatus.pendingHours) approvalStatusInfo.push(3);

  let response: FiltersBody = {
    startDate: filters.startDate,
    endDate: filters.endDate,
    costCentersIds:
      filters.costCenters === null
        ? null
        : filters.costCenters.map((elem) => elem.value),
    clientsIds:
      filters.clients === null
        ? null
        : filters.clients.map((elem) => elem.value),
    entities: null,
    managersProjects:
      filters.projectManagers === null ? null : projectManagerInfo,
    usersIds:
      filters.users === null ? null : filters.users.map((elem) => elem.value),
    taskTypesIds:
      filters.taskTypes === null
        ? null
        : filters.taskTypes.map((elem) => elem.value),
    countries:
      filters.countries === null
        ? null
        : filters.countries.map((elem) => elem.value),
    hourTypes: hourTypeInfo.length > 0 ? hourTypeInfo : null,
    nonBillableReasonsIds:
      filters.nonBillableReasons === null
        ? null
        : filters.nonBillableReasons.map((elem) => elem.value),
    timeOff: timeOffInfo.length > 0 ? timeOffInfo : null,
    statusIds: approvalStatusInfo.length > 0 ? approvalStatusInfo : null,
  };
  return response;
};

export const navigateTo = (entry: EntryListView) => {
  let url: string = "";
  switch (entry.categoryId) {
    case 1:
      //project
      url = `${process.env.REACT_APP_ASUITE_PROJECT_URI}${entry.entityId}`;
      break;
    case 2:
      // task
      url = `${process.env.REACT_APP_ASUITE_TASK_URI}${entry.entityId}&project=${entry.projectId}`;
      break;
    default:
      // work order = 3
      url = `${process.env.REACT_APP_ASUITE_WO_URI}${entry.entityId}`;
      break;
  }
  window.open(url, "_blank");
};

// creates an object, filters by any property
export const groupByProperty = (array, property) => {
  let hash = {};

  for (let elem of array) {
    if (!hash[elem[property]]) hash[elem[property]] = [];
    hash[elem[property]].push(elem);
  }
  return hash;
};
