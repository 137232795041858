import { useCallback, useEffect, useState } from "react";
import "../../../styles/main.scss";
import { ExternalService } from "../../services/external";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleSelection,
  deselectAll,
  loadDetail,
  selectAll,
  selectOrderedBy,
} from "./../../redux/reducers/billableHours.reducer";
import {
  currencyFormat,
  dateFormat,
  timeAmountFormat,
} from "../../../SharedModule/utils/formatters";
import { TimeEntryDetail } from "../../../types";
import { DateTime } from "luxon";
import { Spinner } from "../../../SharedModule/components/Spinner";

interface BillableHoursTableProps {
  uuid: string;
  id: number;
  isProject: boolean;
  accountId: number;
  dateFrom: string;
  dateTo: string;
  employeeIds?: number[];
  invoiceOwners?: number[];
  billingCycleIds?: number[];
  includeWoAttachedToProject: boolean;
}

const BillableHoursTable = ({
  uuid,
  id,
  isProject,
  accountId,
  ...filters
}: BillableHoursTableProps) => {
  const dispatch = useDispatch();

  const [sort, setSort] = useState<{
    orderBy: keyof TimeEntryDetail;
    criteria: "asc" | "desc";
    dataType: "string" | "date";
  }>({
    orderBy: "dateWorked",
    criteria: "desc",
    dataType: "date",
  });

  const detail = useSelector(
    selectOrderedBy(uuid, sort.orderBy, sort.criteria, sort.dataType)
  );

  const loadData = useCallback(async () => {
    const { dateFrom, dateTo, ...otherFilters } = filters;

    const from = DateTime.fromISO(dateFrom);
    const fromUTC = DateTime.utc(
      from.year,
      from.month,
      from.day,
      from.hour,
      from.minute,
      from.second
    );

    const to = DateTime.fromISO(dateTo);
    const toUTC = DateTime.utc(
      to.year,
      to.month,
      to.day,
      to.hour,
      to.minute,
      to.second
    );

    const body = isProject
      ? {
          ...otherFilters,
          projectIds: [id],
          accountIds: [accountId],
          dateFrom: fromUTC.toString(),
          dateTo: toUTC.toString(),
        }
      : {
          ...otherFilters,
          workOrderIds: [id],
          accountIds: [accountId],
          dateFrom: fromUTC.toString(),
          dateTo: toUTC.toString(),
        };

    const data = await ExternalService.getTimeEntriesDetail(body);
    dispatch(loadDetail({ data, uuid }));
  }, [dispatch, filters, id, accountId, isProject, uuid]);

  useEffect(() => {
    if (!!detail) return;
    loadData();
  }, []);

  const sortBy = (
    orderBy: keyof TimeEntryDetail,
    dataType: "string" | "date"
  ) => {
    let criteria: "asc" | "desc" = "asc";
    if (sort.orderBy === orderBy) {
      criteria = sort.criteria === "asc" ? "desc" : "asc";
    }
    setSort({
      orderBy,
      criteria,
      dataType,
    });
  };

  return (
    <table className="table caption-top white-table-header-background">
      <thead className="align-middle">
        <tr className="sticky sticky-with-project">
          <th className="small fw-normal text-center border-dark border-top column-10">
            Select
            <div className="d-flex justify-content-center">
              <div
                className="link-text mx-2"
                onClick={() => detail && dispatch(selectAll(uuid))}
              >
                All
              </div>
              /
              <div
                className="link-text mx-2"
                onClick={() => detail && dispatch(deselectAll(uuid))}
              >
                None
              </div>
            </div>
          </th>
          <th
            className={`border-dark border-top fw-normal pointer column-15 ${
              sort.orderBy === "employeeName" ? "sorted-" + sort.criteria : ""
            }`}
            onClick={() => sortBy("employeeName", "string")}
          >
            User
          </th>
          <th
            className={`border-dark border-top fw-normal pointer column-15 ${
              sort.orderBy === "dateWorked" ? "sorted-" + sort.criteria : ""
            }`}
            onClick={() => sortBy("dateWorked", "date")}
          >
            Date
          </th>
          <th className="border-dark border-top fw-normal column-30 ">Notes</th>
          <th className="border-dark border-top fw-normal text-end column-10 ">
            Hours
          </th>
          <th className="border-dark border-top fw-normal text-end column-10 ">
            Rate
          </th>
          <th className="border-dark border-top fw-normal text-end column-10 ">
            Total Price
          </th>
        </tr>
      </thead>
      <tbody>
        {detail && detail.length > 0 ? (
          detail.map((el: any) => {
            if (!el) return null;
            return (
              <tr key={el.id}>
                <td className="text-center pb-0">
                  <div className="form-check d-inline-block">
                    <input
                      className="form-check-input"
                      id="hours"
                      type="checkbox"
                      checked={el.selected}
                      onChange={() => {
                        dispatch(
                          toggleSelection({ parentId: uuid, id: el.id })
                        );
                      }}
                    />
                  </div>
                </td>
                <td>{el.employeeName}</td>
                <td>{(el.dateWorked && dateFormat(el.dateWorked)) || ""}</td>
                <td style={{ width: "30%", wordBreak: "break-word" }}>
                  {el.description}
                </td>
                <td className="fw-bold text-end">
                  {timeAmountFormat(el.hours || 0)}
                </td>
                <td className="fw-bold text-end">
                  {currencyFormat(el.unitPrice || 0)}
                </td>
                <td className="fw-bold text-end">
                  {currencyFormat(el.amount || 0)}
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <Spinner
              style={{
                marginTop: "5%",
              }}
            />
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default BillableHoursTable;
