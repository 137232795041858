import { dateFormatWithHours } from "../../SharedModule/utils/formatters";

export const ModalMoreInfoTimeEntry = (props) => {
  return (
    <>
      <div className="row">
        <h4>Time entry details</h4>
      </div>
      {props.actions &&
        props.actions.map((action: any) => {
          return (
            <>
              <hr style={{ width: "100%" }} />
              <div className="row">
                <div className="modal-info-action">{action.action}</div>
                <div className="modal-info-action-text mt-2">
                  {action.userName} on{" "}
                  {dateFormatWithHours(action.entryDateEst)} (ET)
                </div>
              </div>
            </>
          );
        })}
      <hr style={{ width: "100%" }} />
      <div className="mt-3 d-flex justify-content-end">
        <button className="btn btn-primary w-25" onClick={props.btnCancel}>
          Close
        </button>
      </div>
    </>
  );
};
