import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UsersService } from "../../../SharedModule/services/users";
import { ExternalService } from "../../../BillingModule/services/external";
import {
  KeyValuePair,
  Project,
  SelectItem,
  Department,
  User,
  WorkOrder,
} from "../../../types";
import { formatForDropdowns } from "../../../SharedModule/utils/formatters";

export const fetchMeta = async () => {
  const [users, asuiteOwners, workOrders, departments, ...rest] = await Promise.all([
    UsersService.getUsers(),
    ExternalService.getInvoiceOwners(),
    ExternalService.getWorkOrders(),
    ExternalService.getDepartments(),
    ExternalService.getBillingCycles(),
    ExternalService.getProjects(),
    ExternalService.getClients()
  ]);

  const [billingCycles, projects, clients] = rest.map((metaItem) => {
      return metaItem.map(formatForDropdowns<KeyValuePair | Project>("name"))
    }
  );

  return {
    invoiceOwners:
      (asuiteOwners &&
        asuiteOwners.map((owner) => ({
          value: owner.id,
          label: `${owner.lastName} ${owner.firstName}`,
        }))) ||
      [],
    workOrders: workOrders.map(
      formatForDropdowns<WorkOrder>("shortDescription")
    ),
    users,
    billingCycles,
    projects,
    clients,
    departments
  };
};

// Users could be moved to a shared metaState (Shared module) if we split the hub in diff reacts app
// we keep users here to avoid restructure "selectAdvancedFiltersOptions" and avoid to duplicate users info (UsersService.getUsers)
export type MetaState = {
  billingCycles: Array<SelectItem> | null;
  clients: Array<SelectItem> | null;
  projects: Array<SelectItem> | null;
  workOrders: Array<SelectItem> | null;
  invoiceOwners: Array<SelectItem> | null;
  departments: Array<Department> | null;
  users: Array<User> | null;
  loaded: boolean;
};

type SetItem = Partial<MetaState>;

const initialState: MetaState = {
  billingCycles: null,
  clients: null,
  projects: null,
  workOrders: null,
  invoiceOwners: null,
  departments: null,
  users: null,
  loaded: false,
};

export const metaSlice = createSlice({
  name: "meta",
  initialState,
  reducers: {
    // Remember Redux Toolkit allows us to write "mutating" logic in reducers.
    setMeta: (state: MetaState, action: PayloadAction<SetItem>) => {
      return { ...state, ...action.payload, loaded: true };
    }
  },
});

export const { setMeta } = metaSlice.actions;

// Selectors
export const selectMetaLoaded = ({ meta }: { meta: MetaState }) => meta.loaded;

export const selectBillingCycles = ({ meta }: { meta: MetaState }) =>
  meta.billingCycles;

export const selectUsers = ({ meta }: { meta: MetaState }) =>
  (meta.users &&
    meta.users.map((user) => ({
      value: user.id,
      label: user.fullName,
    }))) ||
  [];

export const selectClients = ({ meta }: { meta: MetaState }) => meta.clients;

// helper to indent sub-deparments
const setLeftIndent = (dept: any) => {
  return '-'.repeat(dept.level) + dept.name;
}

export const selectAdvancedFiltersOptions = createSelector(
  [(state: { meta: MetaState }) => state.meta],
  (meta) => ({
    ...meta,
    users:
      meta.users &&
      meta.users.map((user) => ({
        value: user.asuiteId,
        label: user.fullName,
      })),
    projectsOrWO: [
      {
        label: "Projects",
        options: meta.projects?.map((item) => ({
          ...item,
          isProject: true,
        })),
      },
      {
        label: "Work Orders",
        options: meta.workOrders,
      },
    ],
    departments:[
      {
        label: "Departments",
        options: meta.departments?.map((department) => ({
          value: department.id,
          label: setLeftIndent(department),
          level: department.level,
          path: department.path,
          parentId: department.parentId
        })),
      }
    ],
  })
);

export const selectRecurringOptions = (state: { meta: MetaState }) => {
  return {
    loaded: selectMetaLoaded(state),
    billingCycles: selectBillingCycles(state),
    clients: selectClients(state),
  };
};

export default metaSlice.reducer;
