import { useRef, useState } from "react";
import useOnClickOutside from "../../SharedModule/hooks/useOnClickOutside";
import {
  APPROVED_TEXT,
  LOCKED,
  PENDING_TEXT,
} from "../../SharedModule/utils/constants";

export const ActionsButton = ({
  disabled,
  entriesSelected,
  showActionNonBillaleModal,
  showActionChangeDateModal,
  showActionChangeProjectModal,
  updateEntriesSelected,
}: any) => {
  const [actionsOpen, setActionsOpen] = useState(false);
  const dropdownRef: any = useRef();

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(dropdownRef, () => {
    setActionsOpen(false);
  });

  const disabledBillable = () => {
    return (
      entriesSelected.some((e) => e.timeEntryTypeId === 1) ||
      entriesSelected.some((e) => e.status === APPROVED_TEXT) ||
      entriesSelected.some((e) => e.status === LOCKED)
    );
  };

  const disabledNonBillable = () => {
    return (
      entriesSelected.some((e) => e.timeEntryTypeId !== 1) ||
      entriesSelected.some((e) => e.status === APPROVED_TEXT) ||
      entriesSelected.some((e) => e.status === LOCKED)
    );
  };

  const disabledApproveTime = () => {
    return (
      entriesSelected.some((e) => e.status === APPROVED_TEXT) ||
      entriesSelected.some((e) => e.status === LOCKED) ||
      entriesSelected.some((e) => e.categoryId === 3)
    );
  };

  const disabledUnapproveTime = () => {
    return (
      entriesSelected.some((e) => e.status === PENDING_TEXT) ||
      entriesSelected.some((e) => e.status === LOCKED) ||
      entriesSelected.some((e) => e.categoryId === 3)
    );
  };

  const disabledMove = () => {
    return (
      entriesSelected.some((e) => e.status === APPROVED_TEXT) ||
      entriesSelected.some((e) => e.status === LOCKED)
    );
  };


  return (
    <span className="position-relative">
      <button
        className={`btn button-secondary dropdown-toggle ${
          disabled && "disabled"
        }`}
        type="button"
        onClick={() => setActionsOpen((state) => !state)}
      >
        Actions
      </button>
      <div
        className={`dropdown-menu ${actionsOpen ? "show" : ""}`}
        ref={dropdownRef}
      >
        <button
          className="dropdown-item"
          onClick={() => updateEntriesSelected(1, null)}
          disabled={disabledBillable()}
        >
          Make Billable
        </button>
        <button
          className="dropdown-item"
          onClick={showActionNonBillaleModal}
          disabled={disabledNonBillable()}
        >
          Make Non-Billable
        </button>
        <hr className="separator-actions" />
        <button
          className="dropdown-item"
          onClick={() => updateEntriesSelected(3, null)}
          disabled={disabledApproveTime()}
        >
          Approve Time
        </button>
        <button
          className="dropdown-item"
          onClick={() => updateEntriesSelected(4, null)}
          disabled={disabledUnapproveTime()}
        >
          Unapprove Time
        </button>
        <hr className="separator-actions" />
        <button
          className="dropdown-item"
          onClick={showActionChangeDateModal}
          disabled={disabledMove()}
        >
          Move Date
        </button>
        <button
          className="dropdown-item"
          onClick={showActionChangeProjectModal}
          disabled={disabledMove()}
        >
          Move to Project
        </button>
      </div>
    </span>
  );
};
