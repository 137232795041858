import { useEffect, useState, useRef, useCallback } from "react";
import { ModalLayout } from "../components/ModalLayout";
import { ModalPortal } from "../components/ModalPortal";
import { useDispatch } from "react-redux";
import { SecurityService } from "../../SharedModule/services/security";
import { AccountInfo } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { login, logout, setUserRoles } from "../redux/reducers/auth.reducer";
import { useNavigate, useLocation } from "react-router-dom";
import eventHanlder from "../utils/eventHanlder";

const eventTypes = [
  "keypress",
  "mousemove",
  "mousedown",
  "scroll",
  "touchmove",
  "pointermove",
];
export const addEventListeners = (listener: any) => {
  eventTypes.forEach((type) => {
    window.addEventListener(type, listener, false);
  });
};
export const removeEventListeners = (listener: any) => {
  if (listener) {
    eventTypes.forEach((type) => {
      window.removeEventListener(type, listener, false);
    });
  }
};

export const TimeoutLogic = () => {
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  const location = useLocation();
  const [, setStatus] = useState({ loaded: false, auth: false });

  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Modal config
  const modalRef = useRef();
  const showModal = () => {
    (modalRef.current as any).show(true);
  };
  const closeModal = () => {
    if (modalRef.current as any) {
      (modalRef.current as any).show(false);
    }
  };

  useEffect(() => {
    localStorage.setItem("lastPageVisited", location.pathname);
    const createTimeout = () =>
      setTimeout(() => {
        if (!isModalOpen) {
          eventHanlder.dispatch("logout", {});
          showModal();
          dispatch(logout());
          setIsModalOpen(true);
        }
        // set time in miliseconds to force logout and show modal => 1800000
      }, 1800000);

    const listener = () => {
      if (!isModalOpen) {
        clearTimeout(timer);
        timer = createTimeout();
      }
    };
    // init
    let timer = createTimeout();
    addEventListeners(listener);
    // Cleanup
    return () => {
      removeEventListeners(listener);
      clearTimeout(timer);
    };
  }, [isModalOpen]);

  const getRoles = useCallback(async () => {
    const userRoles = await SecurityService.getUserRole();
    Array.isArray(userRoles) && dispatch(setUserRoles(userRoles));
    setStatus({
      loaded: true,
      auth: Array.isArray(userRoles) && userRoles.length !== 0,
    });
  }, [dispatch]);

  const loginAgain = () => {
    closeModal();
    instance
      .acquireTokenSilent({
        scopes: ["api://894e8b20-1b64-479c-b4a6-6e4fd7e1757f/Billing.Access"],
        account: accounts[0] as AccountInfo,
      })
      .then((response) => {
        dispatch(
          login({
            accessToken: response.accessToken,
            activeUserId: accounts[0].localAccountId as string,
            activeUserName: (accounts[0] as any).name as string,
            activeUserEmail: accounts[0].username as string,
          })
        );
        getRoles();
        eventHanlder.dispatch("login", {});

        let pageInCache: any = localStorage.getItem("lastPageVisited");
        navigate(pageInCache);
      });
    setIsModalOpen(false);
  };

  return (
    <ModalPortal ref={modalRef} zIndex={170}>
      <ModalLayout
        title="Your session has timed out"
        text="Click below to Log in again and go back to the page you were on. If we can't bring you back, you will be taken to the Home page."
        btnLabel="Log in again"
        btnAction={loginAgain}
        actionPlaceholder="notCloseOnClickOutside"
      />
    </ModalPortal>
  );
};
