import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TimesheetsService } from "../../services/timesheets";
import { SelectItem, UserAssignees } from "../../../types";
import {
  Client,
  NonBillableReason,
  Project,
  ProjectManagerOrUser,
  TaskType,
} from "../../types";
import { UsersService } from "../../../SharedModule/services/users";

const addElementToArray = (arrayToAdd: any, arrayToLoop: any) => {
  arrayToLoop.forEach((elem) => {
    arrayToAdd.push({
      value: elem.id,
      label: elem.name,
      level: elem.level,
    });
    if (elem.children && elem.children.length > 0) {
      addElementToArray(arrayToAdd, elem.children);
    }
  });
};

export const fetchMeta = async (id: string) => {
  const [userAssignees, taskTypesData, nonBillableReasonsData, filtersData] =
    await Promise.all([
      UsersService.getUserAssignees(id),
      TimesheetsService.getTaskTypes(),
      TimesheetsService.getNonBillableReasons(),
      TimesheetsService.getQueryFilters(),
    ]);

  let costCentersData: Array<SelectItem> = [];
  if (filtersData && filtersData.data.costCenters) {
    addElementToArray(costCentersData, filtersData.data.costCenters);
  }

  return {
    userAssignees:
      (userAssignees &&
        userAssignees.data.map((assignee: UserAssignees) => ({
          value: assignee.id,
          label: assignee.fullName,
        }))) ||
      [],
    taskTypes:
      (taskTypesData &&
        taskTypesData.data.map((task: TaskType) => ({
          value: task.taskTypeId,
          label: task.taskTypeName,
        }))) ||
      [],
    nonBillableReasons:
      (nonBillableReasonsData &&
        nonBillableReasonsData.data.map((elem: NonBillableReason) => ({
          value: elem.id,
          label: elem.name,
        }))) ||
      [],
    costCenters: costCentersData || null,
    clients:
      (filtersData.data &&
        filtersData.data.clients.map((elem: Client) => ({
          value: elem.id,
          label: elem.name,
        }))) ||
      null,
    timeProjects:
      (filtersData.data &&
        filtersData.data.projects.map((elem: Project) => ({
          value: elem.id,
          label: elem.name,
          // to review
          // entityId: elem.entityId,
          // categoryId: elem.categoryId,
        }))) ||
      null,
    projectManagers:
      (filtersData.data &&
        filtersData.data.managers.map((elem: ProjectManagerOrUser) => ({
          value: elem.id,
          label: elem.fullName,
          projectIds: elem.projectIds,
        }))) ||
      null,
    timeUsers:
      (filtersData.data &&
        filtersData.data.timeUsers.map((elem: ProjectManagerOrUser) => ({
          value: elem.id,
          label: elem.fullName,
          isActive: elem.isActive,
        }))) ||
      null,
    countries:
      (filtersData.data &&
        filtersData.data.countries.map((elem: string) => ({
          value: elem,
          label: elem,
        }))) ||
      null,
  };
};

export type MetaState = {
  userAssignees: Array<SelectItem> | null;
  taskTypes: Array<SelectItem> | null;
  nonBillableReasons: Array<SelectItem> | null;
  costCenters: Array<any> | null;
  clients: Array<any> | null;
  timeProjects: Array<any> | null;
  projectManagers: Array<any> | null;
  timeUsers: Array<any> | null;
  countries: Array<any> | null;
  loaded: boolean;
};

type SetItem = Partial<MetaState>;

const initialState: MetaState = {
  userAssignees: null,
  taskTypes: null,
  nonBillableReasons: null,
  costCenters: null,
  clients: null,
  timeProjects: null,
  projectManagers: null,
  timeUsers: null,
  countries: null,
  loaded: false,
};

export const metaSlice = createSlice({
  name: "meta",
  initialState,
  reducers: {
    // Remember Redux Toolkit allows us to write "mutating" logic in reducers.
    setMeta: (state: MetaState, action: PayloadAction<SetItem>) => {
      return { ...state, ...action.payload, loaded: true };
    },
  },
});

export const { setMeta } = metaSlice.actions;

// Selectors
export const selectMetaLoaded = ({ meta }: { meta: MetaState }) => meta.loaded;

export const selectUserAssignees = ({ meta }: { meta: MetaState }) =>
  meta.userAssignees;

export const selectTaskTypes = ({ meta }: { meta: MetaState }) =>
  meta.taskTypes;

export const selectNonBillableReasons = ({ meta }: { meta: MetaState }) =>
  meta.nonBillableReasons;

export const selectCostCenters = ({ meta }: { meta: MetaState }) =>
  meta.costCenters;

export const selectClients = ({ meta }: { meta: MetaState }) => meta.clients;

export const selectTimeProjects = ({ meta }: { meta: MetaState }) => meta.timeProjects;

export const selectProjectManagers = ({ meta }: { meta: MetaState }) =>
  meta.projectManagers;

export const selecttimeUsers = ({ meta }: { meta: MetaState }) => meta.timeUsers;

export const selectCountries = ({ meta }: { meta: MetaState }) =>
  meta.countries;

export const selectMetaTimesData = (state: { meta: MetaState }) => {
  return {
    loaded: selectMetaLoaded(state),
    userAssignees: selectUserAssignees(state),
    taskTypes: selectTaskTypes(state),
    nonBillableReasons: selectNonBillableReasons(state),
    costCenters: selectCostCenters(state),
    clients: selectClients(state),
    timeProjects: selectTimeProjects(state),
    projectManagers: selectProjectManagers(state),
    timeUsers: selecttimeUsers(state),
    countries: selectCountries(state),
  };
};

export default metaSlice.reducer;
