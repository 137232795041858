import { LeftNavBar } from "../../SharedModule/components/LeftNavBar";
import { LeftNavBarSection } from "../../SharedModule/components/LeftNavBarSection";

export const LeftNavBarAdmin = (props:any) => {
    return (
        <LeftNavBar>
          <LeftNavBarSection
            mainTitle="The Hub Admin"
            biIconName="BiListUl"
            loadingStatus={props.isLoading}
            listOfLinks={[]}
          />
        </LeftNavBar>
    );
}