import { useDispatch } from "react-redux";
import { addToast } from "../../SharedModule/redux/reducers/ui.reducer";
import { ProductsService } from "../services/products";
import eventHanlder from "../../SharedModule/utils/eventHanlder";

export type deleteProductPayload = {
  productId: number;
};

export const useDeleteProduct = (
  showModal: (options: any) => Promise<void>,
  closeModal: () => void,
  isShowingActives: boolean,
  loadProducts: Function
) => {
  const dispatch = useDispatch();
  const deleteProduct = async (payload: deleteProductPayload) => {
    const options = {
      title: `${isShowingActives ? "Deactivate" : "Reactivate"} Product?`,
      text: `Are you sure you want to ${
        isShowingActives ? "deactivate" : "reactivate"
      } this product?`,
      btnLabel: `Yes, ${
        isShowingActives ? "Deactivate" : "Reactivate"
      } Product`,
    };
    await showModal(options);
    closeModal();

    let result;
    if (isShowingActives) {
      result = await ProductsService.setInactiveProduct(payload.productId);
      if (result === "Product deactivated successfully."){
        dispatch(addToast({ mode: "success", message: result }));
        eventHanlder.dispatch("toast action", { isClosed: false });
        loadProducts();
        return false;
      } else {
        dispatch(addToast({ mode: "error", message: "Something went wrong" }));
      }
    } else {
      result = await ProductsService.setActiveProduct(payload.productId);
      if (result === "Product activated successfully.") {
        dispatch(addToast({ mode: "success", message: result }));
        eventHanlder.dispatch("toast action", { isClosed: false });
        loadProducts();
        return true;
      } else {
        dispatch(addToast({ mode: "error", message: "Something went wrong" }));
      }
    }
    eventHanlder.dispatch("toast action", { isClosed: false });
  };

  return deleteProduct;
};
