import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useEffect } from "react";
import image from "./../../styles/legacy/404.png";
import { NoContent } from "../../BillingModule/components/NoContent";
import { useDispatch } from "react-redux";
import { logout } from "../redux/reducers/auth.reducer";
import eventHanlder from "../utils/eventHanlder";
import { useNavigate } from "react-router-dom";
import { ROUTE_HOME } from "../utils/constants";

export const LogOutPage = () => {
  const { trackPageView } = useMatomo();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  dispatch(logout());

  useEffect(() => {
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });
  }, []);

  const LogIn = async () => {
    eventHanlder.dispatch("authLogin", {});
    navigate(ROUTE_HOME);
  };

  return (
    <NoContent
      title="Logout Successful"
      text="You have been securely logged out of your account."
      action={() => LogIn()}
      image={image}
      style={{ margin: "3% auto 0 auto" }}
      textButton="Log in"
      conditionButton={true}
    />
  );
};
