import ReactSelect from "react-select";

const customStyles = {
  option: (provided, state) => {
    // this prop is for hierarchy in select options - we should use 'level' as value to check
    let paddingCalculatedValue = "4px 6px";
    if (state.data.level > 0) {
      paddingCalculatedValue = `4px 6px 4px ${6 + 15 * state.data.level}px`;
    }
    return {
      ...provided,
      color: state.isSelected ? "white" : "black",
      padding: paddingCalculatedValue,
    };
  },
  control: (provided, state) => ({
    ...provided,
    minHeight: 31,
    border: "1px solid #8B90AA",
    color: "#2F2D31",
    "&:hover": {
      borderColor: "transparent",
      boxShadow: "0 0 0 2px #6357BB !important",
    },
    "&:active": {
      borderColor: "transparent",
      boxShadow: "0 0 0 2px #6357BB !important",
      backgroundColor: "#EEECF5",
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#0e0e0e",
    marginLeft: 4,
  }),
  multiValue: (provided, state) => ({
    ...provided,
    border: "1px solid #979797",
    borderRadius: "4px",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    fontSize: "13px",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    opacity: 0.3,
    marginTop: 2,
    "&:hover": {
      backgroundColor: "transparent",
      color: "black",
      opacity: 1,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "6px 10px",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    paddingRight: 5,
  }),
  indicatorSeparator: (provided) => ({}),
};

const customTheme = (theme) => ({
  ...theme,
  borderRadius: 4,
  spacing: {
    ...theme.spacing,
    baseUnit: 2,
    controlHeight: 31,
  },
  colors: {
    ...theme.colors,
    primary: "#6357BB",
    primary25: "#e6e6e6",
    primary50: "rgb(102 86 193 / 25%)",
  },
});
const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

export const Select = ({
  options,
  defaultSelection = null,
  onChange,
  value,
  isDisabled,
  placeholder = "All",
  ...props
}) => {
  let showValue = value;

  const internalOnChange = (value) => {
    if (!defaultSelection) return onChange(value);
    // If nothing is selected, it returns null
    if (value.length === 0) return onChange(null);
    const newValue = value.filter((f) => f.value !== defaultSelection);
    onChange(newValue);
  };

  if (!value && defaultSelection) {
    showValue = [{ value: defaultSelection, label: defaultSelection }];
  }

  return (
    <ReactSelect
      options={options}
      styles={customStyles}
      theme={customTheme}
      formatGroupLabel={formatGroupLabel}
      value={showValue}
      onChange={internalOnChange}
      placeholder={placeholder}
      isDisabled={isDisabled ? isDisabled : false}
      filterOption={(element, filter) =>
        element.label
          ?.toLowerCase()
          .indexOf(filter.toString()?.toLowerCase()) !== -1
      }
      {...props}
    />
  );
};
