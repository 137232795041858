import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Calendar } from "../../SharedModule/components/Calendar";
import { selectRecurringOptions } from "../redux/reducers/meta.reducer";
import "../../styles/main.scss";
import { FiltersSelect } from "../components/ProcessBillableItems/AdvancedFiltersSelect";
import {
  setItem,
  resetFiltersRecurring as resetFiltersAction,
  selectRecurringFilters,
  setupRecurring,
} from "../redux/reducers/filters.reducer";
import { clear as clearBillableHours } from "../redux/reducers/billableHours.reducer";
import { clearBillableItems } from "../redux/reducers/billableItems.reducer";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import {
  ROUTE_BILLABLE_ITEMS_RESULTS,
  ROUTE_RECURRING_QUERY,
} from "../../SharedModule/utils/constants";
import { setActiveTab } from "../../ProductsModule/redux/reducers/ui.reducer";
import Footer from "../../SharedModule/components/Footer";

export const RecurringQuery = () => {
  const dispatch = useDispatch();
  const recurringOptions = useSelector(selectRecurringOptions);
  const filtersState = useSelector(selectRecurringFilters);
  const location = useLocation();
  const navigate = useNavigate();
  const { trackPageView } = useMatomo();

  useEffect(() => {
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });
  }, []);

  useEffect(() => {
    if (location.hash !== "#keep") {
      dispatch(setupRecurring());
    }
    dispatch(clearBillableHours());
    dispatch(clearBillableItems());

    dispatch(setActiveTab(ROUTE_RECURRING_QUERY));
  }, [location.hash, dispatch]);

  const resetFilters = (event: any) => {
    event.preventDefault();
    dispatch(resetFiltersAction());
  };

  const runQuery = async (event: any) => {
    event.preventDefault();
    navigate(ROUTE_BILLABLE_ITEMS_RESULTS);
  };

  return (
    <div className="content">
      <div className="content-header" style={{ marginTop: "0" }}>
        <h2>Process Recurring Billing</h2>
      </div>
      <form className="card invoice-summary-background p-4 mt-3">
        <div className="row justify-content-center">
          <label className="col-sm-3 col-form-label">Posting Date</label>
          <div className="col-sm-5">
            <div className="d-flex">
              <div className="position-relative">
                <Calendar
                  date={filtersState.postingDate}
                  onChange={(date: string) =>
                    dispatch(setItem({ postingDate: date.toString() }))
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <FiltersSelect
          className="row justify-content-center mt-4"
          label="Billing cycles"
          options={recurringOptions.billingCycles}
          value={filtersState.billingCycles}
          isLoading={!recurringOptions.billingCycles}
          onChange={(billingCycles) => {
            dispatch(setItem({ billingCycles }));
          }}
          isDisabled={false}
        />
        <FiltersSelect
          className="row justify-content-center mt-4"
          label="Clients"
          options={recurringOptions.clients}
          value={filtersState.clients}
          isLoading={!recurringOptions.loaded}
          onChange={(clients: any) => dispatch(setItem({ clients }))}
          isDisabled={false}
        />
        <hr className="separator" />
        <div className="footer-filter mt-4">
          <div className="link-text" onClick={resetFilters}>
            Reset Filters
          </div>
          <button className="btn btn-primary" onClick={runQuery}>
            Run Query
          </button>
        </div>
      </form>
      <Footer />
    </div>
  );
};
