import { Radio } from "../../SharedModule/components/Radio";
import "../../styles/main.scss";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  clear,
  resetFilters,
  resetFiltersWithoutOwner,
  selectFilters,
  setFilters,
} from "../redux/reducers/invoices.reducer";
import {
  ROUTE_INVOICES_LIST,
  ROUTE_INVOICES_SEARCH,
  ROLE_NOT_OWNERS,
} from "../../SharedModule/utils/constants";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useIsAllowed } from "../../SharedModule/components/AccessControl";
import Footer from "../../SharedModule/components/Footer";

enum IdTypes {
  Internal,
  Quickbooks,
}

const regex = /^[\d,-\s]*\d+$/;

export const InvoiceSearch = () => {
  const dispatch = useDispatch();
  const filters = useSelector(selectFilters, shallowEqual);

  const [selectedIdType, setSelectedIdType] = useState(IdTypes.Internal);
  const [idsToQuery, setIdsToQuery] = useState("");

  const { isAllowed } = useIsAllowed(ROLE_NOT_OWNERS);

  const navigate = useNavigate();
  const location = useLocation();

  const texts = {
    [IdTypes.Internal]: {
      message:
        "The Hub IDs are internal invoice numbers used mainly before they are exported to QuickBooks. Search multiple IDs by using commas (e.g. 1000,1002,1008) or a range of IDs by using a hyphen (e.g. 1000-1050).",
      placeholder: "Enter Hub ID (eg. 1234)",
    },
    [IdTypes.Quickbooks]: {
      message:
        "QB invoice numbers are generated ones after an invoice as exported from The Hub. Search multiple invoices by using commas (e.g. 1000,1002,1008) or a range of invoices by using a hyphen (e.g. 1000-1050).",
      placeholder: "Enter a QuickBooks invoice number (e.g. 123456)",
    },
  };

  const rehydrateForm = useCallback(() => {
    setIdsToQuery(filters.idsInputValue);
    setSelectedIdType(
      filters.invoiceNumbers ? IdTypes.Quickbooks : IdTypes.Internal
    );
  }, [filters.invoiceNumbers, filters.idsInputValue]);

  const prepareIds = () => {
    let members: any[] = idsToQuery.replaceAll(" ", "").split(",");

    const range = (start: number, stop: number) =>
      Array.from({ length: stop - start + 1 }, (_, i) => start + i * 1);

    // Fill ranges defined by 1-100
    members = members.flatMap((item) => {
      const elements = item.split("-");
      if (elements.length > 1) {
        return range(Number(elements[0]), Number(elements[1]));
      } else {
        return Number(item);
      }
    });

    return [...(new Set(members) as unknown as Array<any>)] || [];
  };

  const { trackPageView } = useMatomo();

  useEffect(() => {
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });
  }, []);

  useEffect(() => {
    dispatch(clear());
    rehydrateForm();
  }, [dispatch, location, rehydrateForm]);

  const resetPageFilters = () => {
    setIdsToQuery("");
    dispatch(
      setFilters({
        internalNumbers: null,
        invoiceNumbers: null,
        idsInputValue: null,
      })
    );
  };

  // to clean filters on InvoiceQuery.tsx
  const resetQueryFilters = () => {
    if (isAllowed) {
      dispatch(resetFilters());
    } else {
      // TO DO: should keep the owner in the filters
      dispatch(resetFiltersWithoutOwner());
    }
  };

  const goToInvoices = () => {
    if (idsToQuery) {
      const ids = prepareIds();
      const key =
        selectedIdType === IdTypes.Internal
          ? "internalNumbers"
          : "invoiceNumbers";
      const other =
        key === "internalNumbers" ? "invoiceNumbers" : "internalNumbers";
      resetQueryFilters();
      dispatch(
        setFilters({
          // Transform Quickbook ids into strings
          [key]:
            key === "invoiceNumbers" ? ids.map((item) => item.toString()) : ids,
          [other]: null,
          idsInputValue: idsToQuery,
        })
      );
    } else {
      resetPageFilters();
    }
    navigate(ROUTE_INVOICES_LIST, { state: { from: ROUTE_INVOICES_SEARCH } });
  };

  const backDefaultInvoices = () => {
    resetPageFilters();
    navigate(ROUTE_INVOICES_LIST);
  };

  return (
    <div className="content">
      <div className="d-flex flex-wrap">
        <h2 className="flex-fill">Search by ID</h2>
        <button
          className="btn button-secondary"
          onClick={() => backDefaultInvoices()}
        >
          Cancel Search
        </button>
      </div>
      <form className="card invoice-summary-background p-4 mt-3">
        <div className="row justify-content-center mt-3">
          <div className="col-sm-5">
            <div
              onChange={(e: any) => {
                setSelectedIdType(Number(e.target.value));
              }}
            >
              <div className="w-50 d-inline-block">
                <Radio
                  name="idType"
                  label="Hub ID"
                  value={IdTypes.Internal}
                  checked={selectedIdType === IdTypes.Internal}
                  onChange={(e: any) => {}}
                />
              </div>
              <div className="w-50 d-inline-block">
                <Radio
                  name="idType"
                  label="Quickbooks Invoice"
                  value={IdTypes.Quickbooks}
                  checked={selectedIdType === IdTypes.Quickbooks}
                  onChange={(e: any) => {}}
                />
              </div>
            </div>
            <div>
              <input
                type="text"
                className="form-control form-control-sm mt-4"
                placeholder={texts[selectedIdType].placeholder}
                value={idsToQuery}
                onChange={(e: any) => {
                  setIdsToQuery(e.target.value);
                }}
              />
              <div className="text-muted mt-4">
                {texts[selectedIdType].message}
              </div>
            </div>
          </div>
        </div>

        <hr className="separator search-id" />
        <div className="mt-4 footer-filter search-id">
          <div
            className="link-text"
            onClick={(e) => {
              backDefaultInvoices();
            }}
          >
            Cancel Search
          </div>
          <button
            className={`btn btn-primary ${
              idsToQuery && !idsToQuery.match(regex) ? "disabled" : null
            }`}
            onClick={(e) => {
              e.preventDefault();
              goToInvoices();
            }}
          >
            Search
          </button>
        </div>
      </form>
      <Footer />
    </div>
  );
};
